import { useEffect, useState } from "react";

import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import Stack from "react-bootstrap/esm/Stack";

import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadDiet, loadUserInfo } from "@/store/loader";

import MainLayout from "@/components/MainLayout/MainLayout";
import FesComponent from "./components/fesComponent/FesComponent";
import FesTutorial from "@/components/Tutorials/FesTutorial";
import { LoadingPage } from "@/router/LoadingPage";
import ProfileSummary from "../menu/components/ProfileSummary";

import { QuestionCircleFill } from "react-bootstrap-icons";

import { isEmptyOrUndefined } from "@/utils";
import { useNotifications } from "@/contexts/NotificationContext";

import './FesPage.scss';

export default function FesPage() {
  const store = useStateStore();
  const currentUser = useCurrentUser();

  const { showNotification } = useNotifications();

  const {
    dietInformation,
    isLoadingDiet,
    dietCreationFailed,
    hasDiet
  } = store;


  const [isFesTutorialOpen, setIsFesTutorialOpen] = useState(false);


  const loadDietInfo = async () => {
    try {
      await loadDiet(store);
    } catch (error: any) {
      showNotification('Oops, ha ocurrido un error cargando tu dieta', 'danger');
    }
  }

  const checkDiet = async () => {
    if (isLoadingDiet) {
      await loadDietInfo();
    } else {
      return
    }
  };

  const MyPautaTooltip = () => {
    return (
      <Button
        className="fes-details-button"
        onClick={() => setIsFesTutorialOpen(true)}
      >
        <Stack direction='horizontal' gap={2}>
          New
          <QuestionCircleFill size={'1rem'} />
        </Stack>
      </Button>
    )
  }

  useEffect(() => {
    if (currentUser && isEmptyOrUndefined(dietInformation, 'arr')) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (currentUser?.resultsDate && userToken && isLoadingDiet) {
      checkDiet()
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);

  return (
    <MainLayout>
      {hasDiet() ?
        <>
          <FesTutorial show={isFesTutorialOpen} handleClose={() => setIsFesTutorialOpen(!isFesTutorialOpen)} />
          <div className="fes-page pt-2 pt-lg-4 px-0">
            <Container className="page-container">

              <Card className="p-4 mb-4 diet-header">
                <h1 className="diet-title">My Pauta <MyPautaTooltip /></h1>
                <p className="text mt-3">¡Bienvenido a My Pauta!</p>
                <p>
                  En esta sección podrás construir tu propia dieta a partir de recomendaciones según tus objetivos y genética.
                  Elige los alimentos que mejor se acomoden a tu día y descubre cuánto es lo indicado para ti para cumplir tus objetivos.
                   Además, puedes revisar las definiciones de las unidades de medida en la sección de <a href='/diets/portions'>porciones</a>.
                </p>
                <hr />
                <ProfileSummary />
              </Card>

              {
                !isLoadingDiet && !dietCreationFailed &&
                <Card className="py-4 px-2 mb-4 diet-header">
                  <FesComponent />
                </Card>
              }
            </Container>
          </div>
        </>
        : <LoadingPage />
      }
    </MainLayout>
  )
}