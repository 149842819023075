import { QuestionnaireData } from '@/models';
import { axiosInstance } from '@/api/http';
import { QuestionnaireOptions } from '@/models';

export async function saveQuestionnaire(data: QuestionnaireData, userToken: string, requestDiet: boolean): Promise<void> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/questionnaire', { questionnaire: data }, { headers });

    if (requestDiet) {
      console.log('Profile completed, updating diet');
      await axiosInstance.get('/diets/request-new-diet', { headers });
    }
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message

    throw new Error(message);
  }
}

export async function getQuestionnaire(userToken: string): Promise<QuestionnaireData> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.get(
      '/questionnaire',
      { headers }
    );
    const questionnaire: QuestionnaireData = response.data;

    return questionnaire
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema al obtener el questionario');
  }
}

export async function getQuestionnaireOptions(userToken: string): Promise<QuestionnaireOptions> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response: { data: unknown } = await axiosInstance.get(
      '/questionnaire/options',
      { headers }
    );

    const questionnaire: QuestionnaireOptions = response.data as QuestionnaireOptions;

    return questionnaire
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema al obtener las opciones del cuestionario');
  }
}


export async function updateAllergies(toggle: { [x: string]: boolean }, userToken: string): Promise<void> {
  try {
    const headers = { "Authorization": `Bearer ${userToken}` };
    await axiosInstance.put(
      '/questionnaire/allergy-or-intolerance',
      { toggle },
      { headers }
    );
  } catch (error: unknown) {
    throw new Error('Ocurrió un problema guardando las preferencias de alergias')
  }
}