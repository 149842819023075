import { Link, useLocation, useNavigate } from 'react-router-dom';

// state & context
import { useLoadUser } from '@/components/AuthContext';
import { getUserToken } from '@/services/auth/auth-service';
import { updateUserLastLogin } from '@/services/user/user';
import { useNotifications } from '@/contexts/NotificationContext';

// models
import { LoginData, AuthenticationResult } from '@/models';

// my components
import InitialLayout from '@/components/InitialLayout/InitialLayout';
import LoginForm from './LoginForm';

// assets
import MynuBlueLogo from '@/assets/images/mynu-mobile-logo.svg';
import { useIsIOSWebView } from '@/hooks/mobilePlatforms';
import { LOGIN_ERROR_MESSAGES } from '@/utils/constants';

import './Login.scss';

const ACTIVATE_PAYMENTS = true;

function LoginPage() {
  const navigate = useNavigate();

  const { showNotification } = useNotifications();

  const loadUser = useLoadUser();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const device = useIsIOSWebView();

  const loginUser = async (userData: LoginData) => {
    try {
      const authenticationData: AuthenticationResult = await getUserToken(userData);
      localStorage.setItem('userToken', authenticationData.AccessToken);

      await loadUser();
      await updateUserLastLogin();

      if (urlParams.get('testId')) {
        navigate(`/?testId=${urlParams.get('testId')}`);
      } else {
        navigate('/');
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === LOGIN_ERROR_MESSAGES.UNAUTHORIZED) {
          showNotification(`${error.message}`, 'danger');
        } else {
          showNotification(LOGIN_ERROR_MESSAGES.PLATFORM_ERROR, 'danger');
        }
      }
    }
  }

  return (
    <InitialLayout>
      <div className='login-container d-flex'>
        <div className="form-container pt-2">
          <div className="text-center d-lg-none pb-3">
            <img src={MynuBlueLogo} className="mobile-logo" alt="" width={200} />
          </div>
          <h1 className="form-title text-center mb-3">¡Bienvenido/Bienvenida!</h1>
          <p className="description text-center">Ingresa tus datos para continuar</p>
          <LoginForm loginUser={loginUser} />
        </div>
        <div className="text-center redirect-note pb-5">
          <span className='font-avenir-bold'>¿Aún no tienes cuenta? </span>
          <Link to="../signup">Regístrate</Link>
        </div>

        {ACTIVATE_PAYMENTS && device !== undefined && !device ?
          <div className="text-center redirect-note pb-5">
            <span className='font-avenir-bold'>¿Aún no tienes tu kit de ADN? </span>
            <Link to="../buy-kit">Cómpralo acá</Link>
          </div>
          : null}
      </div>
    </InitialLayout>
  )
}

export default LoginPage;
