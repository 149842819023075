
import { useRef, useState } from 'react';

import Overlay from 'react-bootstrap/Overlay';

import './StopLight.scss'

interface StopLightProps {
  colors: string[],
  descriptions: string[],
  titles?: string[],
  imgIcon?: JSX.Element,
  activeIndex?: number,
  changable: boolean
}

export default function StopLight(props: StopLightProps) {

  const { activeIndex, changable, titles, descriptions, colors, imgIcon } = props;

  const [showActiveItem, setShowActiveItem] = useState(false);
  const [activeId, setActiveId] = useState<number | null>(activeIndex ?? null);
  const targets = useRef<(HTMLDivElement | null)[]>([]);

  const handleChangeActiveItem = (elementId: number | null) => {
    setActiveId(activeId === elementId ? null : elementId);
    setShowActiveItem(elementId !== null);
  }
  return (
    <>
      <div className="stoplight-container">
        {colors && colors.length > 0 ?
          colors.map((c: string, i: number) =>
            <div
              key={`stoplight-item-${c}-${i}`}
              ref={(el) => (targets.current[i] = el)}
              style={{
                // marginBottom: titles && titles.length > 0 ? '1rem' : null,
                "--fill-content": colors[i],
                "--after-content": titles && titles.length === colors.length ? `"${titles[i]}"` : null,
              } as React.CSSProperties}
              className={`stoplight-item ${activeId === i ? 'active' : null}`}
              onClick={() => changable && handleChangeActiveItem(i)}
            >
              {imgIcon && colors?.length ? <div> {imgIcon} </div> : null}
            </div>
          ) : null}

      </div>
      {activeId !== null && descriptions && descriptions.length === colors.length ?
        <Overlay target={targets.current[activeId]} show={showActiveItem} placement="bottom" >
          <div
            className='tooltip-description'
            style={{ borderColor: colors[activeId] }}
          >
            {descriptions[activeId]}
          </div>
        </Overlay>
        : null}
    </>

  )
}