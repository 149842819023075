const prefer_text = 'Basar tu dieta en estos grupos de alimento te ayudará a llevar tu salud y bienestar al máximo. **Prioriza aquellos grupos de alimentos del nivel superior**.'
const reduce_text = 'Estos están asociados con algunas de tus características feno o genotípicas. **Consume estos grupos de alimentos en menor frecuencia**.'
const avoid_text = 'Estos grupos de alimentos están vinculados estrechamente con tus caracteristicas fenotípicas o con predisposiciones de alto riesgo según tu genética. **Intenta consumirlos de manera ocasional y evita al máximo los del nivel superior**.'

export enum FoodRecomendation {
  PREFER = 'prefer',
  REDUCE = 'reduce',
  AVOID = 'avoid'
}

export const diet_summary_texts: DietSummaryText = {
  [FoodRecomendation.PREFER]: {
    description: prefer_text,
    subtitle: "BASA TU DIETA EN"
  },
  [FoodRecomendation.REDUCE]: {
    description: reduce_text,
    subtitle: 'CONSUME EN MENOR FRECUENCIA'
  },
  [FoodRecomendation.AVOID]: {
    description: avoid_text,
    subtitle: 'EVÍTALOS',
    subsubtitle: 'EVÍTALOS AL MÁXIMO'
  }
}

export type DietSummaryText = {
  [key in FoodRecomendation]: {
    description: string,
    subtitle: string,
    subsubtitle?: string
  }
}