import { useNavigateToTop } from '@/hooks/useNavigateTop';

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import { ArrowLeftShort } from 'react-bootstrap-icons';

import './BackButton.css';

type BackButtonProps = {
  text: string;
  color?: string;
}

function BackButton({ text, color }: BackButtonProps) {
  const navigate = useNavigateToTop();
  const goBack = () => {
    navigate(-1);
  }
  return (

    <Button onClick={goBack} variant="link" className="back-button">
      <Stack direction='horizontal' gap={2} style={{ color: color ?? undefined }}>
        <ArrowLeftShort size={26} />
        {text}
      </Stack>
    </Button>
  )
}

export default BackButton;