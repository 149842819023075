import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';

import '@/styles/App.scss';
import './TutorialSection.scss';

import TutorialImage from '@/assets/welcome-tutorial/images/tutorial.webp';

import { Compass } from 'lucide-react';

type WelcomeTutorialSectionProps = {
  onTutorialOpen: () => void;
}

function WelcomeTutorialSection({ onTutorialOpen }: WelcomeTutorialSectionProps) {

  return (
    <Card className="mb-4 home-card tutorial-section-component">
      <Container>
        <Row>
          <Col lg="auto" xs={12} className="px-0 image-container">
            <img src={TutorialImage} alt="" className="home-section-image banner-questionnaire d-none d-lg-block" />
            <img src={TutorialImage} alt="" className="home-section-image d-flex d-lg-none" />
          </Col>
          <Col className="pt-3 pb-lg-0">
            <div className='content-header'>
              <div className='icon-element me-3'>
                <Compass />
              </div>
              <span>
                Tutorial de Mynu
              </span>
            </div>
            <p className="home-text mt-3 text-center text-lg-start">
              ¡Conoce las principales características de Mynu!
            </p>
          </Col>
          <Col className="home-button-container" lg={3}>
            <Button
              variant="outline-secondary"
              className='mb-4'
              onClick={() => onTutorialOpen()}
            >
              Ver Tutorial
            </Button>
          </Col>
        </Row>
      </Container>
    </Card >
  )
}

export default WelcomeTutorialSection;
