import {
  FormPersonalData,
  FormDailyRoutine,
  FormNutrition,
  QuestionnaireData,
  formPersonalInfoToData,
  formDailyRoutineToData,
  formNutritionToData,
  personalInfoDataToForm,
  dailyRoutineDataToForm,
  nutritionDataToForm
} from "@/models";

export type QuestionnaireForms = {
  personalData: FormPersonalData,
  dailyRoutine: FormDailyRoutine,
  nutrition: FormNutrition
}

class FormDomainAdapter {
  
  formToDomain({personalData, dailyRoutine, nutrition}: QuestionnaireForms): QuestionnaireData {
    
    return {
      ...formPersonalInfoToData(personalData),
      ...formDailyRoutineToData(dailyRoutine),
      ...formNutritionToData(nutrition)
    } as QuestionnaireData;
  }
  
  domainToForm(domainData: QuestionnaireData): QuestionnaireForms {
    
    const personalData = personalInfoDataToForm(domainData);
    const dailyRoutine = dailyRoutineDataToForm(domainData);
    const nutrition = nutritionDataToForm(domainData);
    
    return {
      personalData,
      dailyRoutine,
      nutrition
    };
  } 
}

export const formDomainAdapter = new FormDomainAdapter();