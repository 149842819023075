import { useNavigateToTop } from '@/hooks/useNavigateTop';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import MainLayout from "../../../../components/MainLayout/MainLayout";

import { DietPanelDescriptions } from "./DietPanelDescription";

import '../../GeneticResults.scss';
import './DietsPanelComponent.scss';
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { useCurrentUser } from "../../../../components/AuthContext";
import { useStateStore } from "../../../../store/store";
import { useEffect, useMemo, useState } from "react";
import { loadUserInfo } from "../../../../store/loader";
import { ArrowLeftIcon } from "lucide-react";
import { DietPanelDescriptionType } from "../../ResultsComponentsModel";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";

export default function DietsPanelComponent() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const [isTopCardOpen, setIsTopCardOpen] = useState(false);

  const { hasResults, resultsData } = store;

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigateToTop();

  const descriptionsToShow = useMemo(() => {

    if (resultsData && resultsData.diets_panel && resultsData.diets_panel.length > 0) {
      const toShow = DietPanelDescriptions.filter((d: DietPanelDescriptionType) => {

        const found_snps = resultsData.diets_panel
          ?.find(p => p.category_name === d.parentId)
          ?.subcategories?.some(s => s.gene_breakdown?.map(g => g.gene_name) && s.gene_breakdown?.map(g => g.gene_name)?.length > 0)

        return found_snps;
      })

      return toShow;
    }

  }, [resultsData])

  return (
    <MainLayout>
      <Container className="diet-section-container mx-auto">
        <Card>
          <Card.Header className="diet-section-custom-bg panel-header"
            style={{ minHeight: '190px' }}
          >
            <div className="arrow-back-button">
              <ArrowLeftIcon size={25} color="white" onClick={() => navigate(-1)} cursor='pointer' />
            </div>
            <div className="d-flex flex-column align-items-baseline gap-3">
              <div className="diets-section-card-title">
                Panel de dietas
              </div>
              <div style={{ fontSize: 'small', color: 'white' }}>
                Descubre qué patrón alimenticio puede traerte mejores resultados, dependiendo de tu objetivo.
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            Patrón alimentario según tu genética
          </Card.Body>
        </Card>
        <div className="diet-section-types">
          {descriptionsToShow && descriptionsToShow.length > 0 ?
            descriptionsToShow.map((btn, i) => {
              const { id, title, subtitle } = btn;
              return (
                <div
                  className={`diet-section-square-btn diet-section-custom-bg ${id}`}
                  key={`ds-sq-btn-${id}-${i}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(
                      `/results/diets-panel/${id}`,
                      { subcatId: id }
                    )
                  }

                >
                  <div className="ds-btn-header">
                    {title}
                  </div>
                  <div className="ds-btn-body">
                    <div>{subtitle}</div>
                    <div>
                      <ArrowRightCircleFill size={25} />
                    </div>
                  </div>
                </div>
              )
            })
            : null}
        </div>
        <div className="diet-section-rectangle-btn diet-section-custom-bg diet-panel-customization"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/results/diets-panel/customize')}
        >
          <div className="ds-btn-header">
            Personaliza tu patrón alimentario
          </div>
          <div className="ds-btn-body">
            <div style={{ width: '80%' }}>
              Activa o desactiva los diferentes patrones alimentarios que se ajustan a tu estilo de vida y revisa los cambios en tu menú.
            </div>
            <div>
              <ArrowRightCircleFill size={25} />
            </div>
          </div>
        </div>

        <Card>
          <Card.Body className="pb-2 d-flex flex-column justify-content-center align-items-center gap-2">
            <span className="pt-2">Referencias bibliográficas</span>
            <CollapseIcon
              open={isTopCardOpen}
              setOpen={() => setIsTopCardOpen(!isTopCardOpen)}
              iconSize={1.5}
            >
              <div className="grid-cards-container gap-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/31751449/" target="_blank" rel="noopener noreferrer">
                    Ramos-Lopez, O. et al.  Am J Clin Nutr 111(2), 2020.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/29516223/" target="_blank" rel="noopener noreferrer">
                    Goni, L et al. Eur J Nutr 58(4), 2019.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/21747052/" target="_blank" rel="noopener noreferrer">
                    Qi, Q. et al. Circulation 124(5), 2011.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/27581055/" target="_blank" rel="noopener noreferrer">
                    Heianza, Y. et al. Diabetes Care 39(11), 2016.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23138007/" target="_blank" rel="noopener noreferrer">
                    de Luis, D. et al. J. Investig. Med. 61(1), 2013.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23034957/" target="_blank" rel="noopener noreferrer">
                    Mattei, J. et al. Am J Clin Nutr 96(5), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/29921800/" target="_blank" rel="noopener noreferrer">
                    Goni, L. et al. Nutrients 10(6), 2018.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/19687793/" target="_blank" rel="noopener noreferrer">
                    Grau, K. et al. Int J Obes 33(11), 2009.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/20032493/" target="_blank" rel="noopener noreferrer">
                    Grau, K. et al. Am J Clin Nutr 91(2), 2010.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/22952648/" target="_blank" rel="noopener noreferrer">
                    Stocks, T. et al. PloS one 7(8), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23446828/" target="_blank" rel="noopener noreferrer">
                    Xu, M. et al. Circulation 127(12), 2013.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/29693310/" target="_blank" rel="noopener noreferrer">
                    Sun, D. et al. Diabetes Obes Metab 20(9), 2018.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/22891219/" target="_blank" rel="noopener noreferrer">
                    Zhang, X. et al. Diabetes 61(11), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/24081236/" target="_blank" rel="noopener noreferrer">
                    Stocks, T. et al. Hum. Hered 75(2-4), 2013.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/20352618/" target="_blank" rel="noopener noreferrer">
                    Razquin, C. et al. Mol Nutr Food Res 54(1), 2010.</a>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/22237064/" target="_blank" rel="noopener noreferrer">
                    Qi, Q. et al. Am J Clin Nutr 95(2), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/24328727/" target="_blank" rel="noopener noreferrer">
                    Garcia-Rios, A. et al. Chronobiol. Int. 31(3), 2014.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/26416604/" target="_blank" rel="noopener noreferrer">
                    Qi, Q. et al. Diabetologia 58(12), 2015.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23601741/" target="_blank" rel="noopener noreferrer">
                    de Luis, D. A. et al. Medicina clinica 141(5), 2013.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/22914552/" target="_blank" rel="noopener noreferrer">
                    Zhang, X. et al. Am J Clin Nutr 96(4), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/25926410/" target="_blank" rel="noopener noreferrer">
                    Xu, M. et al. J Nutr 145(6), 2015.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/30336580/" target="_blank" rel="noopener noreferrer">
                    Griffin, B. A. et al. Nutrients 10(10), 2018.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/25548261/" target="_blank" rel="noopener noreferrer">
                    Qi, Q. et al. J. Lipid Res. 56(3), 2015.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/29169396/" target="_blank" rel="noopener noreferrer">
                    Shatwan, I. M. et al. Lipids Health Dis 16(1), 2017.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23942586/" target="_blank" rel="noopener noreferrer">
                    Corella, D. et al. Diabetes care 36(11), 2013.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/24753491/" target="_blank" rel="noopener noreferrer">
                    Gomez-Delgado, F. et al. Mol Nutr Food Res 58(7), 2014.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/24448738/" target="_blank" rel="noopener noreferrer">
                    Ortega-Azorín, C. et al. Circ. Cardiovasc. Genet. 7(1), 2014.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/24990426/" target="_blank" rel="noopener noreferrer">
                    Corella, D et al. Am J Clin Nutr 100(2), 2014.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/23134888/" target="_blank" rel="noopener noreferrer">
                    Carvalho-Wells, A. L. et al. Am J Clin Nutr 96(6), 2012.</a>
                  <a style={{ fontFamily: 'avenir' }}
                    href="https://pubmed.ncbi.nlm.nih.gov/19748619/" target="_blank" rel="noopener noreferrer">
                    Olano-Martin, E. et al. Atherosclerosis 209(1), 2010. </a>
                </div>
              </div>
            </CollapseIcon>
          </Card.Body>
        </Card>

      </Container >
    </MainLayout >
  )
}