import { carbButtons, CarbohydrateCatgoryType, CarbohydratesTips, categoryNames, subcategoryNames } from "../../ResultsComponentsModel";

import { ReactComponent as Insulin } from '../../../../assets/results/carbs/icons/insulin.svg';
import { ReactComponent as Diabetes } from '../../../../assets/results/carbs/icons/diabetes.svg';
import { ReactComponent as Amylase } from '../../../../assets/results/carbs/icons/amylase.svg';
import { CategoryIds, PredispositionIds, Recommendation, SubcategoryIds } from "../../result-constants";

export const CarbsDescription: CarbohydrateCatgoryType = {
  id: CategoryIds.CARBOHYDRATES,
  name: categoryNames[CategoryIds.CARBOHYDRATES],
  subcategories: [
    {
      id: SubcategoryIds.CARBOHYDRATES,
      name: subcategoryNames[SubcategoryIds.CARBOHYDRATES],
      tooltip: [
        'Las variantes genéticas pueden alterar cómo el cuerpo procesa la glucosa, afectando la secreción de insulina.',
        'Estas alteraciones aumentan el riesgo de desarrollar diabetes tipo 2, ya que pueden llevar a niveles elevados de azúcar en sangre y resistencia a la insulina.'
      ],
      topcard: {
        title: '¿Cómo influye la genética?',
        replacePRSText: [
          '%[PRS_VALUE]% una persona sin predisposición genética a presentar alteraciones en el metabolismo de la glucosa.'
        ],
        buttonGroup: [
          {
            id: carbButtons.INSULIN,
            label: 'Liberación de insulina',
            IconImage: Insulin,
            body: [
              'Algunos genes afectan cómo las células del páncreas liberan insulina después de comer.',
              'Si la liberación es insuficiente, la glucosa se queda alta en sangre, lo que altera el metabolismo.'
            ],
            examples: [
              'TCF7L2', 'MTNR1B', 'ADCY5', 'KCNQ1'
            ]
          },
          {
            id: carbButtons.DIABETES,
            IconImage: Diabetes,
            label: 'Desarrollo de diabetes tipo 2',
            body: [
              'Ciertos genes aumentan la actividad que afecta la producción y liberación de insulina, incrementando el riesgo de diabetes tipo 2.'
            ],
            examples: [
              'WFS1', 'CDC123-CAMK1D', 'TCF7L2', 'LINC00523', 'FTO', 'CDKN2A/2B', 'IGF2BP2', 'SLC30A8', 'MCM6'
            ]
          },
          {
            id: carbButtons.AMYLASE,
            IconImage: Amylase,
            label: 'Baja amilasa salival',
            body: [
              'Una menor producción de la enzima que ayuda a digerir el almidón, puede dificultar la digestión de carbohidratos y aumentar el riesgo de diabetes tipo 2, especialmente con dietas altas en carbohidratos.'
            ],
            examples: [
              'RNPC3-DT'
            ]
          }
        ]
      },
      collapse: {
        title: '¿Cuánto influye mi estilo de vida?',
        body: [
          'Aunque tus genes pueden influir en cómo procesas el azúcar, tus hábitos diarios tienen un gran impacto en tu riesgo de desarrollar diabetes.',
          'Mantener un estilo de vida saludable puede reducir ese riesgo en un 17 %, especialmente entre los 40 y 50 años, cuando los buenos hábitos son aún más determinantes.'
        ]
      },

      bottomCard: {
        header: 'para mantener tu glucosa estable',
        body: [
          {
            id: CarbohydratesTips.TIP1,
            name: 'Desayuna salado',
            description: 'Los alimentos salados estabilizan el azúcar en sangre mejor que los dulces.'
          },
          {
            id: CarbohydratesTips.TIP2,
            name: 'Empieza con las verduras',
            description: 'Las fibras de las verduras retrasan la absorción de carbohidratos.'
          },
          {
            id: CarbohydratesTips.TIP3,
            name: 'Agrega vinagre',
            description: 'El vinagre puede reducir los niveles de glucosa después de las comidas.'
          },
          {
            id: CarbohydratesTips.TIP4,
            name: 'Lo dulce va de postre',
            description: 'Comer lo dulce al final limita los picos de azúcar en sangre.'
          },
          {
            id: CarbohydratesTips.TIP5,
            name: '¡Muévete!',
            description: 'Caminar después de una comida mejora el control de la glucosa y la respuesta a la insulina.'
          }
        ]
      },
      recommendation: {
        [PredispositionIds.LOW]: 'Puedes disfrutar de alimentos con carbohidratos y un consumo moderado de carbohidratos refinados🥣🍞🥐 y azúcares🧃🍭, siempre y cuando sigas un estilo de vida saludable y busques mantenerte activo.',
        [PredispositionIds.MEDIUM]: {
          [Recommendation.AVOID]: null,
          [Recommendation.PREFER]: [
            'canela',
            'carbohidratos_complejos',
            'frutas_enteras',
            'grasas_insaturadas',
            'verduras',
            'vinagre',
          ],
          [Recommendation.REDUCE]: [
            'alcohol',
            'azucar_y_productos_dulces',
            'cereales_refinados',
            'jugos_de_fruta',
            'ultraprocesados_altos_en_azucar',
          ]
        },
        [PredispositionIds.HIGH]: {
          [Recommendation.REDUCE]: null,
          [Recommendation.PREFER]: [
            'canela',
            'carbohidratos_complejos',
            'frutas_enteras',
            'grasas_insaturadas',
            'verduras',
            'vinagre',
          ],
          [Recommendation.AVOID]: [
            'alcohol',
            'azucar_y_productos_dulces',
            'cereales_refinados',
            'jugos_de_fruta',
            'ultraprocesados_altos_en_azucar',
          ]
        }
      }

    }
  ]

}