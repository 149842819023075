export enum PredispositionIds {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export enum Recommendation {
  PREFER = 'prefer',
  REDUCE = 'reduce',
  AVOID = 'avoid'
}

export enum CategoryIds {
  ALLERGIES = 'allergies',
  BODY = 'body',
  CARBOHYDRATES = 'carbohydrates',
  CARDIOVASCULAR = 'cardiovascular',
  VITAMINS = 'vitamins_minerals',
  WELLNESS = 'wellness',
}

export enum SubcategoryIds {
  ALLERGIES_CELIAC = 'allergies_celiac',
  ALLERGIES_COWMILK = 'allergies_cowmilk',
  ALLERGIES_FLG = 'allergies_flg',
  ALLERGIES_FOODALLERGY = 'allergies_foodallergy',
  ALLERGIES_HENEGG = 'allergies_henegg',
  ALLERGIES_LACTOSE = 'allergies_lactose',
  ALLERGIES_PEANUT = 'allergies_peanut',
  ALLERGIES_SHRIMP = 'allergies_shrimp',

  BODY_LEAN = 'body_lean',
  BODY_WEIGHT = 'body_weight',

  CARDIOVASCULAR_GENERAL = 'cardiovascular_general',
  CARDIOVASCULAR_BLOODPRESSURE = 'cardiovascular_bloodpressure',
  CARDIOVASCULAR_CHOLESTEROL = 'cardiovascular_cholesterol',
  CARDIOVASCULAR_TRIGLYCERIDES = 'cardiovascular_triglycerides',

  CARBOHYDRATES = 'carbohydrates',

  VITAMINS_MINERALS_A = 'vitamins_minerals_a',
  VITAMINS_MINERALS_B12 = 'vitamins_minerals_b12',
  VITAMINS_MINERALS_B6 = 'vitamins_minerals_b6',
  VITAMINS_MINERALS_C = 'vitamins_minerals_c',
  VITAMINS_MINERALS_COPPER = 'vitamins_minerals_copper',
  VITAMINS_MINERALS_D = 'vitamins_minerals_d',
  VITAMINS_MINERALS_FOLICACID = 'vitamins_minerals_folicacid',
  VITAMINS_MINERALS_SELENIUM = 'vitamins_minerals_selenium',
  VITAMINS_MINERALS_ZINC = 'vitamins_minerals_zinc',

  WELLNESS_ALCOHOL = 'wellness_alcohol',
  WELLNESS_HISTAMINE = 'wellness_histamine',
  WELLNESS_IBD = 'wellness_ibd',
  WELLNESS_MIGRAINES_MEN = 'wellness_migraines_men',
  WELLNESS_MIGRAINES_WOMEN = 'wellness_migraines_women',
  WELLNESS_TOBACCO = 'wellness_tobacco',
}


export enum DietsPanelCategoryIds {
  WEIGHT_LOSS = 'weight_loss',
  HEALTH = 'health',
  SUPPLEMENTS = 'supplements',
}

export enum DietsPanelSubCategoryIds {
  WEIGHT_LOSS = 'weight_loss',

  HEALTH_CARBOHYDRATE_METABOLISM = 'health_carbohydrate_metabolism',
  HEALTH_CARDIOVASCULAR = 'health_cardiovascular',

  SUPPLEMENTS_CARDIOVASCULAR = 'supplements_cardiovascular',
}


export enum DietsPanelTypeIds {
  LOW_FAT = 'low_fat',
  HIGH_FAT = 'high_fat',

  LOW_CARB = 'low_cho',
  HIGH_CARB = 'high_cho',
  
  HIGH_PROTEIN = 'high_protein',
  
  MEDITERRANEAN = 'med_diet',
  
  HIGH_MUFA = 'high_fat_mufa',
  HIGH_PUFA = 'high_fat_pufa',
  
  LOW_GLUCEMIA = 'Low_gi',
  
  OMEGA3 = 'w3',

  NONE = 'none',
}
