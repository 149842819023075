import { useEffect, useMemo, useState } from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import DietPanelHeader from "./DietPanelHeader";
import MainLayout from "@/components/MainLayout/MainLayout";
import ToggleButtonComponent from "@/components/ToggleButton/ToggleButton";

import { DietPatternDescriptions } from "../DietPanelDescription";
import { DietPanelModifyType } from "../../../ResultsComponentsModel";
import { MYNU_PRIMARY_COLOR } from "@/utils/constants";

import { InfoCircle, InfoCircleFill } from "react-bootstrap-icons";
import { DietsPanelTypeIds } from "../../../result-constants";
import { SyncLoader } from "react-spinners";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadDiet, loadQuestionnaire, loadUserInfo } from "@/store/loader";
import { QuestionnaireData } from "@/models";
import { getDietModification, toggleDietModification } from "@/services/diet";
import { saveQuestionnaire } from "@/services/questionnaire/questionnaire-service";

import { useNotifications } from "@/contexts/NotificationContext";

import '../DietsPanelComponent.scss';
import '../../../GeneticResults.scss';

type DietCustomizationKind = 'diet-types' | 'others';

export default function DietCustomization() {
  const currentUser = useCurrentUser();
  const store = useStateStore();

  const { showNotification } = useNotifications();

  const [kind, setKind] = useState<DietCustomizationKind>('diet-types');
  const { isLoadingDiet, hasResults, questionnaireData, getToken } = store;

  const [originalDietType, setOriginalDietType] = useState<string>();
  const [dietType, setDietType] = useState<string>();
  const [dietTypeChanged, setDietTypeChanged] = useState(false);

  const [originalDietModif, setOriginalDietModif] = useState<string>();
  const [dietModif, setDietmodif] = useState<string>();
  const [dietModifChanged, setDietModifChanged] = useState(false);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedToggleDescriptions = useMemo(() => {
    return DietPatternDescriptions.filter(p =>
      kind === 'diet-types' ? DietPatternButtonsBase.includes(p.id) : DiePatternButtontsOthers.includes(p.id)
    )
  }, [kind])

  useEffect(() => {
    if (!dietTypeChanged && questionnaireData?.dietTypeId !== undefined) {
      setDietType(mapDietTypeBackFront(questionnaireData?.dietTypeId));
      setOriginalDietType(mapDietTypeBackFront(questionnaireData?.dietTypeId));
      setDietTypeChanged(true);
    }
  }, [questionnaireData, dietTypeChanged])

  useEffect(() => {
    if (!dietModifChanged) {
      getDietModification()
        .then((v) => {
          setOriginalDietModif(mapDietModificationBackFront(v));
          setDietmodif(mapDietModificationBackFront(v));
          setDietModifChanged(true);
        })
        .catch(() => {
          showNotification(
            'Ha ocurrido un error obteniendo tus preferencias. Por favor, intenta más tarde',
            'danger'
          );
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dietModifChanged])

  const handleToggleId = (id: DietsPanelTypeIds): void => {
    if (kind === 'diet-types') {
      setDietType(dietType === id ? DietsPanelTypeIds.NONE : id);
    } else {
      setDietmodif(dietModif === id ? DietsPanelTypeIds.NONE : id);
    }
  }

  const handleRevertSelection = () => {
    if (kind === 'diet-types') {
      setDietType(originalDietType);
    } else {
      setDietmodif(originalDietModif);
    }
  }

  const handleSaveOption = async () => {
    const t = getToken();
    if (!t) {
      showNotification('Por favor inicia sesión otra vez', 'warning');
      return;
    }

    try {
      if (kind === 'diet-types') {
        const mappedId = mapDietTypeBackFront(dietType ?? DietsPanelTypeIds.NONE);
        const q = {
          ...questionnaireData,
          dietTypeId: mappedId
        };

        await saveQuestionnaire(q as QuestionnaireData, t, true);
        await loadQuestionnaire(store);
        setDietTypeChanged(false);

      } else {
        await toggleDietModification(dietModif ?? DietsPanelTypeIds.NONE);
      }

      await checkDiet();

      showNotification('Preferencias actualizadas', 'success');

    } catch {
      showNotification(
        'Ha ocurrido un error actualizando tus preferencias. Por favor, intenta más tarde',
        'danger'
      );
    }
  }

  const selectedEqualtoOriginal = useMemo(() => {
    return kind === 'diet-types' ? originalDietType === dietType : originalDietModif === dietModif;
  }, [dietModif, dietType, kind, originalDietModif, originalDietType])

  const checkDiet = async () => {
    try {
      await loadDiet(store);
    } catch (error: any) {
      showNotification(
        'Ha ocurrido un error cargando tu dieta. Por favor, intenta más tarde',
        'danger'
      );
    }
  };

  useEffect(() => {
    if (isLoadingDiet) {
      checkDiet();
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);

  return (
    <MainLayout>
      <div className="diet-section-container">

        <DietPanelHeader
          d={DietCustomizationDescription}
          warnOnExit={!selectedEqualtoOriginal}
        />

        <Container className="px-0 change-parameters-container">
          <Card className='m-0 mynu-shadow'>
            <div className='gap-3 d-flex align-items-center'>
              <div>
                <InfoCircle size={'2rem'} className='mynu-stars m-2' />
              </div>
              <div>
                Puedes actualizar My Dieta con cada modificacion.
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between justify-content-md-end align-items-center'>
              {isLoadingDiet ?
                <div className='gap-3 d-flex flex-column m-2 align-items-center'>
                  <div><i>Tu dieta está siendo procesada, por favor intenta más tarde</i></div>
                  <SyncLoader className='mynu-stars' speedMultiplier={0.7} />
                </div>
                :
                <>
                  <Button variant='outline-primary' onClick={handleRevertSelection} disabled={selectedEqualtoOriginal}>
                    Cancelar
                  </Button>
                  <Button variant='primary' onClick={handleSaveOption} disabled={selectedEqualtoOriginal}>
                    Actualizar
                  </Button>
                </>
              }
            </div>
          </Card>
        </Container>

        <Container className="mx-0 diet-patterns-conatiner">

          <div className="mb-3 d-flex gap-3 flex-column justify-content-center align-items-center">
            <Button
              className="diet-patterns-customize-btn"
              variant='primary'
              active={kind === 'diet-types'}
              onClick={() => setKind('diet-types')}
            >
              Elige tu patrón alimentario
            </Button>
            <Button
              className="diet-patterns-customize-btn"
              variant='secondary'
              active={kind === 'others'}
              onClick={() => setKind('others')}
            >
              Agrega otras modificaciones a tu dieta
            </Button>

            <span className="text-center">
              Revisa tus resultados de <strong>Panel de dietas</strong> para ver qué patrón alimentario se <strong className="mynu-stars">adecúa más a ti</strong>.
              Si quieres probar un patrón alimentario, <strong>haz click sobre el botón</strong> de encendido/apagado y verás los cambios en tu menú.
            </span>

          </div>

          <div className="diet-pattern-cards-container">
            {selectedToggleDescriptions?.map((td, i) => {
              return (
                <Card
                  className="diet-patterns-card mb-3"
                  key={`diet-panel-customization-${td.id}-${i}`}
                >
                  <Card.Header className="d-flex flex-row justify-content-between align-items-center gap-2 ">
                    <div className="d-flex gap-3 flex-row justify-content-center align-items-center">
                      <div className="diet-pattern-logo-tooltip m-0">
                        <div className="logo-tooltip-icon">
                          <td.IconElement />
                        </div>
                      </div>

                      <div className="diet-pattern-title">
                        {td.title}
                      </div>
                    </div>

                    <div className="">
                      {!isLoadingDiet ?
                        <ToggleButtonComponent
                          size={25}
                          value={
                            kind === 'diet-types' ? dietType === td.id : dietModif === td.id
                          }
                          changeValue={() => handleToggleId(td.id)}
                          on={<></>}
                          off={<></>}
                        />
                        :
                        <div className="sync-loader">
                          <SyncLoader speedMultiplier={0.7} />
                        </div>
                      }
                    </div>
                  </Card.Header>
                  <Card.Body className="py-3">
                    {(kind === 'diet-types' ? dietType === td.id : dietModif === td.id) ?
                      <div className="
                          d-flex flex-row
                          justify-content-center justify-content-md-start
                          align-items-center
                        "
                      >
                        <InfoCircleFill size={'35px'} color={MYNU_PRIMARY_COLOR} className="mx-3" />
                        <div className="diet-pattern-title">
                          {td.tooltip}
                        </div>
                      </div>
                      : null}
                  </Card.Body>

                </Card>
              )
            })}
          </div>
        </Container>

      </div >
    </MainLayout >
  )
}


const DietCustomizationDescription: DietPanelModifyType = {
  id: 'diet-panel-customization',
  title: 'Modifica tu alimentación según tus resultados genéticos',
  subtitle: "",
  description: "",
  genes: [],
  toggles: null,
  buttons: null
}

const DietPatternButtonsBase = [
  DietsPanelTypeIds.LOW_FAT,
  DietsPanelTypeIds.LOW_CARB,
  DietsPanelTypeIds.HIGH_PROTEIN,
  DietsPanelTypeIds.MEDITERRANEAN,
]

const DiePatternButtontsOthers = [
  DietsPanelTypeIds.HIGH_PUFA,
  DietsPanelTypeIds.HIGH_MUFA,
  DietsPanelTypeIds.LOW_GLUCEMIA,
  DietsPanelTypeIds.OMEGA3
]

export const mapDietTypeBackFront = (s: string): string => {
  switch (s) {
    // results -> questionnaire
    case DietsPanelTypeIds.LOW_CARB: return 'low_carb';
    case DietsPanelTypeIds.HIGH_CARB: return 'high_carb';
    case DietsPanelTypeIds.LOW_FAT: return 'low_fat';
    case DietsPanelTypeIds.HIGH_FAT: return 'high_fat';
    case DietsPanelTypeIds.MEDITERRANEAN: return 'mediterranean';
    case DietsPanelTypeIds.HIGH_PROTEIN: return 'high_protein';

    // questionnaire -> resutls
    case 'low_carb': return DietsPanelTypeIds.LOW_CARB;
    case 'high_carb': return DietsPanelTypeIds.HIGH_CARB;
    case 'low_fat': return DietsPanelTypeIds.LOW_FAT;
    case 'high_fat': return DietsPanelTypeIds.HIGH_FAT;
    case 'mediterranean': return DietsPanelTypeIds.MEDITERRANEAN;
    case 'high_protein': return DietsPanelTypeIds.HIGH_PROTEIN;

    default:
      return DietsPanelTypeIds.NONE;
  }
}

export const mapDietModificationBackFront = (s: string) => {
  switch (s) {
    // results -> questionnaire
    case DietsPanelTypeIds.HIGH_MUFA: return 'mufa';
    case DietsPanelTypeIds.HIGH_PUFA: return 'pufa';
    case DietsPanelTypeIds.LOW_GLUCEMIA: return 'lowGI';
    case DietsPanelTypeIds.OMEGA3: return 'highW3';

    // questionnaire -> resutls
    case 'mufa': return DietsPanelTypeIds.HIGH_MUFA;
    case 'pufa': return DietsPanelTypeIds.HIGH_PUFA;
    case 'lowGI': return DietsPanelTypeIds.LOW_GLUCEMIA;
    case 'highW3': return DietsPanelTypeIds.OMEGA3;

    default:
      return DietsPanelTypeIds.NONE;
  }
}