import {
  accordionItemKind,
  chartType,
  subcategoryNames,
  CategoryAllergiesType,
  categoryNames,
  Indicator,
  cardItemKind,
  allergiesDiagramIds
} from "../../ResultsComponentsModel";
import {
  CategoryIds,
  PredispositionIds,
  Recommendation,
  SubcategoryIds,
} from "../../result-constants";

import { ReactComponent as Skin } from '../../../../assets/results/allergies/icons/skin-barrier.svg';
import { ReactComponent as Immune } from '../../../../assets/results/allergies/icons/immunoregulation.svg';
import { ReactComponent as Adaptive } from '../../../../assets/results/allergies/icons/adaptive-system.svg';
import { ReactComponent as Others } from '../../../../assets/results/allergies/icons/others.svg';
import { ReactComponent as LactoseIcon } from '../../../../assets/results/allergies/icons/lactose.svg';
import { ReactComponent as GlutenIcon } from '../../../../assets/results/allergies/icons/gluten.svg';

import cowmilkImg from '../../../../assets/results/allergies/images/cowmilk.webp';
import heneggImg from '../../../../assets/results/allergies/images/henegg.webp';
import peanutImg from '../../../../assets/results/allergies/images/peanut.webp';
import shrimpImg from '../../../../assets/results/allergies/images/shrimp.webp';

import allergiesChart from '../../../../assets/results/allergies/images/chart.png';

export const AllergiesDescription: CategoryAllergiesType = {
  id: CategoryIds.ALLERGIES,
  name: categoryNames[CategoryIds.ALLERGIES],
  main_card: {
    id: SubcategoryIds.ALLERGIES_FOODALLERGY,
    name: subcategoryNames[SubcategoryIds.ALLERGIES_FOODALLERGY],
    description: 'Mecanismos por los que mi genética puede aumentar mi riesgo:',
    question: '¿Cómo influye mi genética?',
    answer: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar algún tipo de alergia alimentaria.'
      + '<br />Las alergias alimentarias suelen surgir en la infancia,'
      + '<b style="color:#6628ff;"> si toleras alérgenos alimentarios es improbable que la desarrolles, incluso con predisposición alta</b>.',
    buttons: [
      {
        id: 'skin',
        IconComponent: Skin,
        name: 'Barrera de la piel',
        description: 'La barrera de tu piel depende de proteínas influenciadas por tus genes. Alteraciones en proteínas como la filagrina y las serpinas pueden causar grietas que permiten la entrada de agentes externos.',
        examples: ['FLG', 'FLG-AS1', 'SERPINB7', 'SERPINB7/B2']
      },
      {
        id: 'immune',
        IconComponent: Immune,
        name: 'Regulación del sistema inmune',
        description: 'Tu genética puede afectar proteínas que regulan la inflamación y el sistema inmune, alterando reacciones alérgicas.',
        examples: ['SERPINB7']
      },
      {
        id: 'adaptive',
        IconComponent: Adaptive,
        name: 'Sistema adaptativo',
        description: 'Tu sistema inmune decide si tolera un alérgeno alimentario o lo trata como una amenaza, liberando histamina y causando síntomas que van desde tos y congestión nasal hasta anafilaxia.',
        examples: [' C11orf30/LRRC32', 'HLA-DQB1 | HLA-DQA2', 'HLA-DRA', 'STAT6']
      },
      {
        id: 'others',
        IconComponent: Others,
        name: 'Otros mecanismos',
        description: 'Alteraciones genéticas pueden aumentar la producción de mucosidad en el sistema respiratorio, causando reacciones exageradas como el asma o las alergias.',
        examples: ['ZNF652']
      }
    ],
    accordion: [
      {
        id: 'question_1',
        kind: accordionItemKind.TEXT,
        header: '¿Cómo funciona el sistema inmune?',
        body: 'El sistema inmune nos protege de dos maneras: una rápida, con la piel y las células, y otra más lenta pero específica, que puede causar alergias.La genética también juega un papel, ya que los hermanos de personas alérgicas tienen más probabilidades de ser alérgicos.Entrar en contacto con alérgenos a través de la piel, puede provocar alergias, mientras que la exposición temprana a través del sistema digestivo puede reducir el riesgo y ayudar a desarrollar tolerancia.'
      },
      {
        id: 'question_2',
        kind: accordionItemKind.IMG,
        header: 'Relaciones a otras alergias alimentarias',
        body: '',
        metadata: {
          imgSrc: allergiesChart,
          description: 'Los niños con eczema o dermatitis atópica antes de los 4 años tienen un mayor riesgo de alergias alimentarias, influido por la genética y el ambiente. También, aquellos con predisposición genética a estas condiciones tienen más riesgo de desarrollar asma o rinitis en la adultez. (Gráfico adaptado de Czarnowicki et al, 2017)',
          examples: ['FLG', 'C11orf30/LRRC32', 'FLG-AS1', 'SERPINB7', 'STAT6']
        }
      },
      {
        id: 'question_3',
        kind: accordionItemKind.RECOMMENDATION,
        header: 'Recomendación Nutricional',
        body: {
          [PredispositionIds.LOW]: 'Mantén una dieta balanceada con frutas🍇, verduras🥙 y grasas saludables🫒🥑 para mantener tu sistema inmune fortalecido.',
          [PredispositionIds.MEDIUM]: {
            [Recommendation.PREFER]:
              ['aceite_de_oliva_o_canola',
                'alimentos_altos_en_kaempferol',
                'alimentos_fermentados',
                'alimentos_ricos_en_omega-3',
                'alimentos_ricos_en_vitamina_d',
                'alimentos_ricos_en_vitamina_d',
                'curcuma_y_pimienta',
                'hierbas_y_te',
                'hortalizas_rojas-naranjas-moradas',
                'legumbres',
                'ralladura_de_citricos',
                'vinagre'],
            [Recommendation.REDUCE]:
              ['aceite_de_palma_y_aceite_de_coco',
                'alcohol',
                'azucar_y_productos_dulces',
                'tabaco'],
            [Recommendation.AVOID]: null,
          },
          [PredispositionIds.HIGH]: {
            [Recommendation.PREFER]:
              ['aceite_de_oliva_o_canola',
                'alimentos_altos_en_kaempferol',
                'alimentos_fermentados',
                'alimentos_ricos_en_omega-3',
                'alimentos_ricos_en_vitamina_d',
                'alimentos_ricos_en_vitamina_d',
                'curcuma_y_pimienta',
                'hierbas_y_te',
                'hortalizas_rojas-naranjas-moradas',
                'legumbres',
                'ralladura_de_citricos',
                'vinagre'],
            [Recommendation.REDUCE]: null,
            [Recommendation.AVOID]:
              ['aceite_de_palma_y_aceite_de_coco',
                'alcohol',
                'azucar_y_productos_dulces',
                'tabaco'],
          },
        }
      }
    ]
  },
  subcategories: [
    {
      id: SubcategoryIds.ALLERGIES_HENEGG,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_HENEGG],
      kind: cardItemKind.CHART,
      SubcategoryIcon: null,
      indicator: Indicator.PRS_VALUE,
      barOrStoplight: chartType.BAR,
      chartActiveIds: [
        allergiesDiagramIds.SKIN,
        allergiesDiagramIds.OTHERS
      ],
      chartImg: heneggImg,
      examples: ['ZNF652', 'FLG-AS1', 'SERPINB7/B2'],
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_HENEGG + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo se desarrolla la alergia?',
          body: 'La alergia al huevo ocurre cuando el sistema inmune reacciona de forma exagerada a la proteína del huevo.Suele aparecer en el primer año de vida y, en muchos casos, se supera alrededor de los 5 años. Factores como la genética y la exposición temprana influyen, ya que esta última puede reducir significativamente el riesgo de desarrollar la alergia.'
        },
        {
          id: SubcategoryIds.ALLERGIES_HENEGG + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: '¿En qué influye la genética en la alergia?',
          body: 'Las variantes genéticas pueden causar una respuesta exagerada del sistema inmune a las proteínas del huevo, liberando Inmunoglobulina E y provocando alergias. Además, algunos genes afectan la barrera de la piel y, junto con antecedentes familiares de alergias, aumentan el riesgo de desarrollar alergia al huevo.'
        },
      ],
      footer: {
        title: '¿Qué significan mis resultados?',
        description: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar alergia al huevo de gallina, aproximadamente el 2% de las personas lo manifiesta.'
          + '<br />Las alergias alimentarias suelen surgir en la infancia,'
          + '<b style="color:#6628ff;"> si toleras huevo de gallina, es improbable que desarrolles esta alergia, incluso con predisposición alta</b>.',
        replacePRS: null
      }
    },
    {
      id: SubcategoryIds.ALLERGIES_SHRIMP,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_SHRIMP],
      kind: cardItemKind.CHART,
      SubcategoryIcon: null,
      barOrStoplight: chartType.STOPLIGHT,
      indicator: Indicator.OR_VALUE,
      examples: ['HLA-DQA1/HLA-DQB1'],
      chartActiveIds: [
        allergiesDiagramIds.ADAPTIVE
      ],
      chartImg: shrimpImg,
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_SHRIMP + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo se desarrolla la alergia?',
          body: 'La alergia al camarón y otros mariscos es una reacción del sistema inmune a una proteína en estos alimentos. Puede comenzar en cualquier etapa de la vida y suele ser permanente. Los síntomas incluyen reacciones en la piel, problemas digestivos, dificultad para respirar y, en casos graves, anafilaxia.'
        },
        {
          id: SubcategoryIds.ALLERGIES_SHRIMP + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: '¿En qué influye la genética en la alergia?',
          body: 'Los genes que regulan la respuesta inmune determinan cómo el sistema identifica y reacciona ante la proteína del camarón. Variantes en estos genes pueden aumentar la predisposición a desarrollar esta alergia a través de la liberación de Inmunoglobulina E.'
        }
      ],
      footer: {
        title: '¿Qué significan mis resultados?',
        description: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar alergia al camarón.'
          + '<br />Las alergias alimentarias suelen surgir en la infancia,'
          + '<b style="color:#6628ff;"> si toleras camarón, es improbable que desarrolles esta alergia, incluso con predisposición alta</b>.',
        replacePRS: null
      }
    },
    {
      id: SubcategoryIds.ALLERGIES_PEANUT,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_PEANUT],
      kind: cardItemKind.CHART,
      SubcategoryIcon: null,
      barOrStoplight: chartType.BAR,
      indicator: Indicator.OR_VALUE,
      chartActiveIds: [
        allergiesDiagramIds.SKIN,
        allergiesDiagramIds.ADAPTIVE
      ],
      chartImg: peanutImg,
      examples: ['HLA-DRA', 'HLA-DQB1', 'FLG-AS1', 'HLA-DQB1', 'HLA-DQA2'],
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_PEANUT + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo se desarrolla la alergia?',
          body: 'La alergia al maní suele comenzar en la infancia, y alrededor del 20 % de los niños la superan antes de la adolescencia. La exposición temprana al maní por vía oral puede ayudar al cuerpo a tolerarlo mejor, según la hipótesis de exposición dual.'
        },
        {
          id: SubcategoryIds.ALLERGIES_PEANUT + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: '¿En qué influye la genética en la alergia?',
          body: 'Las variantes genéticas pueden hacer que el sistema inmune reaccione de manera exagerada de manera diferente a las proteínas del maní, liberando Inmunoglobulina E y provocando una reacción alérgica. Además, algunos genes pueden afectar la barrera de la piel, aumentando el riesgo de alergia en niños con piel dañada. Tener familiares con alergia al maní también incrementa el riesgo de desarrollarla.'
        }
      ],
      footer: {
        title: '¿Qué significan mis resultados?',
        description: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar la alergia al maní, aproximadamente el 2% de las personas lo manifiesta.'
          + '<br />Las alergias alimentarias suelen surgir en la infancia,'
          + '<b style="color:#6628ff;"> si toleras maní, es improbable que desarrolles esta alergia, incluso con predisposición alta</b>.',
        replacePRS: null
      }
    },
    {
      id: SubcategoryIds.ALLERGIES_COWMILK,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_COWMILK],
      kind: cardItemKind.CHART,
      SubcategoryIcon: null,
      barOrStoplight: chartType.STOPLIGHT,
      indicator: Indicator.OR_VALUE,
      examples: ['FLG-AS1'],
      chartActiveIds: [
        allergiesDiagramIds.SKIN
      ],
      chartImg: cowmilkImg,
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_COWMILK + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo se desarrolla la alergia?',
          body: 'Esta alergia, a menudo confundida con la intolerancia a la lactosa, es una reacción del sistema inmunitario a proteínas de la leche. Generalmente aparece en el primer año de vida y se supera antes de los cinco años, aunque en raros casos puede persistir o reaparecer en la adultez. Sus síntomas incluyen reacciones gastrointestinales (vómito, dolor abdominal), cutáneas (urticaria, eczema) y, en casos graves, anafilaxia. La genética y la exposición temprana a la leche pueden acelerar el desarrollo de la tolerancia.'
        },
        {
          id: SubcategoryIds.ALLERGIES_COWMILK + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: '¿En qué influye la genética en la alergia?',
          body: 'Variantes en los genes HLA pueden influir en la respuesta inmune de la leche de vaca.'
        }
      ],
      footer: {
        title: '¿Qué significan mis resultados?',
        description: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar la alergia a la leche de vaca.'
          + '<br />Las alergias alimentarias suelen surgir en la infancia,'
          + '<b style="color:#6628ff;"> si toleras leche de vaca, es improbable que desarrolles esta alergia, incluso con predisposición alta</b>.',
        replacePRS: null
      }
    },
    {
      id: SubcategoryIds.ALLERGIES_LACTOSE,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_LACTOSE],
      kind: cardItemKind.CARD,
      SubcategoryIcon: LactoseIcon,
      heroTitle: '¿Qué es?',
      barOrStoplight: chartType.STOPLIGHT,
      description: 'Este gen afecta al gen LCT, relacionado con la capacidad de digerir lactosa según la cantidad de lactasa en el intestino. Con poca lactasa, la lactosa no se digiere bien y causa gases, hinchazón y diarrea al ser fermentada en el colon.',
      indicator: Indicator.NONE,
      examples: ['MCM6'],
      chartActiveIds: [
        allergiesDiagramIds.SKIN,
        allergiesDiagramIds.OTHERS
      ],
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_LACTOSE + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo influye la genética?',
          body: 'La variante del gen MCM6 afecta la actividad del gen que produce lactasa, la enzima que digiere la lactosa. Esta variante hace que la producción de lactasa disminuya con la edad, causando intolerancia a la lactosa en muchos adultos.'
        },
        {
          id: SubcategoryIds.ALLERGIES_LACTOSE + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: 'Síntomas de intolerancia',
          body: 'Gases, hinchazón, diarrea, dolor estomacal e incluso náuseas.'
        },
        {
          id: SubcategoryIds.ALLERGIES_LACTOSE + '-accordion-item-2',
          kind: accordionItemKind.RECOMMENDATION,
          header: 'Recomendación Nutricional',
          body: {
            [PredispositionIds.LOW]: null,
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]:
                ['alimentos_fermentados',
                  'lacteos_con_bajo_contenido_de_lactosa',
                  'lacteos_sin_lactosa'],
              [Recommendation.REDUCE]:
                ['lacteos_con_lactosa',
                  'postres_de_leche',
                  'quesos_untables_blancos_y_frescos'],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: ['alimentos_fermentados', 'lacteos_sin_lactosa'],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]:
                ['lacteos_con_lactosa',
                  'postres_de_leche',
                  'quesos_untables_blancos_y_frescos'],
            },
          }
        }
      ],
      footer: {
        title: '¿Soy intolerante a la lactosa?',
        description: 'No siempre. Solo el 20% de quienes tienen esta variante presentan síntomas. Algunas personas desarrollan bacterias en el colon que ayudan a digerir la lactosa, lo que puede prevenir los síntomas.',
        replacePRS: null,
      },
    },
    {
      id: SubcategoryIds.ALLERGIES_CELIAC,
      name: subcategoryNames[SubcategoryIds.ALLERGIES_CELIAC],
      kind: cardItemKind.CARD,
      heroTitle: '¿Qué es?',
      SubcategoryIcon: GlutenIcon,
      barOrStoplight: chartType.BAR,
      indicator: Indicator.NONE,
      examples: ['CCR3/CCR2', 'UQCRC2P1', 'KIAA1109', 'IL21-AS1', 'HLA-DQB1', 'HLA-DQA1', 'BACH2', 'TAGAP', 'RBFOX1'],
      description: 'Respuesta inmune a la proteína llamada gluten, que se encuentra en alimentos como trigo, avena, centeno, cebada y sus derivados.',
      accordion: [
        {
          id: SubcategoryIds.ALLERGIES_CELIAC + '-accordion-item-0',
          kind: accordionItemKind.TEXT,
          header: 'Síntomas de intolerancia',
          body: 'Dolor abdominal, gases, diarrea y/o estreñimiento, náuseas vómitos.'
        },
        {
          id: SubcategoryIds.ALLERGIES_CELIAC + '-accordion-item-1',
          kind: accordionItemKind.TEXT,
          header: '¿Cómo influye la genética?',
          body: 'La genética puede aumentar el riesgo de enfermedad celíaca al alterar la respuesta inmune y elevar la inflamación en el intestino. Estas variantes genéticas elevan los niveles de marcadores inflamatorios, lo que desregula la respuesta inmune y facilita la inflamación crónica en personas predispuestas.'
        },
        {
          id: SubcategoryIds.ALLERGIES_CELIAC + '-accordion-item-2',
          kind: accordionItemKind.RECOMMENDATION,
          header: 'Recomendación Nutricional',
          body: {
            [PredispositionIds.LOW]: 'Puedes incluir alimentos con gluten como trigo 🌾, cebada, centeno 🫓 y avena 🥣 en tu día a día si no te generan síntomas.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: ['alimentos_procesados_sin_gluten', 'harinas_y_cereales_sin_gluten'],
              [Recommendation.REDUCE]:
                ['avena_no_certificada_sin_gluten',
                  'cebada_y_derivados',
                  'centeno_y_derivados',
                  'productos_a_granel',
                  'trigo_y_derivados'],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: ['alimentos_procesados_sin_gluten', 'harinas_y_cereales_sin_gluten'],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]:
                ['avena_no_certificada_sin_gluten',
                  'cebada_y_derivados',
                  'centeno_y_derivados',
                  'productos_a_granel',
                  'trigo_y_derivados'],
            },
          }
        }
      ],
      footer: {
        title: '¿Soy intolerante al gluten?',
        description: '%[PRS_VALUE]% una persona sin predisposición genética para que tu cuerpo reconozca el gluten como una amenaza.',
        replacePRS: {
          [PredispositionIds.LOW]: 'Tu riesgo de desarrollar celiaquía es muy bajo. Aunque estas variantes están presentes en casi todos los casos de celiaquía, también se encuentran en el 30% de las personas sin predisposición genética, y solo un 3% es celíaco.',
          [PredispositionIds.MEDIUM]: 'Tener variantes genéticas aumenta tu riesgo de desarrollar celiaquía, pero no significa que necesariamente tendrás intolerancia al gluten. Estas variantes están presentes en casi todos los casos de celiaquía, pero también en el 30% de las personas sin predisposición genética, y solo un 3% de ellos es celíaco.',
          [PredispositionIds.HIGH]: 'Tener variantes genéticas aumenta tu riesgo de desarrollar celiaquía, pero no significa que necesariamente tendrás intolerancia al gluten. Estas variantes están presentes en casi todos los casos de celiaquía, pero también en el 30% de las personas sin predisposición genética, y solo un 3% de ellos es celíaco.',
        }
      },
    },
  ]
}