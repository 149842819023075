import { SVGComponent } from "../../pages/results/ResultsComponentsModel"
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import Collapse from "react-bootstrap/esm/Collapse";

import './CollapseIcon.scss';
import React from "react";

export interface CollapseIconProps {
  title?: JSX.Element,
  iconSize?: number,
  elements?: JSX.Element,
  IconComponentOpen?: SVGComponent,
  IconComponentClose?: SVGComponent,
  open: boolean,
  setOpen: (x: boolean) => void
  children: React.ReactNode,
}

export default function CollapseIcon(props: CollapseIconProps) {

  const {
    title, children, elements,
    iconSize,
    IconComponentOpen, IconComponentClose,
    open, setOpen
  } = props;

  const handleOpen = () => {
    setOpen(!open);
  }

  return (
    <div className="collapse-icon-component">
      <div>
        <Collapse in={open}>
          <div>
            <div className="d-flex flex-column justify-content-center">
              {title ?
                <div className="collapse-icon-title mynu-stars">{title}</div>
                : null
              }
              <div className="collapse-icon-description">
                {children}
              </div>
              {
                elements ?
                  <div className="collapse-icon-elements">
                    {elements}
                  </div>
                  : null
              }
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collapse-icon-button mynu-stars text-center"
        style={{
          '--svg-size': `${iconSize ? iconSize + 'em' : '2em'}`
        } as React.CSSProperties}
        onClick={() => handleOpen()}
      >
        {open ?
          IconComponentClose ? <IconComponentClose /> : <DashCircleFill />
          :
          IconComponentOpen ? <IconComponentOpen /> : <PlusCircleFill />
        }
      </div>

    </div>
  )
}