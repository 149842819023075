import { CheckBoxOption } from "@/pages/questionnaire/components/CheckBoxComponent";

export type BasicOptionType = {
  id: string;
  label: string;
}

export interface MedicalConditionOption extends BasicOptionType {
  description: string;
  selected?: boolean;
}

export interface TooltipOption extends BasicOptionType {
  tooltip?: string
}

export interface MedicationOption extends BasicOptionType {
  selected: boolean;
}

export interface IconOptionType extends BasicOptionType {
  iconName: string;
}

export const iconOptionToCheckBoxOption = ({ id, label, iconName }: IconOptionType): CheckBoxOption => {
  return {
    id,
    label,
    icon: require(`../assets/icons/preferredFoods/${iconName}.svg`)
  }
}

export type sportIntensity = 'light' | 'medium' | 'intense';

export interface SportOption extends BasicOptionType {
  intensity: sportIntensity;
}

export type QuestionnaireOptions = {
  sportOptions: SportOption[],
  frequencyIntervalOptions: BasicOptionType[],
  apetiteFeelingOptions: BasicOptionType[],
  genderOptions: BasicOptionType[],
  sleepDescriptionOptions: BasicOptionType[],
  sleepHourOptions: BasicOptionType[],
  dailyActivityOptions: BasicOptionType[],
  meansOfTransportOptions: BasicOptionType[],
  medicationOptions: BasicOptionType[],
  medicalConditionOptions: MedicalConditionOption[],
  allergiesOrIntoleranceOptions: BasicOptionType[],
  goalOptions: BasicOptionType[],
  wellnessGoalOptions: BasicOptionType[],
  gainMuscularWeightFatTimePriorityOptions: BasicOptionType[],
  gainMuscularWeightApetiteOptions: BasicOptionType[],
  dietRestrictionOptions: TooltipOption[],
  dietTypeOptions: TooltipOption[],
  dailyMealOptions: BasicOptionType[],
  foodSuplementOptions: BasicOptionType[],
  waterConsumptionFrequencyOptions: BasicOptionType[],
  alcoholConsumptionFrequencyOptions: BasicOptionType[],
  preferredFoodOptions: {
    animalProteinOptions: IconOptionType[],
    vegetalProteinOptions: IconOptionType[],
    legumeOptions: IconOptionType[],
    carbohydrateOptions: IconOptionType[],
    lipidOptions: IconOptionType[],
    dairyOptions: IconOptionType[],
    fruitOptions: IconOptionType[],
    vegetableOptions: IconOptionType[]
  }
}

export type FormPersonalData = {
  genderId?: string,
  birthDate?: string,
  weightKg?: number,
  heightCm?: number,
  medicalConditionIds: string[],
  otherMedicalConditionsText?: string,
  allergyOrIntoleranceIds: string[],
  otherAllergiesOrIntolerancesText?: string,
  medicationIds: string[],
  smokes?: boolean,
  alcoholConsumptionLevelId?: string,
  goalId?: string,
  wellnessImprovementGoalId?: string,
  hasFollowedDiet?: boolean,
  experienceFollowingDietsText?: string,
  experienceTryingToLooseWeightText?: string,
  weightGoalKg?: number,
  hasTriedToGainWeight?: boolean,
  followingDiet?: boolean,
  gainMuscularWeightFatTimePriorityId?: string,
  gainMuscularWeightTrainingTimeInMonths?: number,
  gainMuscularWeightApetiteLevelId?: string
}

export type QuestionnairePersonalData = {
  genderId: string;
  birthDate: string,
  weightKg: number,
  heightCm: number,
  medicalConditionIds: string[],
  otherMedicalConditionsText?: string,
  allergyOrIntoleranceIds: string[],
  otherAllergiesOrIntolerancesText: undefined,
  medicationIds: string[],
  smokes: boolean,
  alcoholConsumptionLevelId: string;
  goalId: string,
  weightLossData?: {
    weightGoalKg: number,
    hasFollowedDiet: boolean,
    experienceFollowingDietsText: string,
    experienceTryingToLooseWeightText: string,
    followingDiet: boolean
  },
  weightGainData?: {
    weightGoalKg: number,
    hasTriedToGainWeight: boolean,
    followingDiet: boolean
  },
  wellnessData?: {
    improvementGoalId: string
  },
  gainMuscularWeight?: {
    fatTimePriorityId: string,
    trainingTimeInMonths: number
    apetiteLevelId: string
  }
}

export type SportData = {
  sportId: string;
  timesPerWeek: number;
}

export type FormDailyRoutine = {
  sleepHoursId?: string,
  sleepDescriptionIds: string[],
  engagesInSport?: boolean,
  sportsData: SportData[],
  dailyActivityLevelId?: string,
  meanOfTransportId?: string
}

export type QuestionnaireDailyRoutineData = {
  sleepHoursId: string,
  sleepDescriptionIds: string[],
  engagesInSport: boolean,
  sportsData: SportData[],
  dailyActivityLevelId: string,
  meanOfTransportId: string
}

export type FormNutrition = {
  mealsPerDayIds: string[],
  dietRestrictionTypeIds: string[],
  dietTypeId?: string,
  intermitentFastingHours?: number,
  intermitentFastingFirstMealHour?: string,
  animalProteinPreferenceIds: string[],
  vegetalProteinPreferenceIds: string[],
  legumePreferenceIds: string[],
  carbPreferenceIds: string[],
  fatPreferenceIds: string[],
  lactosePreferenceIds: string[],
  fruitPreferenceIds: string[],
  vegetablePreferenceIds: string[],
  foodSupplementIds: string[],
  vitaminSupplementsText?: string,
  mineralSupplementsText?: string,
  otherSupplementsText?: string,
  dailyWaterConsumptionLevelId?: string,
  sugaryDrinkFrequencyId?: string,
  nonSugarDrinkFrequenceId?: string,
  generalSweetsFrequencyId?: string,
  generalFastFoodFrequencyId?: string,
  apetiteFeelingId?: string
};

export type QuestionnaireNutritionData = {
  mealsPerDayIds: string[],
  dietRestrictionTypeIds: string[],
  dietTypeId: string,
  intermitentFastingHours?: number,
  intermitentFastingFirstMealHour?: string,
  animalProteinPreferenceIds: string[],
  vegetalProteinPreferenceIds: string[],
  legumePreferenceIds: string[],
  carbPreferenceIds: string[],
  fatPreferenceIds: string[],
  lactosePreferenceIds: string[],
  fruitPreferenceIds: string[],
  vegetablePreferenceIds: string[],
  foodSupplementIds: string[],
  vitaminSupplementsText?: string,
  mineralSupplementsText?: string,
  otherSupplementsText?: string,
  dailyWaterConsumptionLevelId?: string,
  sugaryDrinkFrequencyId?: string,
  nonSugarDrinkFrequenceId?: string,
  generalSweetsFrequencyId?: string,
  generalFastFoodFrequencyId?: string,
  apetiteFeelingId?: string
};

export const formPersonalInfoToData = (formPersonalInfo: FormPersonalData): QuestionnairePersonalData => {

  const weightLossData = formPersonalInfo.goalId === 'weight-loss' ? {
    weightGoalKg: formPersonalInfo?.weightGoalKg,
    hasFollowedDiet: formPersonalInfo?.hasFollowedDiet,
    experienceFollowingDietsText: formPersonalInfo?.experienceFollowingDietsText,
    experienceTryingToLooseWeightText: formPersonalInfo?.experienceTryingToLooseWeightText,
    followingDiet: formPersonalInfo?.followingDiet
  } : undefined;

  const weightGainData = formPersonalInfo.goalId === 'weight-gain' ? {
    weightGoalKg: formPersonalInfo?.weightGoalKg,
    hasTriedToGainWeight: formPersonalInfo?.hasTriedToGainWeight,
    followingDiet: formPersonalInfo?.followingDiet
  } : undefined;

  const wellnessData = formPersonalInfo.goalId === 'wellness' ? {
    improvementGoalId: formPersonalInfo?.wellnessImprovementGoalId
  } : undefined;

  const gainMuscularWeight = formPersonalInfo.goalId === 'gain-muscular-weight' ? {
    fatTimePriorityId: formPersonalInfo?.gainMuscularWeightFatTimePriorityId,
    trainingTimeInMonths: formPersonalInfo?.gainMuscularWeightTrainingTimeInMonths,
    apetiteLevelId: formPersonalInfo?.gainMuscularWeightApetiteLevelId
  } : undefined;

  return {
    genderId: formPersonalInfo.genderId,
    birthDate: formPersonalInfo.birthDate,
    weightKg: formPersonalInfo.weightKg,
    heightCm: formPersonalInfo.heightCm,
    medicalConditionIds: formPersonalInfo.medicalConditionIds,
    otherMedicalConditionsText: formPersonalInfo?.otherMedicalConditionsText,
    allergyOrIntoleranceIds: formPersonalInfo.allergyOrIntoleranceIds,
    otherAllergiesOrIntolerancesText: formPersonalInfo?.otherAllergiesOrIntolerancesText,
    medicationIds: formPersonalInfo.medicationIds,
    smokes: formPersonalInfo.smokes,
    alcoholConsumptionLevelId: formPersonalInfo.alcoholConsumptionLevelId,
    goalId: formPersonalInfo.goalId,
    weightLossData,
    weightGainData,
    wellnessData,
    gainMuscularWeight
  } as QuestionnairePersonalData;
}

export const formDailyRoutineToData = (formDailyRoutine: FormDailyRoutine): QuestionnaireDailyRoutineData => {
  return {
    sleepHoursId: formDailyRoutine.sleepHoursId,
    sleepDescriptionIds: formDailyRoutine.sleepDescriptionIds,
    engagesInSport: formDailyRoutine.engagesInSport,
    sportsData: formDailyRoutine.sportsData,
    dailyActivityLevelId: formDailyRoutine.dailyActivityLevelId,
    meanOfTransportId: formDailyRoutine.meanOfTransportId
  } as QuestionnaireDailyRoutineData;
}

export const formNutritionToData = (formNutrition: FormNutrition): QuestionnaireNutritionData => {
  const dietRestrictionTypeIds = formNutrition.dietRestrictionTypeIds[0] === 'none' ? [] : formNutrition.dietRestrictionTypeIds;
  return {
    mealsPerDayIds: formNutrition.mealsPerDayIds,
    dietRestrictionTypeIds: dietRestrictionTypeIds,
    dietTypeId: formNutrition.dietTypeId,
    intermitentFastingHours: formNutrition?.intermitentFastingHours,
    intermitentFastingFirstMealHour: formNutrition?.intermitentFastingFirstMealHour,
    animalProteinPreferenceIds: formNutrition.animalProteinPreferenceIds,
    vegetalProteinPreferenceIds: formNutrition.vegetalProteinPreferenceIds,
    legumePreferenceIds: formNutrition.legumePreferenceIds,
    carbPreferenceIds: formNutrition.carbPreferenceIds,
    fatPreferenceIds: formNutrition.fatPreferenceIds,
    lactosePreferenceIds: formNutrition.lactosePreferenceIds,
    fruitPreferenceIds: formNutrition.fruitPreferenceIds,
    vegetablePreferenceIds: formNutrition.vegetablePreferenceIds,
    foodSupplementIds: formNutrition.foodSupplementIds,
    vitaminSupplementsText: formNutrition.vitaminSupplementsText,
    mineralSupplementsText: formNutrition.mineralSupplementsText,
    otherSupplementsText: formNutrition.otherSupplementsText,
    dailyWaterConsumptionLevelId: formNutrition.dailyWaterConsumptionLevelId,
    sugaryDrinkFrequencyId: formNutrition.sugaryDrinkFrequencyId,
    nonSugarDrinkFrequenceId: formNutrition.nonSugarDrinkFrequenceId,
    generalSweetsFrequencyId: formNutrition.generalSweetsFrequencyId,
    generalFastFoodFrequencyId: formNutrition.generalFastFoodFrequencyId,
    apetiteFeelingId: formNutrition.apetiteFeelingId
  } as QuestionnaireNutritionData;
}

export const personalInfoDataToForm = (questionnaire: QuestionnaireData): FormPersonalData => {
  return {
    genderId: questionnaire.genderId,
    birthDate: questionnaire.birthDate,
    weightKg: questionnaire.weightKg,
    heightCm: questionnaire.heightCm,
    medicalConditionIds: questionnaire.medicalConditionIds,
    otherMedicalConditionsText: questionnaire.otherMedicalConditionsText,
    allergyOrIntoleranceIds: questionnaire.allergyOrIntoleranceIds,
    otherAllergiesOrIntolerancesText: questionnaire.otherAllergiesOrIntolerancesText,
    medicationIds: questionnaire.medicationIds,
    smokes: questionnaire.smokes,
    alcoholConsumptionLevelId: questionnaire.alcoholConsumptionLevelId,
    goalId: questionnaire.goalId,
    wellnessImprovementGoalId: questionnaire.wellnessData?.improvementGoalId,
    hasFollowedDiet: questionnaire.weightLossData?.hasFollowedDiet,
    experienceFollowingDietsText: questionnaire.weightLossData?.experienceFollowingDietsText,
    experienceTryingToLooseWeightText: questionnaire.weightLossData?.experienceTryingToLooseWeightText,
    weightGoalKg: questionnaire.weightLossData?.weightGoalKg || questionnaire.weightGainData?.weightGoalKg,
    hasTriedToGainWeight: questionnaire.weightGainData?.hasTriedToGainWeight,
    followingDiet: questionnaire.weightGainData?.followingDiet || questionnaire.weightLossData?.followingDiet,
    gainMuscularWeightFatTimePriorityId: questionnaire.gainMuscularWeight?.fatTimePriorityId,
    gainMuscularWeightTrainingTimeInMonths: questionnaire.gainMuscularWeight?.trainingTimeInMonths,
    gainMuscularWeightApetiteLevelId: questionnaire.gainMuscularWeight?.apetiteLevelId
  } as FormPersonalData;
}

export const dailyRoutineDataToForm = (questionnaire: QuestionnaireData): FormDailyRoutine => {
  return {
    sleepHoursId: questionnaire.sleepHoursId,
    sleepDescriptionIds: questionnaire.sleepDescriptionIds,
    engagesInSport: questionnaire.engagesInSport,
    sportsData: questionnaire.sportsData,
    dailyActivityLevelId: questionnaire.dailyActivityLevelId,
    meanOfTransportId: questionnaire.meanOfTransportId
  } as FormDailyRoutine;
}

export const nutritionDataToForm = (questionnaire: QuestionnaireData): FormNutrition => {
  return {
    mealsPerDayIds: questionnaire.mealsPerDayIds,
    dietRestrictionTypeIds: questionnaire.dietRestrictionTypeIds,
    dietTypeId: questionnaire.dietTypeId,
    intermitentFastingHours: questionnaire.intermitentFastingHours,
    intermitentFastingFirstMealHour: questionnaire.intermitentFastingFirstMealHour,
    animalProteinPreferenceIds: questionnaire.animalProteinPreferenceIds,
    vegetalProteinPreferenceIds: questionnaire.vegetalProteinPreferenceIds,
    legumePreferenceIds: questionnaire.legumePreferenceIds,
    carbPreferenceIds: questionnaire.carbPreferenceIds,
    fatPreferenceIds: questionnaire.fatPreferenceIds,
    lactosePreferenceIds: questionnaire.lactosePreferenceIds,
    fruitPreferenceIds: questionnaire.fruitPreferenceIds,
    vegetablePreferenceIds: questionnaire.vegetablePreferenceIds,
    foodSupplementIds: questionnaire.foodSupplementIds,
    vitaminSupplementsText: questionnaire.vitaminSupplementsText,
    mineralSupplementsText: questionnaire.mineralSupplementsText,
    otherSupplementsText: questionnaire.otherSupplementsText,
    dailyWaterConsumptionLevelId: questionnaire.dailyWaterConsumptionLevelId,
    sugaryDrinkFrequencyId: questionnaire.sugaryDrinkFrequencyId,
    nonSugarDrinkFrequenceId: questionnaire.nonSugarDrinkFrequenceId,
    generalSweetsFrequencyId: questionnaire.generalSweetsFrequencyId,
    generalFastFoodFrequencyId: questionnaire.generalFastFoodFrequencyId,
    apetiteFeelingId: questionnaire.apetiteFeelingId
  } as FormNutrition;
}

export type QuestionnaireData = QuestionnairePersonalData & QuestionnaireDailyRoutineData & QuestionnaireNutritionData