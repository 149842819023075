import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import MainLayout from "../../../../../components/MainLayout/MainLayout";
import UGRSBarComponent from "../../../../../components/UGRSBar/UGRSBarComponent";
import ResponsiveCard from "../../../../../components/ResponsiveCard/ResponsiveCard";
import DietPanelHeader from "./DietPanelHeader";

import { DietPanelDescriptions, DietPatternDescriptions } from '..//DietPanelDescription';
import {
  DietPanelDescriptionType, DietPatternBaseOption,
  DietPatternToggleBaseType, ToggleOptions
} from "../../../ResultsComponentsModel";

import { Check, CheckCircle, InfoCircleFill, X, XCircle } from "react-bootstrap-icons";

import '../DietsPanelComponent.scss';
import '../../../GeneticResults.scss';

import { MYNU_PRIMARY_COLOR } from "../../../../../utils/constants";
import { useCurrentUser } from "../../../../../components/AuthContext";
import { useStateStore } from "../../../../../store/store";
import { loadUserInfo } from "../../../../../store/loader";
import { LoadingPage } from "../../../../../router/LoadingPage";
import { DietSubcategory } from "../../../../../models";
import { DietsPanelTypeIds } from "../../../result-constants";

export default function DietPattern() {
  const currentUser = useCurrentUser();
  const store = useStateStore();

  const { hasResults, resultsData } = store;

  const resultsState = useMemo(() => resultsData?.diets_panel, [resultsData]);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  const [subcategoryState, setSubcategoryState] = useState<null | DietPanelDescriptionType>(null);
  const [subcatButtonsState, setSubcatButtonsState] = useState<null | DietPatternBaseOption[]>(null);

  useEffect(() => {
    if (location?.state) {
      const sbcat = DietPanelDescriptions.find(c => c.id === location.state.subcatId);
      if (sbcat) {
        setSubcategoryState(sbcat);

        const buttons = DietPatternDescriptions.filter(d => sbcat.buttons !== null && sbcat.buttons.includes(d.id))
        if (buttons) {
          setSubcatButtonsState(buttons)
        }
      }
    }
  }, [location?.state]);


  const subcategoriesToDisplay = useMemo(() => {
    if (resultsState && subcategoryState) {
      const catInfo = resultsState?.find(dc => (dc.category_name === subcategoryState.parentId));
      if (catInfo) {
        return {
          ...Object.fromEntries(catInfo
            .subcategories
            .filter(sbc => sbc.subcategory_name === subcategoryState.id)
            .map(s => [s.diet_type, s]))
        }
      }
    }
  }, [resultsState, subcategoryState]);

  return (
    <MainLayout>
      {
        subcategoryState && resultsState && subcategoriesToDisplay ?
          <div className="diet-section-container">
            <Container className="px-0">
              <Card>
                <DietPanelHeader
                  d={subcategoryState}
                />
              </Card>

            </Container>

            <Container className="mx-0 diet-patterns-conatiner">

              <div className="diet-pattern-cards-container">

                <div className="">
                  <div className="">
                    <strong>{subcategoryState.subtitle}</strong>
                  </div>
                  <div className="">
                    {subcategoryState.description}
                  </div>
                </div>
                <hr ></hr>
                {
                  subcatButtonsState?.map((sbcatbtn, i) => {
                    const { id } = sbcatbtn;
                    const info = subcategoriesToDisplay[id];
                    return (
                      info.ugrs !== null ?
                        <DietCardButton key={`diet-patterns-card-${id}-${i}`} content={sbcatbtn} info={info} />
                        : null)
                  })
                }

                {subcategoryState?.toggles?.map((toggle, i) => {
                  const info = subcategoriesToDisplay[DietsPanelTypeIds.OMEGA3];
                  return (
                    info.ugrs !== null ?
                      <DietCardToggle
                        key={`diet-patterns-toggle-card-${toggle.id}-${i}`}
                        content={toggle}
                        action={(info.ugrs > 0 ? "yes" : "no") as ToggleOptions}
                      />
                      : null)
                })}

                <div className="info-container"
                  style={{
                    "--info-container-header": `"Genes"`
                  } as React.CSSProperties}
                >
                  <ResponsiveCard
                    description={''}
                    subtitle={" "}
                    examples={subcategoryState.genes}
                  />
                </div>
              </div>


            </Container>
          </div>
          : <LoadingPage />
      }
    </MainLayout>
  )
}


const DietCardButton = ({ content, info }: { content: DietPatternBaseOption, info: DietSubcategory }) => {
  return (
    info.ugrs !== null
      && info.ugrs_min !== null
      && info.ugrs_max !== null ?
      <Card className="diet-patterns-card">
        <Card.Header>
          <div className="diet-pattern-logo-tooltip">
            {content.IconElement !== null ?
              <OverlayTrigger placement="bottom" overlay={<Tooltip id={content.id}>{content.tooltip}</Tooltip>}>
                <div className="logo-tooltip-icon">
                  <content.IconElement />
                </div>
              </OverlayTrigger>
              : null}

            <div className="diet-pattern-title" style={{ maxWidth: '60%' }}>
              {content.title}
            </div>
          </div>
        </Card.Header>
        <Card.Body className="diet-pattern-bar-contaier">
          <UGRSBarComponent value={info.ugrs} bounds={[info.ugrs_min, info.ugrs_max]}
            label1={
              <div className="labels justify-content-start" style={{ color: '#FE5133' }}>
                <div>
                  <XCircle />
                </div>
                <div>
                  Baja eficiencia
                </div>
              </div>
            }

            label2={
              <div className="labels justify-content-end" style={{ color: '#33B591' }}>
                <div>
                  Alta Eficiencia
                </div>
                <div>
                  <CheckCircle />
                </div>
              </div>
            } />
        </Card.Body>
      </Card>
      : null)
}

const DietCardToggle = ({ content, action }: { content: DietPatternToggleBaseType, action: ToggleOptions }) => {

  return (
    <Card
      className="diet-patterns-card d-flex flex-column justify-content-envenly align-items-center"
      style={{
        padding: '1rem',
        color: MYNU_PRIMARY_COLOR
      }}
    >
      <div className="diet-pattern-logo-tooltip" style={{ margin: '1rem' }}>
        <div className="logo-tooltip-icon">
          <content.IconElement />
        </div>
      </div>
      <div className="diet-pattern-title">
        {content.title}
      </div>

      <div>
        {action === ToggleOptions.YES ?
          <div className="dp-card-toggle-icon" style={{ backgroundColor: '#33B591' }}>
            <Check color={'white'} size={'2rem'} />
          </div>
          :
          <div className="dp-card-toggle-icon" style={{ backgroundColor: MYNU_PRIMARY_COLOR }}>
            <X color={'white'} size={'2rem'} />
          </div>
        }
      </div>
      <div className="font-avenir-bold mt-2"
        style={{
          color: action === ToggleOptions.YES ? '#33B591' : MYNU_PRIMARY_COLOR,
          fontSize: '1.5rem'
        }}
      >
        {action === ToggleOptions.YES ? 'Sí' : 'Adopta diferentes estrategias'}
      </div>

      <div
        className="d-flex flex-row justify-content-center align-items-center"
        style={{
          margin: '1rem',
          gap: '1rem'
        }}
      >
        <div className="align-self-center">
          <InfoCircleFill size={'2rem'} />
        </div>
        <div className="text-body">{content.message[action]}</div>
      </div>
    </Card>
  )
}