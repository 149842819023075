import { useState } from 'react';
import { Link } from 'react-router-dom';
import { z, ZodType } from 'zod';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import FormButton from '@/components/FormButton/FormButton';
import Spinner from '@/components/Spinner/Spinner';

import { useNotifications } from '@/contexts/NotificationContext';
import { LoginData } from '@/models';

import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const { Group, Label, Control } = Form;

interface LoginFormProps {
  loginUser(data: LoginData): Promise<void>
}

export enum LoginFormIds {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface LoginFormFields {
  [LoginFormIds.EMAIL]: string,
  [LoginFormIds.PASSWORD]: string,
}

const LoginFormSchema: ZodType<LoginFormFields> = z
  .object({
    [LoginFormIds.EMAIL]: z
      .string()
      .email({ message: 'Formato de correo incorrecto' })
      .refine((value) => value.toLowerCase()),
    [LoginFormIds.PASSWORD]: z
      .string()
      .min(8, { message: 'La contraseña debe tener al menos 8 caracteres' })
      .max(30, { message: 'La contraseña debe tener máximo 30 caracteres' }),
  })
  .superRefine((data, ctx) => {
    data[LoginFormIds.EMAIL] = data[LoginFormIds.EMAIL].toLowerCase();
  });

function LoginForm({ loginUser }: LoginFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputPasswordType, setInputPasswordType] = useState<'password' | 'text'>('password');

  const { showNotification } = useNotifications();
  const changeToShowPassword = () => {
    setInputPasswordType(inputPasswordType === 'password' ? 'text' : 'password');
  };

  const { register, handleSubmit, setValue } = useForm<LoginFormFields>({
    resolver: zodResolver(LoginFormSchema)
  });


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(LoginFormIds.EMAIL, e.target.value.toLowerCase());
  };

  const onSubmit: SubmitHandler<LoginFormFields> = async (data: LoginFormFields) => {
    const formDataDTO = {
      username: data.email,
      password: data.password,
    }
    setIsLoading(true);
    await loginUser(formDataDTO);
    setIsLoading(false);
  };

  const onError: SubmitErrorHandler<LoginFormFields> = (errors) => {
    const errorMessages = Object.entries(errors).map(([_, error]) => error.message);

    let fallbackErrorMessage = 'Error en el formulario. Por favor, revisa los campos.';

    if (errorMessages.length > 0) {
      errorMessages.forEach((error) => {
        if (error) {
          showNotification(`${error}\n`, 'warning');
        }
      });
    } else {
      showNotification(fallbackErrorMessage, 'warning');
    }

  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
      <Group className="mb-2" controlId="login-email">
        <Label className="signup-form-label mb-1">Correo electrónico:</Label>
        <Control
          {...register(LoginFormIds.EMAIL)}
          type="email"
          placeholder="ejemplo@mail.cl"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            register(LoginFormIds.EMAIL).onChange(e);
            handleEmailChange(e);
          }}
        />
        <Control.Feedback type="invalid">
          Formato de correo inválido
        </Control.Feedback>
      </Group>

      <Label htmlFor="login-form-password" className='signup-form-label'>
        Contraseña:
      </Label>
      <InputGroup className="mb-2">
        <Control
          id="login-form-password"
          type={inputPasswordType}
          {...register(LoginFormIds.PASSWORD)}
        />
        <InputGroup.Text
          onClick={changeToShowPassword}
          className="password-icon"
          role="button"
        >
          {
            inputPasswordType === 'password' ?
              <EyeFill size={20} />
              :
              <EyeSlashFill size={20} />
          }
        </InputGroup.Text>
        <Control.Feedback type="invalid">
          Debes ingresar tu contraseña
        </Control.Feedback>
      </InputGroup>
      <div className="text-center py-3 redirect-note">
        <Link className="link-forgot-password" to="../password-recovery">¿Olvidaste tu clave?</Link>
      </div>

      {isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner />
        </div>
      ) : (
        <div className="text-center">
          <FormButton text="Iniciar sesión" />
        </div>
      )}
    </Form>
  );
}

export default LoginForm;