import React, { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/esm/Badge";
import Card from "react-bootstrap/esm/Card";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/esm/Container";
import Tooltip from "react-bootstrap/esm/Tooltip";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

import AccordionComponent from "@/components/AccordionComponent/AccordionComponent";
import ButtonBadgeComponent from "@/components/ButtonBadge/ButtonBadge";
import FoodTagsRecommendator from "@/components/FoodTagsRecommendator/FoodTagsRecommendator";
import MainLayout from "@/components/MainLayout/MainLayout";
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";
import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";

import bgSrc from '@/assets/results/body/images/body.webp';
import { ReactComponent as Muscle } from '@/assets/results/body/icons/muscle.svg';
import { DashCircleFill, InfoCircleFill, PlusCircleFill } from "react-bootstrap-icons";

import { BodyButtons, BodyButtonsAccordion, predispositionValues } from "../../ResultsComponentsModel";
import { RiskAgeChart, RiskObeseChart } from "./BodyCharts";
import { BodyDescription } from "./BodyDescription";

import { ReactComponent as Person } from '@/assets/results/body/icons/person.svg';
import { ReactComponent as Lines } from '@/assets/results/body/icons/lines.svg';
import { ReactComponent as Tired } from '@/assets/results/body/icons/tired.svg';
import { ReactComponent as Battery } from '@/assets/results/body/icons/battery.svg';
import { ReactComponent as Stress } from '@/assets/results/body/icons/oxi_stress.svg';
import { ReactComponent as Clock } from '@/assets/results/body/icons/clock.svg';
import { ReactComponent as Plate } from '@/assets/results/body/icons/plate.svg';
import { ReactComponent as Fat } from '@/assets/results/body/icons/fat.svg';
import { ReactComponent as InfoCircle } from '@/assets/results/body/icons/infoCircle.svg';

import '../../GeneticResults.scss';
import './BodyComponent.scss';
import { CategoryIds, PredispositionIds, Recommendation } from "../../result-constants";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { buildFoodTagArgumentsUrl, getCategoryFromResults, parseRecommendation, replacePRSOR } from "../../ResultPage";
import { loadUserInfo } from "@/store/loader";
import { LoadingPage } from "@/router/LoadingPage";
import CategoryHeader from "../CategoryHeaderComponent";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";
import { MYNU_PRIMARY_COLOR } from "../../../../utils/constants";

const getFinalRiskInnerHtmlFromPRSs = (prsActual: number, prsPrevious: number): string => {

  const displayPrsActual = `<span style="color:${MYNU_PRIMARY_COLOR}; font-weight: bold;">${parseFloat(prsActual.toFixed(2))}</span>`;

  const displayPrsPrevious = `<span style="color:${MYNU_PRIMARY_COLOR}; font-weight: bold;">${parseFloat(prsPrevious.toFixed(2))}</span>`;

  let innerHTml = '';

  if (prsActual === 1) {
    innerHTml = 'Gracias a tu estilo de vida, no tienes mayor riesgo a subir de peso, en comparación con una persona sin predisposición genética.';
  } else if (displayPrsActual === displayPrsPrevious) {
    innerHTml = `Gracias a tu estilo de vida, tu riesgo a subir de peso se mantiene en ${displayPrsActual}.`;
  } else if (prsActual < 1) {
    innerHTml = `Gracias a tu estilo de vida, tu riesgo genético a subir de peso cambia de ${displayPrsPrevious} a un riesgo final de ${displayPrsActual}.`;
  } else {
    innerHTml = `Debido a tu estilo de vida, tu riesgo genético a subir de peso cambia de ${displayPrsPrevious} a un riesgo final de ${displayPrsActual}.`;
  }

  return `<span>${innerHTml}</span>`;
}



export default function BodyComponent() {
  const [info, setInfo] = useState(false);
  const [adds, setAdds] = useState(0);

  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults, resultsData } = store;

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.BODY), [resultsData]);

  const handleUpdateScore = (v: number) => {
    setAdds(p => p + v);
  }

  const [activeButtons, setActiveButtons] = useState({
    [BodyButtons.DIET]: false,
    [BodyButtons.ACTIVE]: false,
    [BodyButtons.ALCOHOL]: false,
    [BodyButtons.SEDENTARY]: false,
    [BodyButtons.SLEEP]: false,
  })

  const handleActivateButton = (b: BodyButtons, score: number) => {
    setActiveButtons(() => {
      handleUpdateScore(activeButtons[b] ? -1 * score : score)
      return {
        ...activeButtons,
        [b]: !activeButtons[b]
      }
    })
  }

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [collapseTopOpen, setCollapseTopOpen] = useState(false);
  const [collapseBottomOpen, setCollapseBottomOpen] = useState(false);

  return (
    <MainLayout>
      <div className="category-container body-component-container"
        style={{
          '--bg-image-src': `url("${bgSrc}")`,
          position: 'relative'
        } as React.CSSProperties}
      >

        <CategoryHeader title={BodyDescription.name} />

        {hasResults() && resultsState ?
          <>
            {resultsState.body_weight.prs_to_1 !== null
              && resultsState.body_weight.percentile !== null ?
              <Container className="px-0 subcategories-cards-container">
                <Card className="sbcat-card">
                  <Card.Header className="d-flex flex-column justify-content-center">
                    <div>{BodyDescription.topCard.name}</div>

                    <div className="header-info">
                      <div className="percentile-bar-container">
                        <div className="text-center">
                          <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                          <CustomSliderComponent
                            percent={resultsState.body_weight.percentile}
                            size={"2rem"}
                            intervals={['Menor', 'Promedio', 'Mayor']}
                            intervalsDescriptions={[
                              'Tienes menos riesgo que la población general',
                              'Tu riesgo es similar al de la población general',
                              'Tienes más riesgo que la población general'
                            ]}
                          />
                        </div>
                      </div>

                      <div className="info-container"
                        style={{
                          "--info-container-header": `"¿Cómo influye mi genética?"`,
                        } as React.CSSProperties}
                      >
                        <div
                          className="my-auto"
                          style={{ fontSize: '1rem', fontWeight: 'normal' }}
                          dangerouslySetInnerHTML={{
                            __html: replacePRSOR(
                              BodyDescription.topCard.replacePRStext,
                              resultsState.body_weight.prs_to_1,
                              resultsState.body_weight.prs_to_1)
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="p-2">
                        <InfoCircle className="mynu-stars" style={{ width: '2rem', height: '2rem' }} />
                      </div>
                      <div>
                        <Markdown className={'md-container'}>
                          {BodyDescription.topCard.info}
                        </Markdown>
                      </div>
                    </div>

                  </Card.Header>

                  <CollapseIcon
                    open={collapseTopOpen}
                    setOpen={setCollapseTopOpen}
                    iconSize={2}
                  >
                    <div>
                      <Card.Body>

                        <div className="obese-chart">
                          <div className="w-100">
                            <div
                              className="mx-4 d-flex flex-column justify-content-center mynu-stars"
                              style={{ textAlign: 'justify' }}
                            >
                              <div style={{ fontSize: '1.1rem', fontWeight: 'bolder', }}>
                                Riesgo a aumentar de peso según tu genética y estilo de vida (Kim et al, 2024)
                              </div>
                            </div>

                            <RiskObeseChart
                              adds={adds}
                              prs={resultsState.body_weight.prs_to_1}
                              percentile={resultsState.body_weight.percentile}
                            />
                          </div>

                          <div className="mt-3">
                            <div className="mynu-stars">
                              <b>
                                {BodyDescription.topCard.chart.description}
                              </b>
                            </div>

                            <div className="chart-obese-buttons">
                              {BodyDescription.topCard.chart.buttons.map((b, i) => {
                                const { id, name, description, score, IconElement } = b;
                                return (
                                  <div key={`button-wrap-${id}-${i}`} >
                                    <ButtonBadgeComponent
                                      content={<IconElement />}
                                      title={name}
                                      size={3}
                                      active={activeButtons[id]}
                                      round
                                      onClick={() => handleActivateButton(id, score[resultsState.body_weight.predisposition])}
                                    />
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={id}>{description}</Tooltip>}>
                                      <InfoCircleFill style={{ color: '#FE5133', cursor: 'pointer', margin: '-2rem 0 0' }} />
                                    </OverlayTrigger>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                          <div className="m-4" style={{ textAlign: 'justify' }}>
                            {BodyDescription.topCard.chart.info}
                          </div>

                          <div className="info-container align-self-center mb-3"
                            style={{
                              "--info-container-header": `"Riesgo Final"`,
                              textAlign: 'justify',
                            } as React.CSSProperties}

                            dangerouslySetInnerHTML={{
                              __html: `${getFinalRiskInnerHtmlFromPRSs(resultsState.body_weight.prs_to_1 + adds, resultsState.body_weight.prs_to_1)}`,
                            }}
                          />
                        </div>

                        <AccordionComponent>
                          <div className="accordion-elements">
                            {BodyDescription.topCard.accordionElements.map((a, i) => {
                              const { id, button, body, } = a;
                              return (
                                <Accordion.Item
                                  key={`body-topcard-accordion-${id}-${i}`}
                                  eventKey={id}
                                  className="mt-0"
                                >
                                  <Accordion.Header>
                                    {button}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div style={{ textAlign: 'justify' }}>
                                      {body.description ?
                                        <Markdown remarkPlugins={[remarkGfm]} >
                                          {body.description.replaceAll(
                                            '%[PREDISPOSITION_VALUE]%',
                                            predispositionValues[resultsState.body_weight.predisposition].name.toLowerCase()
                                          )}
                                        </Markdown>
                                        : null
                                      }
                                      {id.includes('chart') ?
                                        <RiskAgeChart />
                                        : id.includes('buttons_array_1') ?
                                          <Mechanisms />
                                          : null
                                      }

                                    </div>
                                    <>
                                      {body.info ?
                                        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                          <div>
                                            {info ?
                                              <DashCircleFill className="mynu-stars" size={25} onClick={() => setInfo(!info)} style={{ cursor: 'pointer' }} />
                                              :
                                              <PlusCircleFill className="mynu-stars" size={25} onClick={() => setInfo(!info)} style={{ cursor: 'pointer' }} />
                                            }
                                          </div>
                                          <Collapse in={info}>
                                            <div>
                                              {body.info}
                                            </div>
                                          </Collapse>
                                        </div>
                                        : null
                                      }
                                    </>
                                    {body.recommendation ?
                                      <FoodTagsRecommendator
                                        prefer={parseRecommendation(body.recommendation[resultsState.body_weight.predisposition], Recommendation.PREFER)}
                                        reduce={parseRecommendation(body.recommendation[resultsState.body_weight.predisposition], Recommendation.AVOID)}
                                        avoid={parseRecommendation(body.recommendation[resultsState.body_weight.predisposition], Recommendation.REDUCE)}
                                        navigateTo={
                                          buildFoodTagArgumentsUrl(
                                            CategoryIds.BODY,
                                            BodyDescription.topCard.id,
                                            resultsState.body_weight.predisposition)
                                        }
                                        singleColumnRecommendation
                                      />
                                      : null
                                    }
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            })}
                          </div>
                        </AccordionComponent>
                      </Card.Body>
                    </div>
                  </CollapseIcon>

                </Card>
              </Container>
              : null}

            {resultsState.body_lean.prs_to_1 !== null ?
              <Container className="px-0 subcategories-cards-container">

                <Card className="sbcat-card">
                  <Card.Header className="d-flex flex-column justify-content-center align-items-center">
                    <div>{BodyDescription.bottomCard.name}</div>

                    <div className="d-block d-md-none">
                      <ButtonBadgeComponent size={3} content={<Muscle />} round noInfo />
                    </div>

                    <div className="body-lean-predisposition">
                      <div className="d-none d-md-block">
                        <ButtonBadgeComponent size={4} content={<Muscle />} round noInfo />
                      </div>

                      <div className="text-container">
                        <h4>
                          <Badge pill className={`px-4 py-2 ${resultsState.body_lean.predisposition !== PredispositionIds.LOW ? '' : 'medium'}`}>
                            Predisposición {
                              BodyDescription.bottomCard.topCard.header.replace(
                                '%[PREDISPOSITION_VALUE]%',
                                resultsState.body_lean.predisposition === PredispositionIds.LOW ? 'Ventaja' : 'Normal'
                              )
                            }
                          </Badge>
                        </h4>
                        <Collapse in={collapseBottomOpen}>
                          <div className="info-body-lean">
                            <div>{BodyDescription.bottomCard.topCard.body[resultsState.body_lean.predisposition]}</div>
                            <div>
                              El <span className="text-secondary"><b>32% de las personas</b>{' '}</span> en el estudio tenían una ventaja genética que les permite tener 2,6 kg
                              más de masa muscular comparado con quienes no la tienen.
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Card.Header>

                  <CollapseIcon iconSize={2} open={collapseBottomOpen} setOpen={setCollapseBottomOpen}>
                    <div>
                      <Card.Body>
                        <AccordionComponent>
                          <div className="accordion-elements">
                            {BodyDescription.bottomCard.accordionElements.map((a, i) => {
                              const { id, button, body } = a;
                              return (
                                <Accordion.Item
                                  key={`body-bottomcard-accordion${id}-${i}`}
                                  eventKey={`body-bottomcard-accordion${id}-${i}`}
                                >
                                  <Accordion.Header>{button}</Accordion.Header>
                                  <Accordion.Body>
                                    <FoodTagsRecommendator
                                      singleColumnRecommendation
                                      prefer={parseRecommendation(body[resultsState.body_lean.predisposition], Recommendation.PREFER)}
                                      reduce={parseRecommendation(body[resultsState.body_lean.predisposition], Recommendation.AVOID)}
                                      avoid={parseRecommendation(body[resultsState.body_lean.predisposition], Recommendation.REDUCE)}
                                      navigateTo={
                                        buildFoodTagArgumentsUrl(
                                          CategoryIds.BODY,
                                          BodyDescription.bottomCard.id,
                                          resultsState.body_lean.predisposition)
                                      }
                                    />
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            })}
                            <div className="d-none d-md-flex flex-column justify-content-center align-items-center">
                              <strong>Genes</strong>
                              <div>
                                {BodyDescription.bottomCard.examples.map((e, i) => (
                                  <h6 key={`genes-body-weight-${i}`}><Badge pill>{e}</Badge></h6>
                                ))}
                              </div>
                            </div>
                          </div>
                        </AccordionComponent>
                      </Card.Body>

                      <Card.Footer className="d-flex d-md-none">
                        {BodyDescription.bottomCard.examples.map((e, i) => (
                          <h6 key={`genes-body-weight-${i}`}><Badge pill>{e}</Badge></h6>
                        ))}
                      </Card.Footer>
                    </div>
                  </CollapseIcon>
                </Card>
              </Container>
              : null}
          </>
          : <LoadingPage />

        }
      </div>
    </MainLayout >
  )
}


export const Mechanisms = () => {
  const [activeButton, setActiveButton] = useState<
    {
      id: BodyButtonsAccordion,
      title: string,
      description: string
    } | null>(null);

  const handleClick = (item: BodyButtonsAccordion) => {
    const buttonArrayItem = BodyDescription.topCard.accordionElements[0].body.buttons?.[item] || null;
    setActiveButton(activeButton?.id === item ? null : buttonArrayItem);
  }

  return (
    <div className="d-flex flex-column gap-md-4 justify-content-center">
      <div className="body-mechanisms-chart">
        <div className='buttons-row'>
          <div className="left-button">
            <ButtonBadgeComponent
              m={0}
              size={3}
              content={<Tired />}
              onClick={() => handleClick(BodyButtonsAccordion.ENERGY_USAGE)}
              active={activeButton?.id === BodyButtonsAccordion.ENERGY_USAGE}
              round
            />
          </div>
          <div className="center-icons">
            <Person id='person' />
          </div>
          <div className="right-button">
            <ButtonBadgeComponent
              m={0}
              size={3}
              content={<Battery />}
              onClick={() => handleClick(BodyButtonsAccordion.FAT_METABOLISM)}
              active={activeButton?.id === BodyButtonsAccordion.FAT_METABOLISM}
              round
            />
          </div>
        </div>
        <div className="lines-row">
          <Lines id='lines' />
        </div>
        <div className='bottom-row'>
          <div className='justify-content-start justify-content-lg-center'>
            <ButtonBadgeComponent
              size={3}
              content={<Stress />}
              onClick={() => handleClick(BodyButtonsAccordion.OXIDATIVE_STRESS)}
              active={activeButton?.id === BodyButtonsAccordion.OXIDATIVE_STRESS}
              round
            />
          </div>
          <div>
            <ButtonBadgeComponent
              size={3}
              content={<Clock />}
              onClick={() => handleClick(BodyButtonsAccordion.CIRCADIAN_RYTHM)}
              active={activeButton?.id === BodyButtonsAccordion.CIRCADIAN_RYTHM}
              round
            />
          </div>
          <div>
            <ButtonBadgeComponent
              size={3}
              content={<Plate />}
              onClick={() => handleClick(BodyButtonsAccordion.FOOD_REGULATION)}
              active={activeButton?.id === BodyButtonsAccordion.FOOD_REGULATION}
              round
            />
          </div>
          <div className='justify-content-end justify-content-lg-center'>
            <ButtonBadgeComponent
              size={3}
              content={<Fat />}
              onClick={() => handleClick(BodyButtonsAccordion.FAT_ACCUMULATION)}
              active={activeButton?.id === BodyButtonsAccordion.FAT_ACCUMULATION}
              round
            />
          </div>
        </div>
      </div>

      <Collapse in={activeButton !== null}>
        <div>
          <ResponsiveCard
            md
            title={<div className="mynu-stars text-center">{activeButton?.title ?? ''}</div>}
            description={activeButton?.description ?? ''}
            subtitle="Genes"
            examples={BodyDescription.topCard.accordionElements[0].examples ?? undefined}
          />
        </div>
      </Collapse>
    </div>
  )
}
