import { axiosInstance } from "@/api/http";
import { MessagesResponse } from "@/models";


export async function sendMessageToChat(question: string): Promise<string> {
  const userToken = localStorage.getItem('userToken');
  if (!userToken) {
    throw new Error('Sesion expirada');
  }
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    const response = await axiosInstance.post(
      '/chat-messages',
      { question },
      { headers }
    );
    const assistantResponse: string = response.data;
    if (assistantResponse) {
      return assistantResponse;
    }
    throw new Error('Ocurrió un problema');
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema');
  }
}

export async function saveMessage(role: string, question: string): Promise<string> {
  const userToken = localStorage.getItem('userToken');
  if (!userToken) {
    throw new Error('Sesion expirada');
  }
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    return await axiosInstance.post(
      '/chat-messages/save',
      { question, role },
      { headers }
    );
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema');
  }
}

export async function getMessageHistory(skip: number, amount: number): Promise<MessagesResponse> {
  const userToken = localStorage.getItem('userToken');
  if (!userToken) {
    throw new Error('Sesion expirada');
  }
  try {
    const headers = { "Authorization": `Bearer ${userToken}` };
    const response = await axiosInstance.get(
      `/chat-messages?skip=${skip}&amount=${amount}`,
      { headers }
    );

    const { messages, allFetched } = response.data;
    return { foundMessages: messages, allFetched };

  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema al obtener el historial de mensajes');
  }
}

export async function changeDailyMenu(day: string): Promise<boolean> {
  const userToken = localStorage.getItem('userToken');
  if (!userToken) {
    throw new Error('Sesion expirada');
  }
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.post('/chat-messages/change-diet', { day }, { headers });
    return true;
  } catch (error) {
    throw new Error('Ocurrió un problema al actualizar tu dieta');
  }
}

export async function rateMessage(rate: string, messageId: string): Promise<boolean> {
  const userToken = localStorage.getItem('userToken');
  if (!userToken) {
    throw new Error('Sesion expirada');
  }
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.patch('/chat-messages/rate', {
      rate,
      messageId
    }, { headers });
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('Ocurrió un problema al calificar el mensaje');
  }
}
