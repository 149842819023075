import { useState } from "react";
import Card from "react-bootstrap/esm/Card";

import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";
import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import StopLight from "@/components/StopLight/StopLight";
import UGRSBarComponent from "@/components/UGRSBar/UGRSBarComponent";
import AllergiesDiagram from "../AllergiesComponent/AllergiesDiagram/AllergiesDiagram";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";

import { allergiesDiagramIds } from "../../ResultsComponentsModel";

import heneggImg from '@/assets/results/allergies/images/henegg.webp';
import emptyPerson from '@/assets/results/results-tutorial/empty-person.webp';
import halfPerson from '@/assets/results/results-tutorial/half-person.webp';
import peopleGroup from '@/assets/results/results-tutorial/people-group.webp';

import { CheckCircle, DashCircleFill, InfoCircleFill, PlusCircleFill, XCircle } from "react-bootstrap-icons";

import '../../components/AllergiesComponent/AllergiesDiagram/AllergiesDiagram.scss';
import '../../GeneticResults.scss';
import './ResultsTutorial.scss';
import { MYNU_PRIMARY_COLOR } from "@/utils/constants";

type Step = {
  id: string,
  reverse: boolean,
  title: JSX.Element,
  description: JSX.Element,
  content: JSX.Element,
  collapseElementTitle?: JSX.Element,
  collapseElement?: JSX.Element,
}


export default function ResultsTutorial() {
  return (
    <div className="results-tutorial-container">
      {tutorialSteps.map((s, i) =>
        <TutorialCard
          key={`results-tutorial-card-${s.id}-${i}`}
          index={i + 1}
          id={s.id}
          reverse={s.reverse}
          title={s.title}
          description={s.description}
          content={s.content}
          collapseElementTitle={s.collapseElementTitle}
          collapseElement={s.collapseElement}
        />
      )}
    </div>
  )
}

const Tooltip = () => {
  return (
    <ResponsiveCard
      md
      icon={<InfoCircleFill className="mynu-stars" size={'2em'} style={{ margin: '1rem 0' }} />}
      title={<h4 className="mynu-stars"><b>Tooltip</b></h4>}
      description="Los encontrarás en todos tus resultados y te ayudarán a saber el significado de conceptos más complejos y/o de tus gráficos. Presiónalos."
    />
  )
}

const TutorialCard = (
  props: {
    index: number,
    id: string,
    reverse: boolean,
    title: JSX.Element,
    description: JSX.Element,
    content?: JSX.Element,
    collapseElementTitle?: JSX.Element,
    collapseElement?: JSX.Element,
  }
) => {
  const {
    index, id, reverse, title,
    description, content, collapseElement, collapseElementTitle
  } = props;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <Card className={`tutorial-card ${id}`}
        style={{
          '--card-direction': reverse ? 'row-reverse' : 'row',
        } as React.CSSProperties}
      >
        <div className="tutorial-card-title-description">
          <div className="pb-4 mynu-stars d-flex flex-row flex-md-column gap-3 align-items-baseline">
            <span className="number">{index}. </span>
            <span className="text">{title}</span>
          </div>
          <div className="pb-4">{description}</div>
        </div>
        <div className="tutorial-card-content">
          {content ?? null}
        </div>
      </Card >

      {collapseElement ?

        <div className="collapse-container">
          {collapseElementTitle}
          <CollapseIcon open={collapsed} setOpen={setCollapsed} >
            {collapseElement}
          </CollapseIcon>
        </div>
        : null
      }
    </>
  )
}

const tutorialSteps: Step[] = [
  {
    id: 'tutorial-percentile',
    reverse: true,
    title: <span>Percentil</span>,
    description: <span><span>Esta barra indica el <strong>percentil de tu riesgo genético</strong> para cierta condición, en comparación con el <strong>resto de las personas.</strong></span></span>,
    content: <>
      <CustomSliderComponent
        size={"2rem"}
        percent={65}
        intervals={['Menor', 'Promedio', 'Mayor']}
        intervalsDescriptions={[
          'Tienes menos riesgo que la población general',
          'Tu riesgo es similar al de la población general',
          'Tienes más riesgo que la población general'
        ]}
      />
      <Tooltip />
    </>,
    collapseElementTitle: <span style={{ fontSize: '1.2rem' }}>
      Mi <span style={{ color: MYNU_PRIMARY_COLOR, fontWeight: 'bold' }}>{' '}Riesgo</span>
      <span style={{ color: MYNU_PRIMARY_COLOR, fontWeight: 'bold' }}>{' '}v/s{' '}</span>
      Mi <span style={{ color: MYNU_PRIMARY_COLOR, fontWeight: 'bold' }}>{' '}Percentil</span>
    </span>,
    collapseElement:
      <div className="d-flex flex-column flex-md-row align-items-center gap-3">
        <div className="d-flex flex-row justify-content-between align-items-center gap-3">
          <div className="d-flex flex-column gap-2 align-items-center text-center">
            <img src={peopleGroup} alt="" className="people-group" />
            <span className="mx-3">Población de Referencia</span>
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="w-50 px-3 d-flex flex-column gap-2 align-items-center text-center">
              <img src={emptyPerson} alt="" className="empty-person" />
              <span className="mx-3" style={{ fontSize: 'small' }}>Sin predisposición genética (Riesgo = 1)</span>
            </div>
            <div className="w-50 d-flex flex-column gap-2 align-items-center text-center">
              <img src={halfPerson} alt="" className="half-person" />
              <span className="mx-3" style={{ fontSize: 'small', minWidth: '7vw' }}>Yo (4.3 veces más riesgo)</span>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'justify' }}>
          El valor de riesgo muestra <strong>cuánto riesgo</strong> tienes comparado con alguien que no tiene riesgo genético. El percentil te compara con el <strong>resto de las personas</strong>.
          <br /><br />Por ejemplo, podrías tener <strong>riesgo alto</strong> pero estar en un <strong>percentil bajo</strong> si muchas personas también tienen riesgos altos para esa condición, y viceversa.
        </div>
      </div>
  },
  {
    id: 'tutorial-risk',
    reverse: false,
    title: <span>Tu <strong>riesgo</strong></span>,
    description: <span>Indica <strong>cuántas veces más probable</strong> eres de tener esa condición en comparación con el resto de las personas.</span>,
    content: <>
      <div className="d-flex flex-row align-items-center gap-2">
        <div className="mynu-stars d-flex align-items-center" style={{ fontSize: '1.7rem' }}>
          <PlusCircleFill />
          <div className="mx-2"><strong>1</strong></div>
        </div>
        <div>
          <span className="mynu-stars">Si es mayor a 1:</span> tienes <strong>más riesgo</strong> que una persona sin predisposición genética.
        </div>
      </div>
      <div className="d-flex flex-row align-items-center gap-2">
        <div className="mynu-secondary-text d-flex align-items-center" style={{ fontSize: '1.7rem' }}>
          <DashCircleFill />
          <div className="mx-2"><strong>1</strong></div>
        </div>
        <div>
          <span className="mynu-secondary-text">Si es menor a 1:</span> tienes <strong>menos riesgo</strong> que una persona sin predisposición genética.
        </div>
      </div>
    </>
  },
  {
    id: 'tutorial-stoplight',
    reverse: true,
    title: <span><strong>Semáforo de riesgo</strong></span>,
    description: <span>Según la categoría en la que te encuentres, te ayudará a saber el nivel de <strong>tu riesgo genético</strong> a esa condición.</span>,
    content: <>
      <div className="text-center"><strong>Riesgo o Intolerancia - <span style={{ color: '#FE5133' }}>Alta</span></strong></div>
      <div className="w-100 d-flex justify-content-center">
        <StopLight colors={['#34B692', '#3900B3', '#FE5133']} titles={['Baja', 'Media', 'Alta']} activeIndex={2} descriptions={[]} changable={false} />
      </div>
    </>
  },
  {
    id: 'tutorial-efficiency',
    reverse: false,
    title: <span>Eficiencia</span>,
    description: <span>Gráfico de barra, que te indicará <strong>qué tan eficiente</strong> podría ser ese patrón alimenticio para ayudarte a cumplir tus <strong>objetivos</strong>, según tu genética.</span>,
    content: <div >
      <div style={{ margin: '0 0 4rem' }}>
        <UGRSBarComponent
          value={90}
          bounds={[0, 100]}
          label1={
            <div className="mt-1 d-flex flex-row justify-content-start" style={{ color: '#FE5133' }}>
              <div className="px-2 "> <XCircle /> </div> <div> Baja eficiencia </div>
            </div>
          }
          label2={
            <div className="mt-1 d-flex flex-row justify-content-end" style={{ color: '#33B591' }}>
              <div className="px-2 "> Alta Eficiencia </div> <div> <CheckCircle /> </div>
            </div>
          }
        />
      </div>
      <div style={{ margin: '5rem 0 4rem' }}>
        <UGRSBarComponent
          value={10}
          bounds={[-10, 100]}
          label1={
            <div className="mt-1 d-flex flex-row justify-content-start" style={{ color: '#FE5133' }}>
              <div className="px-2 "> <XCircle /> </div> <div> Baja eficiencia </div>
            </div>
          }
          label2={
            <div className="mt-1 d-flex flex-row justify-content-end" style={{ color: '#33B591' }}>
              <div className="px-2 "> Alta Eficiencia </div> <div> <CheckCircle /></div>
            </div>
          } />
      </div>
    </div>
  },
  {
    id: 'tutorial-allergies',
    reverse: false,
    title: <span>Factores <strong>genéticos y ambientales</strong></span>,
    description: <span>
      El círculo morado indica el <strong>factor genético</strong> y
      el círculo naranja representa los <strong>factores ambientales</strong>.
      Los íconos a los lados indican factores que participan en el desarrollo de las alergias
    </span>,
    content:
      <AllergiesDiagram
        activeIds={[allergiesDiagramIds.SKIN, allergiesDiagramIds.OTHERS]}
        imgSrc={heneggImg}
        multiplier={1}
        tutorial
      />
  }
]