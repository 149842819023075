import {
  useEffect,
  useState,
  useCallback,
  useMemo
} from "react";

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import { FormNutrition, iconOptionToCheckBoxOption, QuestionnaireOptions } from '@/models';

import QuestionnaireExitModal from './QuestionnaireExitModal';
import CloseQuestionnaire from './CloseQuestionnaire';
import EndOfSection from "./components/EndOfSection";
import CheckBoxComponent from "./components/CheckBoxComponent";
import NumericComponent from "./components/NumberComponent";
import SelectComponent from "./components/SelectComponent";
import QuestionnaireHeader from "./components/QuestionnaireSectionHeader";
import TextComponent from "./components/TextComponent";

import { hasIntersectionOfSize } from "@/utils";

import './Questionnaire.scss';

interface QuestionnaireFormNutritionProps {
  options: QuestionnaireOptions;
  updateFormAndGoToNextSection: (form: FormNutrition) => void;
  currentForm: FormNutrition;
  goBack: (form: FormNutrition) => void;
  close: (form: FormNutrition, save: boolean) => void;
}

const QuestionnaireFormNutrition: React.FC<QuestionnaireFormNutritionProps> = ({
  options,
  updateFormAndGoToNextSection,
  currentForm,
  goBack,
  close
}) => {
  const [validated, setValidated] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const [form, setForm] = useState<FormNutrition>({
    mealsPerDayIds: [],
    dietRestrictionTypeIds: [],
    dietTypeId: undefined,
    intermitentFastingHours: undefined,
    intermitentFastingFirstMealHour: undefined,
    animalProteinPreferenceIds: [],
    vegetalProteinPreferenceIds: [],
    legumePreferenceIds: [],
    carbPreferenceIds: [],
    fatPreferenceIds: [],
    lactosePreferenceIds: [],
    fruitPreferenceIds: [],
    vegetablePreferenceIds: [],
    foodSupplementIds: [],
    vitaminSupplementsText: undefined,
    mineralSupplementsText: undefined,
    otherSupplementsText: undefined,
    dailyWaterConsumptionLevelId: undefined,
    sugaryDrinkFrequencyId: undefined,
    nonSugarDrinkFrequenceId: undefined,
    generalSweetsFrequencyId: undefined,
    generalFastFoodFrequencyId: undefined,
    apetiteFeelingId: undefined
  });

  const ovo_veg_type = ['Huevo Entero', 'Clara de Huevo']
  const pesc_type = ['Pescado', 'Mariscos', 'Atún']

  type dietType = 'vegan' | 'lacto-vegetarian' | 'ovo-vegetarian' | 'ovo-lacto-vegetarian' | 'pescatarian' | 'no-gluten' | 'fasting' | 'none';

  const dietTypeRestriction = useMemo(() => {
    const restrictionString = form.dietRestrictionTypeIds.map(char => char.toLowerCase()).join('-');

    let dietRestriction = {
      'vegan': false,
      'lacto-vegetarian': false,
      'ovo-vegetarian': false,
      'ovo-lacto-vegetarian': false,
      'pescatarian': false,
      'no-gluten': false,
      'fasting': false,
      'none': true
    }

    if (!restrictionString) {
      return dietRestriction;
    }

    dietRestriction[restrictionString as dietType] = true;
    dietRestriction['none'] = false;

    return dietRestriction;

  }, [form.dietRestrictionTypeIds]);

  useEffect(() => {
    setForm(currentForm);
  }, [currentForm]);

  const handleSendButton = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (event.currentTarget.checkValidity()) {
      updateFormAndGoToNextSection(form);
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const updateField = useCallback((name: string, newValue: any) => {
    const newValues = {
      ...form,
      [name]: newValue,
    }
    setForm(newValues);
  }, [form]);

  const handleExit = () => {
    setShowExitModal(true);
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <div className="py-3">
        <CloseQuestionnaire onClick={handleExit} text="Volver al inicio" />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSendButton}>

        <QuestionnaireHeader
          title="Enfoque en tu nutrición"
          subtitle="Tener más detalles sobre tu nutrición nos brindará información relevante para tu plan nutricional"
          numerator={3}
          denominator={3}
        />

        <CheckBoxComponent
          name="mealsPerDayIds"
          required={true}
          question="¿Cuál de estos tiempos de comida consumes?"
          description={"Selecciona al menos tres opciones y al menos dos comidas principales (desayuno, almuerzo o cena)"}
          options={options.dailyMealOptions}
          isValid={(selectedIds: string[]) => {
            return selectedIds.length >= 3
              && hasIntersectionOfSize(['breakfast', 'lunch', 'dinner'], selectedIds, 2)
          }}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('mealsPerDayIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.mealsPerDayIds || []}
        />

        <CheckBoxComponent
          name="dietRestrictionTypeIds"
          question="¿Sigues algún patrón alimentario?"
          options={options.dietRestrictionOptions}
          isValid={(selectedIds: string[]) => selectedIds.length === 1}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('dietRestrictionTypeIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.dietRestrictionTypeIds || []}
          required
          single
          hasNone
        />

        <CheckBoxComponent
          name="dietType"
          question="¿Sigues algún tipo de dieta?"
          options={options.dietTypeOptions}
          isValid={(selectedIds: string[]) => selectedIds.length === 1}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('dietTypeId', selectedIds[0]);
          }}
          showInvalid={validated}
          initiallySelectedIds={(form.dietTypeId ? [form.dietTypeId] : undefined) || []}
          required
          single
        />

        {dietTypeRestriction['fasting'] && <>
          <NumericComponent
            name="intermitentFastingHours"
            question="¿Por cuántas horas seguidas ayunas?"
            className='fasting-time'
            required={true}
            placeholder="Un numero menor a 24"
            updateValue={(newValue) => {
              updateField('intermitentFastingHours', newValue);
            }}
            isValid={(newValue) => newValue < 24}
            numberType="float"
            showInvalid={validated}
            initialValue={form.intermitentFastingHours}
          />

          <SelectComponent
            name="intermitentFastingFirstMealHour"
            question="¿A qué hora es tu primera comida?"
            options={Array.from({ length: 24 }, (_, index) => index).map(hr => {
              return {
                id: hr.toString().padStart(2, '0') + ':00',
                label: hr.toString().padStart(2, '0') + ':00'
              };
            })}
            required={true}
            updateValue={(newValue) => {
              updateField('intermitentFastingFirstMealHour', newValue);
            }}
            isValid={(newValue) => true}
            showInvalid={validated}
            initialValue={form.intermitentFastingFirstMealHour}
          />

        </>}
        {!dietTypeRestriction['vegan'] && !dietTypeRestriction['lacto-vegetarian'] ?
          <CheckBoxComponent
            name="animalProteinPreferenceIds"
            required={true}
            question="Selecciona las fuentes de proteína animal que te gustan"
            description={
              dietTypeRestriction['ovo-lacto-vegetarian']
                || dietTypeRestriction['ovo-vegetarian']
                || dietTypeRestriction['pescatarian']
                ? undefined : renderCheckboxDescription("3")
            }
            options={
              dietTypeRestriction['ovo-lacto-vegetarian']
                || dietTypeRestriction['ovo-vegetarian']
                ? options.preferredFoodOptions.animalProteinOptions
                  .filter(o => ovo_veg_type.includes(o.label))
                  .map(opt => iconOptionToCheckBoxOption(opt))
                : dietTypeRestriction['pescatarian'] ?
                  options.preferredFoodOptions.animalProteinOptions
                    .filter(o => pesc_type.includes(o.label))
                    .map(opt => iconOptionToCheckBoxOption(opt))

                  : options.preferredFoodOptions.animalProteinOptions
                    .map(opt => iconOptionToCheckBoxOption(opt))
            }
            isValid={(selectedIds: string[]) =>
              dietTypeRestriction['ovo-lacto-vegetarian']
                || dietTypeRestriction['ovo-vegetarian']
                ? true : selectedIds.length > 2
            }
            updateSelectedIds={(selectedIds: string[]) => {
              updateField('animalProteinPreferenceIds', selectedIds);
            }}
            showInvalid={validated}
            initiallySelectedIds={form.animalProteinPreferenceIds ?? []}
          />
          : null
        }

        {(dietTypeRestriction['vegan']
          || dietTypeRestriction['lacto-vegetarian']
          || dietTypeRestriction['ovo-vegetarian']
          || dietTypeRestriction['ovo-lacto-vegetarian']) &&
          <CheckBoxComponent
            name="vegetalProteinPreferenceIds"
            required={true}
            question="Selecciona las fuentes de proteína vegetal que te gustan"
            description={renderCheckboxDescription("3")}
            options={options.preferredFoodOptions.vegetalProteinOptions.map(opt => iconOptionToCheckBoxOption(opt))}
            isValid={(selectedIds: string[]) => selectedIds.length > 2}
            updateSelectedIds={(selectedIds: string[]) => {
              updateField('vegetalProteinPreferenceIds', selectedIds);
            }}
            showInvalid={validated}
            initiallySelectedIds={form.vegetalProteinPreferenceIds ?? []}
          />}

        <CheckBoxComponent
          name="legumePreferenceIds"
          required={true}
          question="Selecciona todas las legumbres que te gustaría incorporar"
          description={renderCheckboxDescription("2")}
          options={options.preferredFoodOptions.legumeOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 2}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('legumePreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.legumePreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="carbPreferenceIds"
          required={true}
          question="Selecciona todos los carbohidratos que te gustaría incorporar"
          description={renderCheckboxDescription("3")}
          options={options.preferredFoodOptions.carbohydrateOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 3}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('carbPreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.carbPreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="fatPreferenceIds"
          required={true}
          question="Selecciona todos los alimentos lipídicos que te gustaría incorporar"
          description={renderCheckboxDescription("3")}
          options={options.preferredFoodOptions.lipidOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 3}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('fatPreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.fatPreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="lactosePreferenceIds"
          required={true}
          question="Selecciona todos los lácteos que te gustaría incorporar"
          description={renderCheckboxDescription("2")}
          options={options.preferredFoodOptions.dairyOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 2}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('lactosePreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.lactosePreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="fruitPreferenceIds"
          required={true}
          question="Selecciona todos las frutas que te gustaría incorporar"
          description={renderCheckboxDescription("2")}
          options={options.preferredFoodOptions.fruitOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 2}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('fruitPreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.fruitPreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="vegetablePreferenceIds"
          required={true}
          question="Selecciona todos las verduras que te gustaría incorporar"
          description={renderCheckboxDescription("2")}
          options={options.preferredFoodOptions.vegetableOptions.map(opt => iconOptionToCheckBoxOption(opt))}
          isValid={(selectedIds: string[]) => selectedIds.length >= 2}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('vegetablePreferenceIds', selectedIds);
          }}
          showInvalid={validated}
          initiallySelectedIds={form.vegetablePreferenceIds ?? []}
        />

        <CheckBoxComponent
          name="foodSupplementIds"
          question="¿Consumes algún suplemento alimenticio asociado a las siguientes categorías?"
          required
          options={options.foodSuplementOptions}
          updateSelectedIds={(selectedIds: string[]) => {
            updateField('foodSupplementIds', selectedIds);
          }}
          isValid={(selectedOptions: string[]) => selectedOptions.length > 0}
          initiallySelectedIds={form.foodSupplementIds}
          hasNone
        />

        {form.foodSupplementIds.includes('vitamins') && <>
          <TextComponent
            name="vitaminSupplementsText"
            question="Qué tipo de suplementos vitamínicos consumes?"
            required={true}
            updateText={(newText) => {
              updateField('vitaminSupplementsText', newText);
            }}
            isValid={(text) => true}
            initialText={form.vitaminSupplementsText}
          />
        </>}

        {form.foodSupplementIds.includes('minerals') && <>
          <TextComponent
            name="mineralSupplementsText"
            question="Qué tipo de suplementos minerales consumes?"
            required={true}
            updateText={(newText) => {
              updateField('mineralSupplementsText', newText);
            }}
            isValid={(text) => true}
            initialText={form.mineralSupplementsText}
          />
        </>}

        {form.foodSupplementIds.includes('others') && <>
          <TextComponent
            name="otherSupplementsText"
            question="¿Qué otros suplementos alimenticios consumes?"
            required={true}
            updateText={(newText) => {
              updateField('otherSupplementsText', newText);
            }}
            isValid={(text) => true}
            initialText={form.otherSupplementsText}
          />
        </>}

        <SelectComponent
          name="dailyWaterConsumptionLevelId"
          question="¿Cuánta agua bebes diariamente?"
          required={true}
          options={options.waterConsumptionFrequencyOptions}
          updateValue={(optionId: string) => {
            updateField('dailyWaterConsumptionLevelId', optionId);
          }}
          initialValue={form.dailyWaterConsumptionLevelId}
        />

        <SelectComponent
          name="sugaryDrinkFrequencyId"
          question="¿Con qué frecuencia tomas jugos o bebidas azucaradas?"
          required={true}
          options={options.frequencyIntervalOptions}
          updateValue={(optionId: string) => {
            updateField('sugaryDrinkFrequencyId', optionId);
          }}
          initialValue={form.sugaryDrinkFrequencyId}
        />

        <SelectComponent
          name="nonSugarDrinkFrequenceId"
          question="¿Con qué frecuencia tomas jugos o bebidas sin azúcar?"
          required={true}
          options={options.frequencyIntervalOptions}
          updateValue={(optionId: string) => {
            updateField('nonSugarDrinkFrequenceId', optionId);
          }}
          initialValue={form.nonSugarDrinkFrequenceId}
        />

        <SelectComponent
          name="generalSweetsFrequencyId"
          question="¿Con qué frecuencia consumes golosinas, galletas, chocolates, pastelería, etc.?"
          required={true}
          options={options.frequencyIntervalOptions}
          updateValue={(optionId: string) => {
            updateField('generalSweetsFrequencyId', optionId);
          }}
          initialValue={form.generalSweetsFrequencyId}
        />

        <SelectComponent
          name="generalFastFoodFrequencyId"
          question="¿Con qué frecuencia consumes comida rápida, fritura, snacks salados, etc.?"
          required={true}
          options={options.frequencyIntervalOptions}
          updateValue={(optionId: string) => {
            updateField('generalFastFoodFrequencyId', optionId);
          }}
          initialValue={form.generalFastFoodFrequencyId}
        />

        <SelectComponent
          name="apetiteFeelingId"
          question="¿Cómo es tu relación con la alimentación?"
          required={true}
          options={options.apetiteFeelingOptions}
          updateValue={(optionId: string) => {
            updateField('apetiteFeelingId', optionId);
          }}
          initialValue={form.apetiteFeelingId}
        />

        <EndOfSection
          previousSectionButtonText="Atrás"
          previousSectionButtonHandler={() => { goBack(form) }}
          nextSectionButtonText="Enviar"
        />

        <br></br>

      </Form>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Porfavor completa todos los campos</Modal.Title>
        </Modal.Header>
      </Modal>

      <QuestionnaireExitModal
        show={showExitModal}
        onClose={() => setShowExitModal(false)}
        onExit={(save) => close(form, save)}
      />
    </Container>
  );
}

export default QuestionnaireFormNutrition;


const renderCheckboxDescription = (amount: string) => {
  return (
    <>
      {`Selecciona al menos ${amount} opciones.`}
      <br />
      <span><i>Recuerda: Los alimentos seleccionados aparecerán en tu dieta con mayor frecuencia,
        pero esto no impide que los que no selecciones puedan aparecer en ella.</i></span>
    </>
  )
}