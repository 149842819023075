export const calculateBackoffTime = (attempt: number): number => {
  return 2000;
};

/**
 * Checks is value is (undefined or null) and empty
 * @param value any: the value to be tested 
 * @param kind string: the type to check if empty
 * @returns 
 */
export const isEmptyOrUndefined = (value: any, kind: string): value is undefined | null | [] => {
  if (value === undefined || value === null) return true;

  switch (kind) {
    case 'str':
      return (typeof (value) === 'string' && value.trim() === '');
    case 'arr':
      return (Array.isArray(value) && value.length === 0);
    case 'obj':
      return (typeof (value) === 'object' && Object.keys(value).length === 0);
    default:
      return false;
  }
}

export const parseBool = (value: any): value is true => value === 'true' || value === 1 || value === true;

/**
 * Removes all undefined and null values from an array
 * @param value Input array to filter
 * @returns Array with undefined and null values removed
 */
export const rmUndefinedOrNulls = <T>(value: (T | undefined | null)[]): T[] => {
  return value.filter((v): v is T => v !== undefined && v !== null);
}


/**
 * Removes duplicate objects/values from an array or object
 * @param arr Input array to deduplicate
 * @param remove Type of deduplication ('obj' or 'arr')
 * @param key Optional key for object deduplication
 * @returns Deduplicated array
 */
export const rmDuplicates = <T>(arr: T[], remove: 'obj' | 'arr', key?: keyof T): T[] => {
  switch (remove) {
    case 'obj':
      if (!key) {
        throw new Error('Key is required to remove duplicates from an object');
      }
      return arr.filter((v, i, a) => a.findIndex(t => t[key] === v[key]) === i);
    case 'arr':
      return arr.filter((v, i, a) => a.indexOf(v) === i);
  }
}

/**
 * formats a number to chile currency format
 * @param {number} value to apply format
 * @returns string with desired format
 */
export const formatCLP = (value: number): string => {
  return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
}

/**
 * Checks if two strings are similar based on a character-wise similarity threshold
 * @param word1 First string to compare
 * @param word2 Second string to compare
 * @param threshold Similarity threshold (0-1)
 * @returns Whether the strings are similar enough
 */
export const compareStrings = (word1: string, word2: string, threshold: number): boolean => {
  // Validate inputs
  if (!word1 || !word2) throw new Error('No strings to compare');
  if (threshold < 0 || threshold > 1) throw new Error('Threshold must be between 0 and 1');

  // Remove spaces, emojis, and convert to lowercase
  const regex = /[\s\p{Emoji_Presentation}]/gu;
  const cleanWord1 = Array.from(new Set(word1.toLowerCase().replaceAll(regex, '')));
  const cleanWord2 = Array.from(new Set(word2.toLowerCase().replaceAll(regex, '')));

  const uniqueChars1 = new Set(cleanWord1);
  const uniqueChars2 = new Set(cleanWord2);

  const intersection = new Set(
    [...uniqueChars1].filter(char => uniqueChars2.has(char))
  );

  const maxLength = Math.max(uniqueChars1.size, uniqueChars2.size);
  const similarity = intersection.size / maxLength;

  // Compare against threshold
  return similarity >= threshold;
}

/** 
 * Returns a normalized string without its emojis
 * @param s string to be normalized
 * @returns normalized string with NFD type and no emojis 
 */
export const normalizeString = (s: string): string => {
  return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}


export const truncateTo = (n: number, d: number) => {
  return Math.trunc(n * (10**d)) / (10**d);
}

export function colorFromPercentage(
  percentage: number,
  color1: string,
  color2: string,
  color3: string
): string {
  const rgbGreen = hexToRgb(color1);
  const rgbPurple = hexToRgb(color2);
  const rgbRed = hexToRgb(color3);

  // Interpola entre los colores basándose en el porcentaje
  let interpolatedColor = [];
  if (percentage <= 50) {
    const factor = percentage / 50;
    for (let i = 0; i < 3; i++) {
      interpolatedColor[i] = Math.round(
        rgbGreen[i] + factor * (rgbPurple[i] - rgbGreen[i])
      );
    }
  } else {
    const factor = (percentage - 50) / 50;
    for (let i = 0; i < 3; i++) {
      interpolatedColor[i] = Math.round(
        rgbPurple[i] + factor * (rgbRed[i] - rgbPurple[i])
      );
    }
  }

  const colorHex = interpolatedColor.reduce((acc, val) => {
    return acc + ("0" + val.toString(16)).slice(-2);
  }, "#");

  return colorHex;
}

export function hexToRgb(hex: string) {
  hex = hex.replace(/^#/, "");

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}


/**
 * Compares two arrays for equality.
 * Checks if both arrays have the same length and if each corresponding element is equal.
 * This function works with arrays of any elemental type (e.g., string, number, boolean).
 *
 * @template T - The type of the elements in the arrays (e.g., string, number, boolean).
 * @param {T[] | undefined} x - The first array to compare.
 * @param {T[] | undefined} y - The second array to compare.
 * @returns {boolean} - Returns `true` if the arrays are equal, `false` otherwise.
 */
export const areArraysEqual = <T>(x: T[] | undefined, y: T[] | undefined): boolean => {
  if (x !== undefined && y !== undefined) {
    if (x.length !== y.length) return false;
    return x.every((val, index) => val === y[index]);
  }
  return false;
};


/**
 * Determines if the intersection of two arrays has a size greater than or equal to the specified intersection size
 * @template T The type of elements in the arrays.
 * @param {T[]} arrayA The first array.
 * @param {T[]} arrayB The second array.
 * @param {number} intersectionSize The minimum intersection size.
 * @returns {boolean} True if the intersection size is greater than or equal to the specified size, false otherwise.
 */
export const hasIntersectionOfSize = <T>(arrayA: T[], arrayB: T[], intersectionSize: number): boolean => {
  const elementCounts = new Map<T, boolean>();
  for (const element of arrayA) {
    elementCounts.set(element, true);
  }

  let intersectionCount = 0;
  for (const element of arrayB) {
    if (elementCounts.has(element)) {
      intersectionCount++;
      if (intersectionCount >= intersectionSize) {
        return true;
      }
    }
  }
  return false;
}
