import { cardiovascularButtons, CardiovascularDescriptionType, categoryNames, subcategoryNames } from "../../ResultsComponentsModel";

import {
  CategoryIds,
  PredispositionIds,
  Recommendation,
  SubcategoryIds,
} from "../../result-constants";

export const CardioDescription: CardiovascularDescriptionType = {
  id: CategoryIds.CARDIOVASCULAR,
  name: categoryNames[CategoryIds.CARDIOVASCULAR],
  mainSubcategory: {
    id: SubcategoryIds.CARDIOVASCULAR_GENERAL,
    name: subcategoryNames[SubcategoryIds.CARDIOVASCULAR_GENERAL],
    tooltip: 'Considera predisposición a desarrollar complicaciones al corazón.',
    replacePRSText: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar complicaciones al corazón.',
    bodyTitle: 'Factores que influyen en mi salud cardiovascular',
    cardContent: [
      {
        id: cardiovascularButtons.CORONARY_DISEASE,
        name: 'Enfermedad coronaria',
        description: 'Genes como el APOA5 regulan la producción y metabolismo de los triglicéridos, impactando en la salud de las arterias coronarias (arterias del corazón)',
      },
      {
        id: cardiovascularButtons.INFARCTION,
        name: 'Infartos',
        description: 'Genes como el TGFB1 participan en el riesgo de incidencia de infartos isquémicos y eventos coronarios, afectando directamente en la salud cardiovascular.'
      },
      {
        id: cardiovascularButtons.FAT_METABOLISM,
        name: 'Metabolismo de grasas',
        description: 'Genes como ADRB3 y PPARA alteran la quema y almacenamiento de energía'
      },
      {
        id: cardiovascularButtons.BLOOD_PRESSURE,
        name: 'Presión y rigidez arterial',
        description: 'El gen TNF-α participa en la regulación de la presión arterial, pudiendo alterar la presión sistólica y diastólica, así como en la salud de las arterias en general'
      },
      {
        id: cardiovascularButtons.HOMOCYSTEINE,
        name: 'Homocisteína',
        description: 'Genes como AGT o GABBR2 alteran el metabolismo de la homocisteína, molécula estrechamente relacionada con la salud de los vasos sanguíneos, estrés oxidativo, inflamación y riesgo de coágulos, aumentando así el riesgo cardiovascular'
      }
    ],
    // TODO recomendacion nutricional
    footer: {
      examples: ['APOA5', 'TGFB1', 'TNF-α', 'AGT', 'GABBR2']
    }
  },
  subcategories: [
    {
      id: SubcategoryIds.CARDIOVASCULAR_CHOLESTEROL,
      name: subcategoryNames[SubcategoryIds.CARDIOVASCULAR_CHOLESTEROL],
      tooltip: 'Regulación de la digestión, transporte, metabolización y acumulación de colesterol LDL (conocido como "malo") y HDL (conocido como "bueno"). El colesterol es uno de varios factores utilizados para estimar el riesgo cardiovascular.',
      replacePRSText: {
        [PredispositionIds.LOW]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles alterados de colesterol, con una tendencia a regular normalmente los niveles de colesterol LDL("malo") y / o HDL("bueno").'],
        [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles altos de colesterol. El colesterol elevado puede obstruir las arterias, dificultando el flujo sanguíneo y aumentando el riesgo de problemas cardíacos.'],
        [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles altos de colesterol. El colesterol elevado puede obstruir las arterias, dificultando el flujo sanguíneo y aumentando el riesgo de problemas cardíacos.'],
      },
      recommendation: {
        [PredispositionIds.LOW]: 'Tus niveles de colesterol no tienden a variar según tu alimentación, permitiéndote gozar de más alimentos 🥩🧈 sin preocuparte en exceso por las grasas. Sin embargo, busca que sean naturales y sin preservantes, evitando la comida rápida 🍟, ultraprocesados 🍪 y embutidos 🌭.',
        [PredispositionIds.MEDIUM]: {
          [Recommendation.PREFER]: [
            'aceite_de_oliva_o_canola',
            'alimentos_ricos_en_omega-3',
            'carbohidratos_complejos',
            'frutas_y_verduras',
          ],
          [Recommendation.REDUCE]: [
            'carnes_rojas_altas_en_grasa',
            'embutidos',
            'tabaco',
          ],
          [Recommendation.AVOID]: null,
        },
        [PredispositionIds.HIGH]: {
          [Recommendation.PREFER]: [
            'aceite_de_oliva_o_canola',
            'alimentos_ricos_en_omega-3',
            'carbohidratos_complejos',
            'frutas_y_verduras',
            'lacteos_con_fitoesteroles_con_o_sin_lactosa',
          ],
          [Recommendation.REDUCE]: null,
          [Recommendation.AVOID]: [
            'carnes_rojas_altas_en_grasa',
            'embutidos',
            'tabaco',
          ],
        },
      },
      examples: ['LPL', 'MC4R', 'PCSK9', 'APOA2', 'LDLR', 'TM6SF2', 'FADS1']
    },
    {
      id: SubcategoryIds.CARDIOVASCULAR_TRIGLYCERIDES,
      name: subcategoryNames[SubcategoryIds.CARDIOVASCULAR_TRIGLYCERIDES],
      tooltip: 'Regulación de la digestión, transporte, metabolismo y acumulación de triglicéridos. Los niveles de triglicéridos son uno de varios factores utilizados para estimar el riesgo cardiovascular.',
      replacePRSText: {
        [PredispositionIds.LOW]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles alterados de trigliceridos, con una tendencia a regular normalmente esta grasa en sangre.'],
        [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética para riesgo de tener niveles altos de triglicéridos. Los triglicéridos altos pueden endurecer las arterias, aumentando el riesgo de problemas del corazón.'],
        [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles altos de triglicéridos. Los triglicéridos altos pueden endurecer las arterias, aumentando el riesgo de problemas del corazón.'],
      },
      recommendation: {
        [PredispositionIds.LOW]: 'Puedes disfrutar de una mayor variedad de alimentos, pero nunca olvides apuntar hacia una alimentación natural 🥕🥩.',
        [PredispositionIds.MEDIUM]: {
          [Recommendation.PREFER]: [
            'alimentos_ricos_en_omega-3',
            'carbohidratos_complejos',
            'grasas_insaturadas',
            'verduras',
          ],
          [Recommendation.REDUCE]: [
            'alcohol',
            'azucar_y_productos_dulces',
            'ultraprocesados_altos_en_azucar',
          ],
          [Recommendation.AVOID]: null,
        },
        [PredispositionIds.HIGH]: {
          [Recommendation.PREFER]: [
            'alimentos_ricos_en_omega-3',
            'carbohidratos_complejos',
            'grasas_insaturadas',
            'verduras',
          ],
          [Recommendation.REDUCE]: null,
          [Recommendation.AVOID]: [
            'alcohol',
            'azucar_y_productos_dulces',
            'ultraprocesados_altos_en_azucar',
          ],
        },
      },
      examples: ['LPL', 'MC4R', 'PCSK9', 'APOA2', 'LDLR', 'TM6SF2', 'FADS1']
    },
    {
      id: SubcategoryIds.CARDIOVASCULAR_BLOODPRESSURE,
      name: subcategoryNames[SubcategoryIds.CARDIOVASCULAR_BLOODPRESSURE],
      tooltip: 'Relación de los sistemas encargados del equilibrio de la presión arterial y el volumen de sangre total. También considera genes asociados al consumo de sal, que en exceso, puede aumentar tu presión arterial.',
      replacePRSText: {
        [PredispositionIds.LOW]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener niveles de presión arterial elevados, con tendencia a regularla de forma normal.'],
        [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener mayores niveles de presión arterial. La presión arterial elevada aumenta el esfuerzo del corazón, dañando los vasos sanguíneos, aumentando el riesgo de enfermedades cardiacas y renales.'],
        [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener mayores niveles de presión arterial. La presión arterial elevada aumenta el esfuerzo del corazón, dañando los vasos sanguíneos, aumentando el riesgo de enfermedades cardiacas y renales.'],
      },
      recommendation: {
        [PredispositionIds.LOW]: 'Si quieres no estar tan preocupado de la 🧂 sal que agregas a tus comidas, incluye 🍏 frutas y 🥦 verduras a diario.',
        [PredispositionIds.MEDIUM]: {
          [Recommendation.PREFER]: [
            'aceite_de_oliva_o_canola',
            'alimentos_altos_en_antioxidantes',
            'alimentos_no_procesados',
            'alimentos_ricos_en_omega-3',
          ],
          [Recommendation.REDUCE]: [
            'azucar_y_productos_dulces',
            'concentrados_altos_en_sodio',
            'embutidos',
            'ultraprocesados_altos_en_sodio',
          ],
          [Recommendation.AVOID]: null,
        },
        [PredispositionIds.HIGH]: {
          [Recommendation.PREFER]: [
            'aceite_de_oliva_o_canola',
            'alimentos_altos_en_antioxidantes',
            'alimentos_no_procesados',
            'alimentos_ricos_en_omega-3',
          ],
          [Recommendation.REDUCE]: null,
          [Recommendation.AVOID]: [
            'azucar_y_productos_dulces',
            'concentrados_altos_en_sodio',
            'embutidos',
            'ultraprocesados_altos_en_sodio',
          ],
        },
      },
      examples: ['NPAS2', 'SLC15A2', 'FRYL', 'CREB3L2', 'ZFHX4', 'BICC1', 'CCDC6', 'UBE4A', 'COL4A1', 'ERG', 'TAS2R38', 'AGT', 'ACE']
    },
  ]
}