import { getDiet, getFoodTags, getNutritionalRecommendation } from "@/services/diet";
import { getQuestionnaire, getQuestionnaireOptions } from "@/services/questionnaire/questionnaire-service";
import { getResults } from "@/services/results/results";
import { getCurrentUser } from "@/services/user/user";
import { days } from "@/utils/constants";
import { AppState } from "./interfaces";

export const loadUser = async (store: AppState) => {
  const {
    getUserData,
    setUserData,
    setUserToken,
    setIsLoadingUserData
  } = store;

  let loaded = false;

  const userInfo = getUserData();
  const t = localStorage.getItem('userToken');

  if (!userInfo && t) {
    setUserToken(t);

    try {
      setIsLoadingUserData(true);
      const data = await getCurrentUser(t);
      setUserData(data);

      loaded = true;

    } catch (e) {
      console.error('error loading user info');
      loaded = false;

    } finally {
      setIsLoadingUserData(false);
    }
  }
  return loaded;
}

export const loadUserInfo = async (store: AppState) => {
  const userInfo = store.getUserData();

  if (userInfo) {
    try {
      await Promise.all([
        loadResults(store),
        loadQuestionnaire(store),
        loadQuestionnaireOptions(store),
        loadFoodTags(store)
      ])

      // must have loaded results!
      await loadDiet(store);
      await loadDietSummary(store);

    } catch (err) {
      console.log('could not load user info')
    }
  }
}

export const loadDiet = async (store: AppState) => {
  const {
    setIsLoadingDiet,
    setDietInformation,
    setDietStatus,
    setDietCreationFailed,
    hasResults,
    hasQuestionnaireData,
    getToken,
    getUserData,
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo && hasResults() && hasQuestionnaireData()) {
    setIsLoadingDiet(true);
    try {
      const data = await getDiet(days);
      const { meals, status } = data;
      setDietInformation(meals);
      setDietStatus(status);
      setIsLoadingDiet(status === "LOADING");
      setDietCreationFailed(status === "FAILED");
    } catch (e) {
      console.error('could not load diet');
    }
  }
}

export const loadFoodTags = async (store: AppState) => {
  const {
    setFoodTags,
    getToken,
    getUserData
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo) {
    try {
      const tags = await getFoodTags();
      setFoodTags(tags);
    } catch (error) {
      console.log('could not load food tags')
    }
  }
}

export const loadDietSummary = async (store: AppState) => {
  const {
    hasDiet,
    setIsLoadingDietSummary,
    setDietSummary,
    getToken,
    getUserData,
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo && hasDiet()) {
    setIsLoadingDietSummary(true);

    try {
      const data = await getNutritionalRecommendation(t);
      setDietSummary(data);
      setIsLoadingDietSummary(false);

    } catch (error) {
      throw new Error("Could not fetch diet summary");
    }
  }
}

export const loadResults = async (store: AppState) => {
  const {
    setIsLoadingResults,
    setResultsData,
    getToken,
    getUserData,
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo) {
    setIsLoadingResults(true);

    try {
      const data = await getResults(userInfo.id, t);
      setResultsData(data);
    } catch (e) {
      console.log('could not load results');
    } finally {
      setIsLoadingResults(false);
    }
  }
}

export const loadQuestionnaire = async (store: AppState) => {
  const {
    setIsLoadingQuestionnaire,
    setQuestionnaireData,
    getToken,
    getUserData,
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo) {
    setIsLoadingQuestionnaire(true);

    try {
      const data = await getQuestionnaire(t);
      setQuestionnaireData(data);

    } catch (e) {
      console.log('could not load questionnaire');
    }
    setIsLoadingQuestionnaire(false);
  }
}


export const loadQuestionnaireOptions = async (store: AppState) => {
  const {
    setIsLoadingQuestionnaireOptions,
    setQuestionnaireOptions,
    getToken,
    getUserData,
  } = store;

  const t = getToken();
  const userInfo = getUserData();

  if (t && userInfo) {
    setIsLoadingQuestionnaireOptions(true);

    try {
      const data = await getQuestionnaireOptions(t);
      setQuestionnaireOptions(data);

    } catch (e) {
      console.log('could not load questionnaire options');
    } finally {
      setIsLoadingQuestionnaireOptions(false);
    }
  }
}