import welcomeTutorialImg1 from '@/assets/welcome-tutorial/images/1.webp';
import welcomeTutorialImg2 from '@/assets/welcome-tutorial/images/2.webp';
import welcomeTutorialImg3 from '@/assets/welcome-tutorial/images/3.webp';
import welcomeTutorialImg4 from '@/assets/welcome-tutorial/images/4.webp';
import welcomeTutorialImg5 from '@/assets/welcome-tutorial/images/5.webp';
import welcomeTutorialImg6 from '@/assets/welcome-tutorial/images/6.webp';
import welcomeTutorialImg7 from '@/assets/welcome-tutorial/images/7.webp';
import welcomeTutorialImg8 from '@/assets/welcome-tutorial/images/8.webp';

import Tutorial from "./Tutorial";

const tutorialSlidesInfo = [{
  title: <div className="title"><div className="title1">¡Bienvenid@ a</div><div className="title2">tu app Mynu!</div></div>,
  description: <div><span>Revisa tus resultados genéticos, personaliza tu alimentación, chatea con Numy y conviértete en la </span><span className="highlighted-description"> mejor versión de ti mismo ;)</span></div>,
  buttonText: 'Empezar'
},
{
  title: <div className="title">Cuestionario</div>,
  description: <div>Cuéntanos sobre ti para crear tu plan de alimentación personalizado. Avanza presionando "siguiente" hasta enviar y luego regresa al inicio.</div>,
  imageSrc: welcomeTutorialImg1,
  buttonText: 'Siguiente'
},
{
  title: <div className="title">Mis resultados</div>,
  description: <div>Si te queda alguna duda después de revisarlos, no dudes en consultarle a Numy. Ella conoce todo acerca de tus resultados y puede ayudarte a integrar la información.</div>,
  imageSrc: welcomeTutorialImg2,
  buttonText: 'Siguiente'
},
{
  title: <div className="title">My Menú</div>,
  description: <div>Menú semanal personalizado según tu genética y objetivos, cubriendo el 100% de tus necesidades. Haz clic en cada día para verlo o cambia la dieta completa aquí.</div>,
  imageSrc: welcomeTutorialImg3,
  buttonText: 'Siguiente',
}, {
  title: <div className="title">My Pauta</div>,
  description: <div>Esta sección te ayudará a crear tu propio menú, según lo que quieras comer o tengas disponible.</div>,
  imageSrc: welcomeTutorialImg4,
  buttonText: 'Siguiente',
  SecondButtonText: 'Ver My Pauta'
}, {
  title: <div className="title">Guía Nutricional</div>,
  description: <div>Recomendaciones personalizadas según tu genética, salud y hábitos. Úsalas para elegir mejor tus alimentos.</div>,
  imageSrc: welcomeTutorialImg5,
  buttonText: 'Siguiente'
}, {
  title: <div className="title">Tamaño de tus porciones</div>,
  description: <div>Verifica el gramaje y tamaño de las porciones en My Menú y My Pauta para entender mejor las cantidades recomendadas.</div>,
  imageSrc: welcomeTutorialImg6,
  buttonText: 'Siguiente',
},
{
  title: <div className="title">Numy</div>,
  description: <div>Numy estará disponible 24/7 para responder tus preguntas y recomendarte lo mejor según tu perfil genético, estilo de vida y objetivos.</div>,
  imageSrc: welcomeTutorialImg7,
  buttonText: 'Siguiente'
},
{
  title: <div className="title">Agenda teleconsultas</div>,
  description: <div>Haz clic para agendar tu teleconsulta mensual con una nutricionista. Aclara dudas sobre tus resultados genéticos, el uso de la app Mynu y solicita exámenes bioquímicos.</div>,
  imageSrc: welcomeTutorialImg8,
  buttonText: '¡Descubre la app!',
}];

interface WelcomeTutorialProps {
  show: boolean,
  handleClose: (openFesTutorial: boolean) => void
}

export default function WelcomeTutorial({ show, handleClose }: WelcomeTutorialProps) {
  return Tutorial({ show, handleClose, tutorialSlidesInfo, startIndex: 0 });
}