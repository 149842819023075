import { useNavigateToTop } from "@/hooks/useNavigateTop";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";

export interface ModalToQuestionnaireProps {
  show: boolean;
}

export default function ModalToQuestionnaire(props: ModalToQuestionnaireProps) {
  const { show } = props;
  const navigate = useNavigateToTop();
  return (
    <Modal show={show}>
      <Modal.Body>
        <h3>Debes completar tu cuestionario para ver esta seccion</h3>
      </Modal.Body>

      <Modal.Footer className="d-flex flex-column justify-content-center border-0">
        <Button variant="primary" onClick={() => navigate('/questionnaire')}>
          Ir al cuestionario
        </Button>
      </Modal.Footer>
    </Modal>
  )
}