import { axiosInstance } from "../../api/http";
import { GeneticResults } from "../../models";


export async function getResults(userId: string, userToken: string): Promise<GeneticResults | null> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }

    const response = await axiosInstance.get(`/results`, { headers });
    const results: GeneticResults = response.data;
    
    return results;

  } catch (e) {
    return null;
  }
}