import { Plus } from 'react-bootstrap-icons';

import './ButtonBadge.scss'
import React, { ReactElement } from 'react';

interface ButtonBadgeComponentProps {
  m?: number,
  size: number,
  round?: boolean,
  iconBadge?: JSX.Element,
  content?: ReactElement,
  onClick?: () => void,
  active?: boolean,
  title?: string,
  noInfo?: boolean
}

export default function ButtonBadgeComponent(props: ButtonBadgeComponentProps) {
  const {
    active, content, iconBadge,
    onClick, round, size, title,
    noInfo, m
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div
      className={`button-badge-container ${active ? 'active' : ''}`}
      onClick={handleClick}
      style={
        {
          '--button-container-width': size * 1.5 + 'em',
          '--after-content': title ? `"${title}"` : null,
          borderRadius: round ? '50%' : undefined,
          margin: m !== undefined ? m : (title ? '0 0 4rem' : '0'),
        } as React.CSSProperties
      }
    >
      {!noInfo ?
        <div className="top-badge">
          {iconBadge ? iconBadge : <Plus />}
        </div>
        : null}

      <div className='button-content'
        style={{
          "--svg-size": size ? `${size}em` : '30px',
        } as React.CSSProperties}
      >
        {content ? content : null}
      </div>

    </div>
  )
}