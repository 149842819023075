import { useEffect, useMemo, useState } from "react";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadUserInfo } from "@/store/loader";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import FoodTagsRecommendator from "@/components/FoodTagsRecommendator/FoodTagsRecommendator";
import MainLayout from "@/components/MainLayout/MainLayout";
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";
import ButtonWrapDescription from "@/components/ButtonWrapDescription/ButtonWrapDescription";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";
import { LoadingPage } from "@/router/LoadingPage";
import CategoryHeader from "../CategoryHeaderComponent";

import { CardioDescription } from "./CardiovascularDescription";
import { Subcategory } from "@/models";
import { cardiovascularButtons, CardiovascularSubcategory, categoryNames, SVGComponent } from "../../ResultsComponentsModel";

import '../../GeneticResults.scss';
import bgSrc from '@/assets/results/cardio/images/cardio.webp';

import { ReactComponent as Coronary } from '@/assets/results/cardio/icons/coronary.svg';
import { ReactComponent as Infarction } from '@/assets/results/cardio/icons/infarction.svg';
import { ReactComponent as Fat } from '@/assets/results/cardio/icons/fat_metabolism.svg';
import { ReactComponent as Bp } from '@/assets/results/cardio/icons/bp.svg';
import { ReactComponent as Homocysteine } from '@/assets/results/cardio/icons/homocysteine.svg';

import { CategoryIds, Recommendation } from "../../result-constants";
import { buildFoodTagArgumentsUrl, getCategoryFromResults, parseRecommendation, replacePRSOR } from "../../ResultPage";

import './CardioComponent.scss';


const iconsArray: Record<cardiovascularButtons, SVGComponent> = {
  [cardiovascularButtons.CORONARY_DISEASE]: Coronary,
  [cardiovascularButtons.INFARCTION]: Infarction,
  [cardiovascularButtons.FAT_METABOLISM]: Fat,
  [cardiovascularButtons.BLOOD_PRESSURE]: Bp,
  [cardiovascularButtons.HOMOCYSTEINE]: Homocysteine,
}

export default function CardioComponent() {

  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults, resultsData } = store;

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.CARDIOVASCULAR), [resultsData]);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mainCardOpen, setMainCardOpen] = useState(false);

  return (
    <MainLayout>
      <div className="category-container cardio-component-container"
        style={{
          '--bg-image-src': `url("${bgSrc}")`,
          position: 'relative'
        } as React.CSSProperties}
      >
        <CategoryHeader title={categoryNames[CategoryIds.CARDIOVASCULAR]} />

        {hasResults() && resultsState ?
          <Container className="px-0 subcategories-cards-container grid-cards-container">
            <>
              {resultsState.cardiovascular_general.prs_to_1 !== null
                && resultsState.cardiovascular_general.percentile !== null
                && resultsState.cardiovascular_general.predisposition !== null ?
                <Card className="sbcat-card">
                  <Card.Header className="d-flex flex-column justify-content-center">
                    <div className="d-flex align-items-center gap-2">
                      <div className="sbcat-title">
                        {CardioDescription.mainSubcategory.name}
                      </div>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={CardioDescription.mainSubcategory.id}>
                            {CardioDescription.mainSubcategory.tooltip}
                          </Tooltip>
                        }
                      >
                        <div className="sbcat-tooltip">
                          <div> ? </div>
                        </div>
                      </OverlayTrigger>
                    </div>

                    <div className="mx-auto percentile-bar-container">
                      <div className="text-center">
                        <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                        <CustomSliderComponent
                          percent={resultsState.cardiovascular_general.percentile}
                          intervals={['Menor', 'Promedio', 'Mayor']}
                          intervalsDescriptions={[
                            'Tienes menos riesgo que la población general',
                            'Tu riesgo es similar al de la población general',
                            'Tienes más riesgo que la población general'
                          ]}
                        />
                      </div>
                    </div>
                  </Card.Header>

                  <CollapseIcon
                    open={mainCardOpen}
                    setOpen={setMainCardOpen}
                    iconSize={2}
                  >
                    <div>
                      <Card.Body>
                        <div>
                          <div className="info-container text-center"
                            style={{
                              margin: '3rem auto 2rem',
                              "--info-container-header": `"¿Cómo influye la genética?"`
                            } as React.CSSProperties}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replacePRSOR(
                                  CardioDescription.mainSubcategory.replacePRSText,
                                  resultsState.cardiovascular_general.prs_to_1,
                                  resultsState.cardiovascular_general.prs_to_1,
                                )
                              }}
                            />
                          </div>
                        </div>

                        <ButtonWrapDescription
                          componentTitle={
                            <span>
                              <span className="mynu-stars">Factores</span>
                              <br className="d-none d-md-block" />
                              <span className="d-block d-md-none">{' '}</span>
                              <span>
                                que influyen en mi salud cardiovascular
                              </span>
                            </span>
                          }
                          buttonSize={1.7}
                          buttons={CardioDescription.mainSubcategory.cardContent}
                          iconsArray={iconsArray}
                          noInfoInButtons
                        />

                      </Card.Body>

                      <Card.Footer>
                        <ResponsiveCard
                          description={""}
                          subtitle="Genes"
                          examples={resultsState.cardiovascular_general.gene_breakdown?.map(g => g.gene_name) ?? CardioDescription.mainSubcategory.footer.examples}
                        />
                      </Card.Footer>
                    </div>
                  </CollapseIcon>
                </Card>
                : null
              }
            </>

            {CardioDescription.subcategories.map((sbcat, i) => {
              const { id } = sbcat;
              const sbcatInfo = resultsState[id];
              return (
                sbcatInfo.prs_to_1 !== null ?
                  <SubcategoryComponent
                    key={`cardiovascular-sbcat-${id}-${i}`}
                    sb={sbcat}
                    info={sbcatInfo}
                  />
                  : null)
            })}
          </Container>
          : <LoadingPage />
        }
      </div>
    </MainLayout >
  )
}

interface SubcategoryComponentProps {
  sb: CardiovascularSubcategory;
  info: Subcategory;
}

export const SubcategoryComponent = ({ sb, info }: SubcategoryComponentProps) => {
  const {
    id, name, tooltip, replacePRSText,
    recommendation, examples
  } = sb;

  const [selectedBody, setSelectedBody] = useState<string | null>('effects');
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    info.percentile !== null ?
      <Card className="sbcat-card">
        <Card.Header className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center gap-2">
            <div className="sbcat-title">
              {name}
            </div>

            <OverlayTrigger placement="bottom" overlay={<Tooltip className='sbcat-tooltip' id={id}>{tooltip}</Tooltip>}>
              <div className="sbcat-tooltip">
                <div> ? </div>
              </div>
            </OverlayTrigger>
          </div>

          <div className="px-2 percentile-bar-container">
            <div className="text-center">
              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
              <CustomSliderComponent
                percent={info.percentile}
                intervals={['Menor', 'Promedio', 'Mayor']}
                intervalsDescriptions={[
                  'Tienes menos riesgo que la población general',
                  'Tu riesgo es similar al de la población general',
                  'Tienes más riesgo que la población general'
                ]}
              />
            </div>
          </div>
        </Card.Header>
        <CollapseIcon
          iconSize={2}
          open={collapseOpen}
          setOpen={setCollapseOpen}
        >
          <div>
            <Card.Body>
              <div className="p-2 d-flex flex-column justify-content-center">
                <div className="sbcat-buttons-array mx-auto">
                  <ButtonGroup aria-label="sbcat-buttons-array">
                    <Button className="first"
                      active={selectedBody === 'effects'}
                      onClick={() => setSelectedBody('effects')}
                    >
                      Efectos
                    </Button>
                    <Button className='third'
                      active={selectedBody === 'recommendation'}
                      onClick={() => setSelectedBody('recommendation')}
                    >
                      Recomendación Nutricional
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="display-body">
                  {selectedBody === 'effects' ?
                    <div className="effects">
                      {replacePRSText[info.predisposition]?.map((t, i) =>
                        <div
                          key={'replace-text-prs-' + i}
                          className="text-center"
                          dangerouslySetInnerHTML={{ __html: replacePRSOR(t, info.prs_to_1, info.prs_to_1) }}
                        />
                      )}
                    </div>
                    : selectedBody === 'recommendation' ?
                      <FoodTagsRecommendator
                        prefer={parseRecommendation(recommendation[info.predisposition], Recommendation.PREFER)}
                        reduce={parseRecommendation(recommendation[info.predisposition], Recommendation.AVOID)}
                        avoid={parseRecommendation(recommendation[info.predisposition], Recommendation.REDUCE)}
                        navigateTo={
                          buildFoodTagArgumentsUrl(
                            CategoryIds.CARDIOVASCULAR,
                            id,
                            info.predisposition)
                        }
                        singleColumnRecommendation
                      />
                      :
                      null}
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <ResponsiveCard subtitle={'Genes'} examples={info.gene_breakdown?.map(g => g.gene_name) ?? examples} description={""} />
            </Card.Footer>
          </div>
        </CollapseIcon>
      </Card>
      : null
  )
}