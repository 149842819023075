import { chartType, subcategoryNames, SubcategoryVitaminsType } from "../../ResultsComponentsModel";

import {
  PredispositionIds,
  Recommendation,
  SubcategoryIds,
} from "../../result-constants";

export const VitaminsDescrition: SubcategoryVitaminsType[] = [
  // vitamin A
  {
    id: SubcategoryIds.VITAMINS_MINERALS_A,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_A],
    tooltip: 'La vitamina A es vital para la salud ocular, la inmunidad y la piel. Variaciones genéticas regulan la velocidad de conversión del betacaroteno a vitamina A, afectando los niveles de ambos compuestos.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Niveles bajos de vitamina A pueden afectar la salud de la vista, inmunidad y la piel.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un metabolismo eficiente de vitamina A.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina A.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina A.'],
    },
    examples: ['BCMO1', 'FFAR4', 'TTR', 'GCKR', 'RPL31P23', 'PCCB', 'RBP4'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: null,
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'hortalizas_rojas - naranjas - moradas',
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // vitamin D
  {
    id: SubcategoryIds.VITAMINS_MINERALS_D,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_D],
    tooltip: 'La vitamina D es esencial para mantener huesos fuertes, la función inmune y prevenir enfermedades en general.  Alteraciones genéticas pueden afectar la activación, transporte y almacenamiento de ésta.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Bajos niveles de vitamina D y calcio aumentan el riesgo de osteoporosis, lo que aumenta la fragilidad de los huesos. También puede debilitar el sistema inmune. Incluso puede afectar el estado de ánimo.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a una activación, transporte y almacenamiento normal de vitamina D.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina D, debido a deficiencias en la activación, transporte y/o almacenamiento de esta.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina D, debido a deficiencias en la activación, transporte y/o almacenamiento de esta.'],
    },
    examples: ['GC-globulin', 'GC', 'PSMA1', 'PDE3B', 'ACTE1P'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Aprovecha momentos de exposición al sol y consume alimentos ricos en vitamina D como pescados grasos🐠, huevo🍳, hongos shiitake🍄 y lácteos fortificados🥛.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'alimentos_fortificados_en_vitamina_d',
          'harina_de_trigo__pan', 'huevos',
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'alimentos_fortificados_en_vitamina_d',
          'harina_de_trigo__pan', 'huevos',
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // vitamin b12
  {
    id: SubcategoryIds.VITAMINS_MINERALS_B12,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_B12],
    tooltip: 'La vitamina B12 es crucial para transportar oxígeno a tus tejidos, sentirte con energía y poder concentrarte. Variaciones genéticas pueden alterar la absorción de vitamina B12, disminuyendo sus niveles y afectando todas estas funciones.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Niveles bajos de vitamina B12 puede asociarse a síntomas como fatiga, pérdida de memoria y palidez.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a una absorción eficiente de vitamina B12.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina B12, debido a alteraciones en la absorción de esta.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de vitamina B12, debido a alteraciones en la absorción de esta.'],
    },
    examples: ['CUBN', 'TCN1', 'TCN2', 'FUT2', 'MMACHC', 'CD320', 'CLYBL', 'MMUT', 'PPP4R3B', 'IL2RA', 'HLA-DQB1-AS1', 'PHTF1', 'PTPN22', 'AIRE', 'MMAA'],

    mtfhrActivity: null,
    remember: ['La vitamina B12 se halla principalmente en alimentos de origen animal. Si llevas una dieta vegetariana o vegana, es vital monitorear tus niveles de B12 y consultar a un profesional en caso de dudas sobre deficiencia.'],
    body: {
      [PredispositionIds.LOW]: 'Incluye proteínas animales como carnes🍗, pescados🐟, huevos🍳 y lácteos🥛🧀 de forma diaria en tu dieta. Si prefieres no consumirlos, procura alcanzar tus requerimientos.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'carnes_rojas_bajas_en_grasa',
          'huevos',
          'lacteos_con_o_sin_lactosa',
          'pescados_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'carnes_rojas_bajas_en_grasa',
          'huevos',
          'lacteos_con_o_sin_lactosa',
          'pescados_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // vitamin b6
  {
    id: SubcategoryIds.VITAMINS_MINERALS_B6,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_B6],
    tooltip: ' La vitamina B6 ayudar a formar neurotransmisores y hormonas. La variante genética puede aumentar su degradación, disminuyendo sus niveles en sangre, y afectando en tu salud.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Niveles bajos pueden afectar el estado de ánimo y el sueño.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencias de Vitamina B6 debido a una degradación normal de esta.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias de vitamina B6, debido a una descomposición de esta más rápida de lo normal.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias de vitamina B6, debido a una descomposición de esta más rápida de lo normal.'],
    },
    examples: ['ALPL'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Incluye proteínas magras🍗, pescados🐠, frutos secos🥜, papas🥔 y garbanzos🫘 en tu alimentación para asegurar niveles adecuados de vitamina B6.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'frutas_altas_en_grasa',
          'hortalizas_ricas_en_almidon',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'frutas_altas_en_grasa',
          'hortalizas_ricas_en_almidon',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // vitamin c
  {
    id: SubcategoryIds.VITAMINS_MINERALS_C,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_C],
    tooltip: 'Ayuda en el crecimiento y reparación de tejidos, a prevenir enfermedades crónicas, etc. Variantes genéticas en este gen se relaciona con un transporte inadecuado de la vitamina por el cuerpo, y por ende, no se utiliza de forma eficiente. ',
    percentileOrStoplight: chartType.STOPLIGHT, // chartType.STOPLIGHT
    effects: [''],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un transporte y utilización normal de vitamina C.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener deficiencia de vitamina C, debido a un transporte y utilización deficiente de esta.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética para tener deficiencia de vitamina C, debido a un transporte y utilización deficiente de esta.'],
    },
    examples: ['SLC23A1'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Incluye 5 porciones de frutas🍊🍏 y verduras🫑🥕 al dia para asegurar el consumo de vitamina C.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'frutas_altas_en_grasa',
          'hortalizas_ricas_en_almidon',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'frutas_altas_en_grasa',
          'hortalizas_ricas_en_almidon',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // folic acid
  {
    id: SubcategoryIds.VITAMINS_MINERALS_FOLICACID,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_FOLICACID],
    tooltip: 'Los folatos son clave para la formación y reparación de tejidos, por lo que tienen un rol en el crecimiento, función cerebral y la salud en general. Variantes genéticas pueden influir en el metabolismo de esta vitamina, alterando sus niveles en sangre.',
    percentileOrStoplight: chartType.BAR,
    effects: ['Niveles bajos podría causar cansancio, falta de concentración, irritabilidad y, en algunos casos, anemia.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un metabolismo normal de ácido fólico.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de ácido fólico.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de ácido fólico.'],
    },
    examples: ['MTHFR', 'RNU5E-1', 'SBF1P2', 'FOLR3',],
    remember: null,
    mtfhrActivity: [
      'La enzima MTHFR procesa el ácido fólico y regula los niveles de homocisteína en el cuerpo. Esta enzima es clave para mantener adecuados niveles de folatos, influyendo en el 7 % de los folatos en las células sanguíneas y el 4 % en los niveles de homocisteína.',
      'Una baja actividad de MTHFR puede disminuir los niveles de ácido fólico y aumentar los de homocisteína en sangre,  lo que puede ser dañino para la salud cardiovascular.'
    ],

    body: {
      [PredispositionIds.LOW]: 'Aprovecha fuentes naturales de folatos como verduras de hoja verde🥬, frutos secos🥜 y alimentos fortificados con ácido fólico(como por ejemplo, la harina🌾) para evitar síntomas como cansancio, falta de concentración, irritabilidad e incluso anemia.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'hortalizas_rojas-naranjas-moradas',
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'lacteos_con_o_sin_lactosa',
          'pescados_grasos_y_mariscos'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
]

export const MineralsDescrition: SubcategoryVitaminsType[] = [
  // selenium
  {
    id: SubcategoryIds.VITAMINS_MINERALS_SELENIUM,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_SELENIUM],
    tooltip: 'El selenio es esencial para un adecuado funcionamiento de distintos procesos desde la reproducción hasta como antioxidante. Bajos niveles de selenio se asocian con alteraciones en tu salud del corazón y reproductiva, como por ejemplo, infertilidad.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: [
      'Presentar menores niveles de selenio puede llevar a mayores riesgos para tu salud reproductiva, el corazón e incluso, aumenta la susceptibilidad a infecciones.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un metabolismo normal de selenio.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de selenio.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de selenio.'],
    },
    examples: ['DMGDH', 'BHMT'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Para mantener niveles adecuados de selenio, lo encuentras en mariscos 🦞, carnes 🍗, granos enteros 🫘 y nueces de Brasil 🌰.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'carbohidratos_complejos',
          'carnes_rojas_bajas_en_grasa',
          'huevos',
          'pescados_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,

      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'carbohidratos_complejos',
          'carnes_rojas_bajas_en_grasa',
          'huevos',
          'pescados_y_mariscos',
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // copper
  {
    id: SubcategoryIds.VITAMINS_MINERALS_COPPER,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_COPPER],
    tooltip: 'Niveles adecuados de cobre contribuyen a un adecuado funcionamiento de tu cuerpo. Niveles bajos de cobre pueden causar cansancio, aumentar el riesgo a infecciones, problemas al corazón y problemas neuronales.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Niveles bajos podría causar cansancio, aumentar el riesgo a infecciones, problemas al corazón y problemas neuronales.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un metabolismo normal de cobre.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de cobre.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de cobre.'],
    },
    examples: ['SMIM1', 'SELENBP1'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Mantén una dieta variada. Recuerda que el cobre que necesitas lo puedes encontrar en pescados 🐟🐠, mariscos 🦐, frutos secos 🥜🌰, semillas 🌻 y chocolate amargo 🍫.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'chocolate_alto_en_cacao',
          'frutos_secos',
          'legumbres',
          'mariscos_con_concha',
          'semillas'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,

      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'chocolate_alto_en_cacao',
          'frutos_secos',
          'legumbres',
          'mariscos_con_concha',
          'semillas'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  },
  // zinc
  {
    id: SubcategoryIds.VITAMINS_MINERALS_ZINC,
    name: subcategoryNames[SubcategoryIds.VITAMINS_MINERALS_ZINC],
    tooltip: 'Es clave para combatir infecciones, la cicatrización de heridas, el razonamiento y la memoria. Niveles bajos en sangre incluso pueden afectar tu sentido del gusto y olfato.',
    percentileOrStoplight: chartType.BAR, // chartType.STOPLIGHT
    effects: ['Niveles bajos de zinc puede afectar tu cicatrización de heridas, tu capacidad de razonamiento, memoria y debilitar tu sistema inmune.'],
    prsText: {
      [PredispositionIds.LOW]: ['Sin predisposición genética a tener deficiencia debido a un metabolismo normal de zinc.'],
      [PredispositionIds.MEDIUM]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de zinc.'],
      [PredispositionIds.HIGH]: ['%[PRS_VALUE]% una persona sin predisposición genética de presentar deficiencias en tus niveles de zinc.'],
    },
    examples: ['NBDY', 'PPCDC', 'CA1'],
    remember: null,
    mtfhrActivity: null,

    body: {
      [PredispositionIds.LOW]: 'Para tener niveles normales, debes obtenerlo a través de la dieta. Búscalo en proteínas magras 🍗🐟🦞, frutos secos 🥜🌰🫘 y semillas 🌻.',
      [PredispositionIds.MEDIUM]: {
        [Recommendation.PREFER]: [
          'carnes_blanca_magras_pescados_y_mariscos',
          'frutos_secos',
          'huevos',
          'legumbres',
          'semillas'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,

      },
      [PredispositionIds.HIGH]: {
        [Recommendation.PREFER]: [
          'carnes_blanca_magras_pescados_y_mariscos',
          'frutos_secos',
          'huevos',
          'legumbres',
          'semillas'
        ],
        [Recommendation.REDUCE]: null,
        [Recommendation.AVOID]: null,
      }
    }
  }
]
