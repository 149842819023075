import { useState } from "react";

import Card from "react-bootstrap/esm/Card";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";

import { Subcategory } from "@/models";
import { SubcategoryIds } from "@/pages/results/result-constants";
import { ButtonForGroup, ButtonsArrayComponent } from "./ButtonsArrayComponent";

interface SubcatCardProps {
  id: SubcategoryIds;
  name: string;
  tooltip: string[];
  sbcatInfo: Subcategory;
  buttonsArray: ButtonForGroup[];
  examples: string[];
}

export default function SubcatCard(props: SubcatCardProps) {
  const { id, name, tooltip, sbcatInfo, buttonsArray, examples } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <Card className="sbcat-card">
      <Card.Header className="d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center gap-2">
          <div>{name}</div>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={id} >
                {tooltip.length > 0 ?
                  tooltip.map((t, i) => <p key={`tooltip-wellness-${i}`}>{t}</p>)
                  : null}
              </Tooltip>
            }
          >
            <div className="sbcat-tooltip">
              <div> ? </div>
            </div>
          </OverlayTrigger>
        </div>

        {sbcatInfo.percentile !== null ?
          <div className="percentile-bar-container">
            <div className="text-center">
              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
              <CustomSliderComponent
                size={"2rem"}
                percent={sbcatInfo.percentile}
                intervals={['Menor', 'Promedio', 'Mayor']}
                intervalsDescriptions={[
                  'Tienes menos riesgo que la población general',
                  'Tu riesgo es similar al de la población general',
                  'Tienes más riesgo que la población general'
                ]}
              />
            </div>
          </div>
          : null
        }
      </Card.Header>

      <CollapseIcon open={collapseOpen} setOpen={setCollapseOpen}>
        <div>
          <Card.Body>
            <div className="">
              <ButtonsArrayComponent
                buttons={buttonsArray}
                predisposition={sbcatInfo.predisposition}
                prs={sbcatInfo.prs_to_1}
                subcategoryId={id}
              />
            </div>
          </Card.Body>

          <Card.Footer>
            <div className="d-flex justify-content-center">
              <ResponsiveCard description="" subtitle="Genes" examples={sbcatInfo.gene_breakdown?.map(g => g.gene_name) ?? examples} />
            </div>
          </Card.Footer>
        </div>
      </CollapseIcon>
    </Card>
  )
}