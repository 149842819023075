import fesTutorialImg1 from '@/assets/fes/images/tutorial/1.webp'
import fesTutorialImg2 from '@/assets/fes/images/tutorial/2.webp'
import fesTutorialImg3 from '@/assets/fes/images/tutorial/3.webp'
import fesTutorialImg3_1 from '@/assets/fes/images/tutorial/3-1.webp'
import fesTutorialImg4 from '@/assets/fes/images/tutorial/4.webp'
import fesTutorialImg5 from '@/assets/fes/images/tutorial/5.webp'
import fesTutorialImg6 from '@/assets/fes/images/tutorial/6.webp'

import Tutorial from "./Tutorial";

const tutorialSlidesInfo = [{
  title: <div className="title"><div className="title1">¡Bienvenida/o a</div><div className="title2">My Pauta!</div></div>,
  description: <div><span>Esta sección te ayudará a crear tu propio menú, según lo que </span><span className="highlighted-description"> quieras comer o tengas disponible.</span></div>,
  buttonText: 'Empezar'
},
{
  title: <div className="title">¡Organiza tus tiempos!</div>,
  description: <div>En el día en el que nos encontramos, habrá una propuesta de grupos de alimentos necesarios para cumplir con tus requerimientos. </div>,
  imageSrc: fesTutorialImg1,
  buttonText: '¡Vamos!'
},
{
  title: <div className="title">Añade tus alimentos</div>,
  description: <div>Busca tus opciones preferidas para consumir en cada tiempo de comida.</div>,
  imageSrc: fesTutorialImg2,
  buttonText: 'Siguiente'
},
{
  title: <div className="title">Alimentación 100% personalizada</div>,
  description: <div>En cada alimento se indicará la <span className="highlighted-description">calidad</span> de este según tus recomendaciones genéticas.</div>,
  imageSrc: fesTutorialImg3,
  extraImg: fesTutorialImg3_1,
  buttonText: 'Continúa ;)'
},
{
  title: <div className="title">Agrega + alimentos</div>,
  description: <div>Puedes agregar nuevos grupos en el botón + de abajo.</div>,
  imageSrc: fesTutorialImg4,
  buttonText: '¡Ya casi!'
},
{
  title: <div className="title">Calcula tus porciones</div>,
  description: <div>Haz click en <span className="highlighted-description">calcular porciones</span> para ver la cantidad que debes consumir, y así cumplir tus objetivos.</div>,
  imageSrc: fesTutorialImg5,
  buttonText: '¡Un paso más!'
},
{
  title: <div className="title">Tu reporte Diario</div>,
  description: <div>Al final del día, ve tu <span className="highlighted-description">reporte diario</span>!<br />Este cuenta con una calificación en base a tus elecciones alimentarias y sus nutrientes asociados, además de <span className="highlighted-description">recomendaciones</span> para mejorar tu alimentación.</div>,
  imageSrc: fesTutorialImg6,
  buttonText: '¡Tu turno!'
}];

interface FesTutorialProps {
  show: boolean,
  handleReturn?: () => void,
  handleClose: () => void
}

export default function FesTutorial({ show, handleReturn, handleClose }: FesTutorialProps) {
  const startIndex = !!handleReturn ? 1 : 0;
  return Tutorial({ show, handleClose, handleReturn, tutorialSlidesInfo, startIndex });
}