import { BodyButtons, BodyButtonsAccordion, categoryNames,subcategoryNames } from "../../ResultsComponentsModel";
import { ReactComponent as Rest } from '../../../../assets/results/body/icons/rest.svg';
import { ReactComponent as Sleep } from '../../../../assets/results/body/icons/sleep.svg';
import { ReactComponent as Excercise } from '../../../../assets/results/body/icons/excercise.svg';
import { CategoryIds, PredispositionIds, Recommendation, SubcategoryIds } from "../../result-constants";
import { Apple, Milk } from "lucide-react";

export const BodyDescription = {
  id: CategoryIds.BODY,
  name: categoryNames[CategoryIds.BODY],
  topCard: {
    id: SubcategoryIds.BODY_WEIGHT,
    name: subcategoryNames[SubcategoryIds.BODY_WEIGHT],
    description: 'Esta categoría incluye genes asociados al **peso** y factores que influyen en su regulación, tales como **señales hormonales**, **preferencias y conductas alimentarias**, etc. Controlar el peso implica múltiples **factores**, y tu **dieta** es un factor determinante.',
    replacePRStext: '%[PRS_VALUE]% una persona sin predisposición genética para subir de peso.',
    info: '¿Sabías que tu peso está influenciado en un 56.6% por la genética y en un 25.2% por el estilo de vida?',
    chart: {
      description: 'Usa los botones para ver cómo cambian tus probabilidades de aumento de peso según tus hábitos y tu genética',
      buttons: [
        {
          id: BodyButtons.ACTIVE,
          name: 'Actividad Física',
          description: 'Practicas deporte regularmente o llevas un estilo de vida activo, realizando unas 5 sesiones semanales de una hora de actividades intensas (como correr, nadar, crossfit) o moderadas (como bailar, caminar rápido, trotar).',
          score: {
            [PredispositionIds.LOW]: -0.356675,
            [PredispositionIds.MEDIUM]: -0.356674944,
            [PredispositionIds.HIGH]: -0.356674944,
          },
          IconElement: Excercise,
        },
        {
          id: BodyButtons.DIET,
          name: 'Alimentación',
          description: 'Llevas una alimentación diaria con frutas, verduras, proteínas magras y granos integrales, y minimizas el consumo de azúcares y alimentos procesados.',
          score: {
            [PredispositionIds.LOW]: -0.274437,
            [PredispositionIds.MEDIUM]: -0.314710745,
            [PredispositionIds.HIGH]: -0.314710745,
          },
          IconElement: Apple,
        },
        {
          id: BodyButtons.SLEEP,
          name: 'Sueño',
          description: 'Duermes entre 6 a 8 horas al día.',
          score: {
            [PredispositionIds.LOW]: -0.342490,
            [PredispositionIds.MEDIUM]: -0.248461359,
            [PredispositionIds.HIGH]: -0.287682072,
          },
          IconElement: Sleep,
        },
        {
          id: BodyButtons.ALCOHOL,
          name: 'Alcohol',
          description: 'Tomas uno o más tragos al día si eres mujer, o dos o más si eres hombre.',
          score: {
            [PredispositionIds.LOW]: 0.094311,
            [PredispositionIds.MEDIUM]: 0.010050336,
            [PredispositionIds.HIGH]: 0.58268908,
          },
          IconElement: Milk,
        },
        {
          id: BodyButtons.SEDENTARY,
          name: 'Actividades Sedentarias',
          description: 'Pasas dos o más horas al día viendo televisión o en el computador, excluyendo las horas de trabajo.',
          score: {
            [PredispositionIds.LOW]: 0.713350,
            [PredispositionIds.MEDIUM]: 0.634878272,
            [PredispositionIds.HIGH]: 0.616186139,
          },
          IconElement: Rest,
        },
      ],
      adjustedPRSText: '%[PRS_VALUE]% una persona sin predisposición genética a subir de peso, ajustado a tu estilo de vida.',
      info: 'Seguir un estilo de vida saludable puede disminuir tu riesgo de diabetes y enfermedades del corazón, ¡incluso con alto riesgo genético!'
    },
    accordionElements: [
      {
        id: 'buttons_array_1',
        button: '¿Cómo influye la genética?',
        body: {
          info: null,
          description: 'Conoce los mecanismos por los cuales la genética puede afectar tu peso:',
          buttons: {
            [BodyButtonsAccordion.ENERGY_USAGE]: {
              id: BodyButtonsAccordion.ENERGY_USAGE,
              title: 'Uso de energía',
              description: 'UCP reduce la eficiencia del gasto energético.',
            },
            [BodyButtonsAccordion.FAT_METABOLISM]: {
              id: BodyButtonsAccordion.FAT_METABOLISM,
              title: 'Metabolismo de grasas',
              description: 'ADRB3 y PPARA influyen en la quema y almacenamiento de energía.',
            },
            [BodyButtonsAccordion.OXIDATIVE_STRESS]: {
              id: BodyButtonsAccordion.OXIDATIVE_STRESS,
              title: 'Estrés oxidativo',
              description: 'ISR-2 aumenta el estrés oxidativo, dificultando bajar de peso.',
            },
            [BodyButtonsAccordion.CIRCADIAN_RYTHM]: {
              id: BodyButtonsAccordion.CIRCADIAN_RYTHM,
              title: 'Ritmo Circadiano',
              description: 'CLOCK desajusta el reloj interno, causando hábitos alimenticios irregulares y un metabolismo menos eficiente.'
            },
            [BodyButtonsAccordion.FOOD_REGULATION]: {
              id: BodyButtonsAccordion.FOOD_REGULATION,
              title: 'Regulación de la ingesta de alimentos',
              description: 'Genes como FTO, DRD2 y MC4R afectan la sensación de hambre y saciedad, pudiendo llevar a comer en exceso.',
            },
            [BodyButtonsAccordion.FAT_ACCUMULATION]: {
              id: BodyButtonsAccordion.FAT_ACCUMULATION,
              title: 'Acumulación de grasa',
              description: 'POMC y ADIPOQ regulan el almacenamiento de grasa y el metabolismo de insulina.',
            },
          },
          recommendation: null
        },
        examples: ['BDNF', 'FTO', 'MC4R', 'ADIPOQ', 'CLOCK', 'PPARA']
      },
      {
        id: 'chart_1',
        button: '¿Mi riesgo cambia con la edad?',
        body: {
          info: 'Al nacer, todos tienen pesos similares, pero desde la infancia, aquellos con mayor riesgo genético comienzan a ganar más peso. En la adolescencia, las diferencias se acentúan: quienes tienen alto riesgo pueden pesar hasta 12 kilos más que sus pares con menor riesgo. En la adultez, esta diferencia puede llegar a 13 kilos.',
          description: 'Descubre cómo tu riesgo varía desde la niñez hasta la adultez y toma decisiones más informadas sobre tu salud. Si tu riesgo genético de ganar peso es %[PREDISPOSITION_VALUE]%, sigue la línea para ver cómo podría cambiar tu peso con los años (adaptado de Khera et al, 2019).',
          buttons: null,
          recommendation: null
        },
        examples: null
      },
      {
        id: 'recommendation',
        button: 'Recomendación Nutricional',
        body: {
          info: null,
          description: null,
          buttons: null,
          recommendation: {
            [PredispositionIds.LOW]: 'Puedes ser más flexible con tu alimentación sin generar fluctuaciones de peso, pero puede ser una arma de doble filo si quieres aumentar de peso. Esto no quiere decir que debes descuidar tu alimentación. Prioriza los alimentos naturales 🥔🍅 y evita el exceso de azúcares 🍭🍰🍪 y ultraprocesados 🍟🥤🍫 para cuidar tu salud.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: [
                'alimentos_no_procesados',
                'alimentos_ricos_en_omega-3',
                'frutas_y_verduras',
                'frutos_secos',
                'grasas_insaturadas',
                'legumbres',
              ],
              [Recommendation.REDUCE]: [
                'azucar_y_productos_dulces',
                'carnes_rojas_altas_en_grasa',
                'cereales_refinados',
                'embutidos',
                'ultraprocesados_altos_en_calorias',
              ],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: [
                'alimentos_no_procesados',
                'alimentos_ricos_en_omega-3',
                'frutas_y_verduras',
                'frutos_secos',
                'grasas_insaturadas',
                'legumbres',
              ],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]: [
                'azucar_y_productos_dulces',
                'carnes_rojas_altas_en_grasa',
                'cereales_refinados',
                'embutidos',
                'ultraprocesados_altos_en_calorias',
              ],
            }
          }
        },
        examples: null
      }
    ]
  },
  bottomCard: {
    id: SubcategoryIds.BODY_LEAN,
    name: subcategoryNames[SubcategoryIds.BODY_LEAN],
    tooltip: [
      'Se refiere al peso de músculo, huesos y órganos, excluyendo la grasa corporal.',
      'Se utiliza como indicador de cantidad de músculo en tu cuerpo.'
    ],
    topCard: {
      header: '%[PREDISPOSITION_VALUE]%',
      body: {
        [PredispositionIds.LOW]: 'Predisposición alta a tener más músculo, tienes una ventaja genética para aumentar masa muscular más fácilmente ante estímulos de ejercicio y dieta.',
        [PredispositionIds.MEDIUM]: 'Predisposición a una cantidad de músculo normal. No tienes una ventaja genética para aumentar masa muscular más fácilmente ante estímulos de ejercicio y dieta.',
        [PredispositionIds.HIGH]: 'Predisposición a una cantidad de músculo normal. No tienes una ventaja genética para aumentar masa muscular más fácilmente ante estímulos de ejercicio y dieta.'
      }
    },
    accordionElements: [
      {
        id: 'recommendation',
        button: 'Recomendación Nutricional',
        body: {
          [PredispositionIds.LOW]: 'Busca una adecuada relación de proteínas animales 🍗🐟 y/o vegetales 🫘🫛 en tu dieta para mantener niveles adecuados de masa muscular 💪.',
          [PredispositionIds.MEDIUM]: {
            [Recommendation.PREFER]: [
              'alimentos_ricos_en_omega-3',
              'alimentos_ricos_en_vitamina_d',
              'carnes_blanca_magras_pescados_y_mariscos',
              'lacteos_con_o_sin_lactosa',
              'legumbres',
            ],
            [Recommendation.REDUCE]: [
              'alcohol',
              'carnes_rojas_altas_en_grasa',
              'embutidos',
            ],
            [Recommendation.AVOID]: null,
          },
          [PredispositionIds.HIGH]: {
            [Recommendation.PREFER]: [
              'alimentos_ricos_en_omega-3',
              'alimentos_ricos_en_vitamina_d',
              'carnes_blanca_magras_pescados_y_mariscos',
              'lacteos_con_o_sin_lactosa',
              'legumbres',
            ],
            [Recommendation.REDUCE]: null,
            [Recommendation.AVOID]: [
              'alcohol',
              'carnes_rojas_altas_en_grasa',
              'embutidos',
            ],
          }
        }
      }
    ],
    examples: ['TRHR']
  }
}
