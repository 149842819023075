import { useNavigateToTop } from '@/hooks/useNavigateTop';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import QuestionnaireImage from '@/assets/images/questionnaire-image.png';
import QuestionnaireImageMobile from '@/assets/images/questionnaire-image-mobile.png';
import QuestionnaireIcon from '@/assets/icons/questionnaire-icon.svg';

type QuestionnaireSectionProps = {
  progress?: number | null;
  subscriptionValid: boolean;
}

const COMPLETE_QUESTIONNAIRE_MSG: string = "¡Cuestionario completo!";

function QuestionnaireSection({ subscriptionValid, progress }: QuestionnaireSectionProps) {
  const navigate = useNavigateToTop();
  return (
    <Card className="mb-4 home-card">
      <Container>
        <Row>
          <Col lg="auto" xs={12} className="px-0">
            <img src={QuestionnaireImage} alt="" className="home-section-image banner-questionnaire d-none d-lg-block" />
            <img src={QuestionnaireImageMobile} alt="" className="home-section-image d-flex d-lg-none" />
          </Col>
          <Col className="pt-3 pb-lg-0">
            <h2 className="home-title subtitle">
              <img src={QuestionnaireIcon} alt="" className="me-3" />
              {progress && progress > 0 ? COMPLETE_QUESTIONNAIRE_MSG : 'Cuestionario de perfilamiento'}
            </h2>
            <p className="home-text mt-3 text-center text-lg-start">
              {
                progress && progress > 0 ?
                  'Las respuestas que obtendremos de las preguntas se tomarán en cuenta para identificar sus riesgos con mayor precisión.'
                  :
                  'Completa tu perfil ahora para recibir recomendaciones personalizadas sobre tu nutrición que se ajusten a tus ' +
                  'necesidades y objetivos. ¡Tu camino hacia el bienestar empieza aquí!'
              }
            </p>
          </Col>
          <Col className="home-button-container" lg={3}>
            <Button variant="outline-secondary" className="fw-bold mb-4" disabled={!subscriptionValid} onClick={() => navigate('/questionnaire')} >
              {progress && progress > 0 ? "Editar Cuestionario" : "Comenzar Cuestionario"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default QuestionnaireSection;
