import { useState, useEffect, useCallback } from 'react';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

// state & context
import { loadUserInfo } from '@/store/loader';
import { useStateStore } from '@/store/store';
import { useCurrentUser } from '@/components/AuthContext';
import { useNavigateToTop } from '@/hooks/useNavigateTop';

// services
import { saveQuestionnaire, } from '@/services/questionnaire/questionnaire-service';

// models & types
import {
  FormPersonalData,
  FormDailyRoutine,
  FormNutrition,
} from '@/models';
import { formDomainAdapter } from './FormDomainAdapter';

// my components
import QuestionnaireFormPersonalInformation from './QuestionnaireFormPersonalInformation';
import QuestionnaireFormDailyRoutines from './QuestionnaireFormDailyRoutines';
import QuestionnaireFormNutrition from './QuestionnaireFormNutrition';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import BackdropComponent from './components/BackdropComponent';

// utils
import { validSubscription } from '@/utils';
import { AppState } from '@/store/interfaces';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';

const saveFormData = async (
  formPersonalInfoToSave: FormPersonalData,
  formDailyRoutineToSave: FormDailyRoutine,
  formNutritionToSave: FormNutrition,
  store: AppState
) => {
  const userToken = localStorage.getItem('userToken');

  if (!userToken) throw new Error('No user token found!');

  const questionnaireForms = {
    personalData: {
      ...formPersonalInfoToSave,
      medicalConditionIds: formPersonalInfoToSave.medicalConditionIds.filter(c => c !== 'none'),
      allergyOrIntoleranceIds: formPersonalInfoToSave.allergyOrIntoleranceIds.filter(c => c !== 'none'),
      medicationIds: formPersonalInfoToSave.medicationIds.filter(c => c !== 'none')
    },
    dailyRoutine: {
      ...formDailyRoutineToSave,
      sleepDescriptionIds: formDailyRoutineToSave.sleepDescriptionIds.filter(c => c !== 'none')
    },
    nutrition: {
      ...formNutritionToSave,
      foodSupplementIds: formNutritionToSave.foodSupplementIds.filter(c => c !== 'none')
    }
  };

  const { hasResults, setQuestionnaireData } = store;
  const questionnaireData = formDomainAdapter.formToDomain(questionnaireForms);

  await saveQuestionnaire(questionnaireData, userToken, hasResults());

  setQuestionnaireData(questionnaireData);
};

export default function QuestionnairePage() {
  const navigate = useNavigateToTop();
  const store = useStateStore();
  const currentUser = useCurrentUser();

  const [error, setError] = useState(null);
  const [errorSaveData, setErrorSaveData] = useState<boolean>(false);
  const [finishedQuestionnaire, setFinishedQuestionnaire] = useState(false);

  const [showingFormPersonalInformation, setShowingFormPersonalInformation] = useState(true);
  const [showingFormDailyRoutines, setShowingFormDailyRoutines] = useState(false);
  const [showingFormNutrition, setShowingFormNutrition] = useState(false);

  const [isUploadingQuestionnaire, setIsUploadingQuestionnaire] = useState(false);

  const {
    questionnaireData,
    hasQuestionnaireData,
    hasQuestionnaireOptions,
    isLoadingQuestionnaire,
    questionnaireOptions,
    isLoadingQuestionnaireOptions,
    setIsLoadingQuestionnaire,
  } = store;

  const [formPersonalInfo, setFormPersonalInfo] = useState<FormPersonalData>({
    genderId: undefined,
    birthDate: undefined,
    weightKg: undefined,
    heightCm: undefined,
    medicalConditionIds: [],
    otherMedicalConditionsText: undefined,
    allergyOrIntoleranceIds: [],
    otherAllergiesOrIntolerancesText: undefined,
    medicationIds: [],
    smokes: undefined,
    alcoholConsumptionLevelId: undefined,
    goalId: undefined,
    wellnessImprovementGoalId: undefined,
    hasFollowedDiet: undefined,
    experienceFollowingDietsText: undefined,
    experienceTryingToLooseWeightText: undefined,
    weightGoalKg: undefined,
    hasTriedToGainWeight: undefined,
    followingDiet: undefined,
    gainMuscularWeightFatTimePriorityId: undefined,
    gainMuscularWeightTrainingTimeInMonths: undefined,
    gainMuscularWeightApetiteLevelId: undefined
  });

  const [formDailyRoutine, setFormDailyRoutine] = useState<FormDailyRoutine>({
    sleepHoursId: undefined,
    sleepDescriptionIds: [],
    engagesInSport: undefined,
    sportsData: [],
    dailyActivityLevelId: undefined,
    meanOfTransportId: undefined
  });

  const [formNutrition, setFormNutrition] = useState<FormNutrition>({
    mealsPerDayIds: [],
    dietRestrictionTypeIds: [],
    intermitentFastingHours: undefined,
    intermitentFastingFirstMealHour: undefined,
    animalProteinPreferenceIds: [],
    vegetalProteinPreferenceIds: [],
    legumePreferenceIds: [],
    carbPreferenceIds: [],
    fatPreferenceIds: [],
    lactosePreferenceIds: [],
    fruitPreferenceIds: [],
    vegetablePreferenceIds: [],
    foodSupplementIds: [],
    vitaminSupplementsText: undefined,
    mineralSupplementsText: undefined,
    otherSupplementsText: undefined,
    dailyWaterConsumptionLevelId: undefined,
    sugaryDrinkFrequencyId: undefined,
    nonSugarDrinkFrequenceId: undefined,
    generalSweetsFrequencyId: undefined,
    generalFastFoodFrequencyId: undefined,
    apetiteFeelingId: undefined
  });

  useEffect(() => {
    if (!hasQuestionnaireData() || !hasQuestionnaireOptions()) {
      loadUserInfo(store)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    checkSubscription();
    if (questionnaireData) {
      const {
        personalData,
        dailyRoutine,
        nutrition
      } = formDomainAdapter.domainToForm(questionnaireData);

      setFormPersonalInfo(personalData);
      setFormDailyRoutine(dailyRoutine);
      setFormNutrition(nutrition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireData]);

  const checkSubscription = async () => {
    if (currentUser && currentUser?.subscription) {
      if (currentUser?.subscription) {
        if (!validSubscription(currentUser?.subscription)) {
          navigate('/');
        }
      }
    }
  }

  const updatePersonalInformationData = useCallback((form: FormPersonalData) => {
    setFormPersonalInfo(form);
  }, []);

  const updateDailyRoutineData = useCallback((form: FormDailyRoutine) => {
    setFormDailyRoutine(form);
  }, []);

  const updateNutritionDataAndEndQuestionnaire = useCallback(async (form: FormNutrition) => {
    setIsLoadingQuestionnaire(true);
    setIsUploadingQuestionnaire(true);
    try {
      setFormNutrition(form);
      await saveFormData(formPersonalInfo, formDailyRoutine, form, store);
      setFinishedQuestionnaire(true);
      showForm('personalInfo');
    } catch (error) {
      console.log('Ha ocurrido un error');
      setErrorSaveData(true);
    } finally {
      setIsLoadingQuestionnaire(false);
      setIsUploadingQuestionnaire(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPersonalInfo, formDailyRoutine]);

  //summarize the next 3 functions into a single one suign a switch statement

  const showForm = (form: string) => {
    const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    switch (form) {
      case 'personalInfo':
        setShowingFormDailyRoutines(false);
        setShowingFormPersonalInformation(true);
        setShowingFormNutrition(false);
        scrollToTop();
        break;
      case 'dailyRoutines':
        setShowingFormDailyRoutines(true);
        setShowingFormPersonalInformation(false);
        setShowingFormNutrition(false);
        scrollToTop();
        break;
      case 'nutrition':
        setShowingFormDailyRoutines(false);
        setShowingFormPersonalInformation(false);
        setShowingFormNutrition(true);
        scrollToTop();
        break;
      default:
        scrollToTop();
        break;
    }
  }

  const backToFormPersonalInformation = async (form: FormDailyRoutine) => {
    setFormDailyRoutine(form);

    showForm('personalInfo');
  };

  const backToFormDailyRoutines = async (form: FormNutrition) => {
    setErrorSaveData(false);
    setFormNutrition(form);

    showForm('dailyRoutines');
  };

  const closeQuestionnairePersonalData = (async (form: FormPersonalData, save: boolean) => {
    if (!errorSaveData) {
      await loadUserInfo(store);
      navigate('/');
    }
  });

  const closeQuestionnaireDailyData = (async (form: FormDailyRoutine, save: boolean) => {
    if (!errorSaveData) {
      await loadUserInfo(store);
      navigate('/');
    }
  });

  const closeQuestionnaireNutritionData = (async (form: FormNutrition, save: boolean) => {
    if (!errorSaveData) {
      await loadUserInfo(store);
      navigate('/');
    }
  });

  if (finishedQuestionnaire) {
    return (
      <Container
        style={{ height: '100vh' }}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='w-100 d-flex justify-content-center align-items-center'>
          <SuccessMessage
            text="Has completado el cuestionario!"
            description={
              <span>
                Gracias por completar tu perfilamiento!
                <br />
                Ahora podremos brindarte una dieta personalizada según tus necesidades y objetivos.
              </span>
            }
            to="/"
            textLink="Ir a Inicio"
          />
        </div>
      </Container>
    )
  }

  else if (errorSaveData) {
    return (
      <Container
        style={{ height: '100vh' }}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='w-100 d-flex justify-content-center align-items-center'>
          <ErrorMessage
            text="Oops, ha ocurrido un error!"
            description="Ha ocurrido un error al guardar tus datos, por favor intenta de nuevo más tarde."
            to="/"
            textLink="Ir a Inicio"
          />
        </div>
      </Container>
    )
  }

  return (
    <>
      <BackdropComponent
        visible={isLoadingQuestionnaire || isLoadingQuestionnaireOptions || isUploadingQuestionnaire}
        displayText={`${!isUploadingQuestionnaire ? 'Cargando' : 'Subiendo'} Cuestionario`}
      />
      {questionnaireOptions && <Container fluid className="page pt-2 pt-lg-4 px-0">
        <Container className="page-container">
          {showingFormPersonalInformation && (
            <QuestionnaireFormPersonalInformation
              options={questionnaireOptions}
              updateForm={(form) => updatePersonalInformationData(form)}
              goToNextSection={() => showForm('dailyRoutines')}
              currentForm={formPersonalInfo}
              goBack={(form, save) => closeQuestionnairePersonalData(form, save)}
              close={(form, save) => closeQuestionnairePersonalData(form, save)}
            />
          )}
          {showingFormDailyRoutines && (
            <QuestionnaireFormDailyRoutines
              options={questionnaireOptions}
              updateForm={(form) => updateDailyRoutineData(form)}
              gotoNextSection={() => showForm('nutrition')}
              currentForm={formDailyRoutine}
              goBack={(form) => backToFormPersonalInformation(form)}
              close={(form, save) => closeQuestionnaireDailyData(form, save)}
            />
          )}
          {showingFormNutrition && (
            <QuestionnaireFormNutrition
              options={questionnaireOptions}
              updateFormAndGoToNextSection={async (form) => await updateNutritionDataAndEndQuestionnaire(form)}
              currentForm={formNutrition}
              goBack={(form) => backToFormDailyRoutines(form)}
              close={(form, save) => closeQuestionnaireNutritionData(form, save)}
            />
          )}
        </Container>
        <Modal show={error !== null} onHide={() => setError(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error}
          </Modal.Body>
        </Modal>
      </Container>}
    </>
  );
}