import React, { createContext, useContext, useState } from 'react';
import Notification, { NotificationToastVariant } from '@/components/Notification/Notification';

interface NotificationContextType {
  showNotification: (message: string, variant: NotificationToastVariant) => void;
}

interface NotificationItem {
  id: number;
  message: string;
  variant: NotificationToastVariant;
}

const NotificationContext = createContext<NotificationContextType>({
  showNotification: () => {},
});

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const showNotification = (message: string, variant: NotificationToastVariant) => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, variant }]);

    // Auto remove notification after 5 seconds
    setTimeout(() => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, 5000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <div style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}>
        {notifications.map((notification) => (
          <div key={notification.id} style={{ marginBottom: 10 }}>
            <Notification
              show={true}
              variant={notification.variant}
              text={[notification.message]}
              onClose={() => {
                setNotifications(prev => 
                  prev.filter(n => n.id !== notification.id)
                );
              }}
            />
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};
