import {
  useState,
  useEffect,
} from "react";

import { useNavigateToTop } from "@/hooks/useNavigateTop";

import Container from "react-bootstrap/esm/Container";
import Collapse from "react-bootstrap/esm/Collapse";

import MainLayout from "@/components/MainLayout/MainLayout";
import { useCurrentUser } from "@/components/AuthContext";
import ResultsTutorial from "./components/Tutorial/ResultsTutorial";
import { LoadingPage } from "@/router/LoadingPage";

import { useStateStore } from "@/store/store";
import { loadUserInfo } from "@/store/loader";
import { categoryNames, LandingCategoryType, RecommendationValues } from "./ResultsComponentsModel";

import allergiesImgSrc from '@/assets/results/allergies/images/allergies.webp';
import wellnessImgSrc from '@/assets/results/wellness/images/wellness.webp';
import bodyImgSrc from '@/assets/results/body/images/body.webp';
import vitaminsImgSrc from '@/assets/results/vitamins/images/vitamins.webp'
import cardioImgSrc from '@/assets/results/cardio/images/cardio.webp';
import carbsImgSrc from '@/assets/results/carbs/images/carbs.webp';
import healthyNotebookSrc from '@/assets/results/diets-panel/images/healthy-notebook.webp';
import backgroundSrc from '@/assets/results/results.webp';

import { ArrowRightCircleFill, DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";

import './GeneticResults.scss';
import { CategoryIds, PredispositionIds, Recommendation, SubcategoryIds } from "./result-constants";
import { GeneticResults, Subcategory } from "@/models";
import { MYNU_PRIMARY_COLOR } from "@/utils/constants";
import { truncateTo } from "@/utils";

function ResultPage() {
  const store = useStateStore();
  const navigate = useNavigateToTop();
  const { hasResults } = store;

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!hasResults()) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const [show, setShow] = useState(false);

  const categories: LandingCategoryType[] = [
    {
      id: CategoryIds.ALLERGIES,
      name: categoryNames[CategoryIds.ALLERGIES],
      imgSrc: allergiesImgSrc,
    },
    {
      id: CategoryIds.WELLNESS,
      name: categoryNames[CategoryIds.WELLNESS],
      imgSrc: wellnessImgSrc,
    },
    {
      id: CategoryIds.BODY,
      name: categoryNames[CategoryIds.BODY],
      imgSrc: bodyImgSrc,
    },
    {
      id: CategoryIds.VITAMINS,
      name: categoryNames[CategoryIds.VITAMINS],
      imgSrc: vitaminsImgSrc,
    },
    {
      id: CategoryIds.CARDIOVASCULAR,
      name: categoryNames[CategoryIds.CARDIOVASCULAR],
      imgSrc: cardioImgSrc,
    },
    {
      id: CategoryIds.CARBOHYDRATES,
      name: categoryNames[CategoryIds.CARBOHYDRATES],
      imgSrc: carbsImgSrc,
    }
  ];

  return (
    <MainLayout>
      <div className="mt-4 results-page-container">

        <div className="category-container results-header"
          style={{
            '--bg-image-src': `url("${backgroundSrc}")`,
          } as React.CSSProperties}
        >
          <Container className='p-0 header-container'>
            <div className="title">
              Mis Resultados Genéticos
            </div>
          </Container>
        </div>

        <Container style={{ background: 'white', margin: '1rem 0', padding: 0, borderRadius: '12px' }}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="mynu-stars d-flex gap-1 flex-column flex-md-row text-center align-items-baseline" style={{ margin: '1rem' }}>
              <span className='font-avenir' style={{ fontSize: '3vh'}}>¿Cómo puedo entender</span>
              <span className='font-avenir-bold' style={{ fontSize: '3vh' }}>mis resultados genéticos?</span>
            </span>
            <Collapse in={show}>
              <div>
                <ResultsTutorial />
              </div>
            </Collapse>
            <div className="mynu-stars" onClick={() => setShow(!show)} style={{ cursor: 'pointer', margin: '1rem auto' }}>
              {show ? <DashCircleFill size={'25px'} /> : <PlusCircleFill size={'25px'} />}
            </div>
          </div>
        </Container>

        <div className='d-flex justify-content-center align-items-center'>
          <div className="purple-text" style={{ padding: '1rem 3rem' }}>
            Descubre tu riesgo genético
          </div>
        </div>
        {
          hasResults() ?
            <Container className="p-0">
              <div
                className="categories-container"
                style={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridTemplateAreas: `
            "${CategoryIds.ALLERGIES} ${CategoryIds.ALLERGIES}"
            "${CategoryIds.WELLNESS} ${CategoryIds.BODY}"
            "${CategoryIds.VITAMINS} ${CategoryIds.VITAMINS}"
            "${CategoryIds.CARDIOVASCULAR} ${CategoryIds.CARDIOVASCULAR}"
            "${CategoryIds.CARBOHYDRATES} ${CategoryIds.CARBOHYDRATES}"
          `
                }}
              >
                {categories && categories.length > 0 ?
                  categories.map((c, i) => {
                    return (
                      <div
                        key={`genetic-results-categories-${c.id}-${i}`}
                        className="category-button"
                        style={{
                          backgroundImage: `
                      linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.4) 85%),
                      url(${c.imgSrc})
                    `,
                          backgroundSize: 'cover',
                          backgroundPosition: '0 35%',
                          gridArea: c.id
                        }}
                        onClick={() => navigate(`/results/${c.id}`)}
                      >
                        <div className="w-100 d-flex flex-row justify-content-between align-items-end">
                          <div className="category-name-container">
                            {c.name}
                          </div>
                          <div className="category-arrow-icon-container">
                            <ArrowRightCircleFill size={30} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  : null}
              </div>

              <div className='d-flex justify-content-center align-items-center'>
                <div className="purple-text" style={{ padding: '1rem 3rem' }}>
                  Descubre tu alimentación ideal
                </div>
              </div>

              <div className="categories-container d-grid">
                <div className="category-button"
                  style={{
                    backgroundImage: `              
                linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(137, 137, 137, 0.28) 55%, rgba(0, 0, 0, 0.60) 85%),
                linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 85%),
                url(${healthyNotebookSrc})
              `,
                    backgroundSize: 'cover',
                    backgroundPosition: '0 35%',
                  }}
                  onClick={() => navigate(`/results/diets-panel`)}
                >
                  <div className="w-100 d-flex flex-row justify-content-between align-items-end">
                    <div className="w-100 d-flex flex-column align-items-start">
                      <div className="category-name-container">
                        Panel de dietas
                      </div>
                      <div style={{ fontSize: 'small', color: 'white' }}>
                        Descubre qué patrón alimenticio
                        puede traerte mejores resultados, dependiendo de tu objetivo.
                      </div>
                    </div>
                    <div className="category-arrow-icon-container">
                      <ArrowRightCircleFill size={30} />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            :
            <LoadingPage />
        }

      </div>

    </MainLayout >
  );
}

export default ResultPage;


export type IndexedCategory = {
  [key in SubcategoryIds]: Subcategory;
}
export const getCategoryFromResults = (r: GeneticResults | null, categoryId: CategoryIds): IndexedCategory | null => {
  const category = r?.main_panel?.find(r => r.category_name === categoryId);

  if (category) {
    const resultsObj = {
      ...Object.fromEntries(
        category.subcategories.map(sbcat => [sbcat.subcategory_name as SubcategoryIds, sbcat])
      )
    }
    return resultsObj as { [x in SubcategoryIds]: Subcategory };
  } else {
    return null;
  }
}

export const replacePRSOR = (t: string, prs: number | null, or: number | null) => {
  return t.replaceAll(
    '%[PRS_VALUE]%',
    prs && prs > 1 ?
      `<span>
        Debido a tu genética,
        <span style="color:${MYNU_PRIMARY_COLOR}; font-weight: bold;">
          ${' '}tienes ${truncateTo(prs, 2)} veces ${prs > 1 ? 'más' : 'menos'} riesgo
        </span>
        que
         </span>
        `
      : 'No posees riesgo en comparación con'
  )
}

export const buildFoodTagArgumentsUrl = (category: CategoryIds, subcategory: SubcategoryIds, predisposition: PredispositionIds) => {
  const navigateUrl = `/results/explanation/${category}/${subcategory}/${predisposition}/`;

  return navigateUrl;
}

export const parseRecommendation = (r: RecommendationValues, recommendation: Recommendation) => {
  if (r === null) return null;
  if (typeof r === 'string') return r;
  return r?.[recommendation];
}
