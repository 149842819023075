import { useMemo, useState } from "react";
import { Card } from "react-bootstrap";

import { MacronutrientsChart } from "../MacroChart/MacroNutrientsChart";

import { fesNutrientReportType } from "@/models/fes-model";

import { adaptationLevels, macroNutrients, objectives, objectivesMap } from "../../constants";
import { adaptationLevelFromBoundsAndValue, translateMacroToEs } from "@/utils/fes";
import { useNotifications } from "@/contexts/NotificationContext";

interface MacroNutrientsProps {
  goal: objectives;
  protein: fesNutrientReportType | null;
  carbs: fesNutrientReportType | null;
  fat: fesNutrientReportType | null;
}

const macros: macroNutrients[] = [
  macroNutrients.PROTEINS,
  macroNutrients.CARBS,
  macroNutrients.FAT
];

type AdaptationLevelsFromMacros = {
  [key in macroNutrients]: adaptationLevels | null;
}

export default function Macronutrients(props: MacroNutrientsProps) {

  const { showNotification } = useNotifications();

  const {
    goal,
    protein,
    carbs,
    fat,
  } = props;

  const calculateAdaptation = (macro: fesNutrientReportType | null) => {
    if (!macro) return null;

    const actual = macro.value;
    const lb = macro.dailyLb;
    const ub = macro.dailyUb;

    try {
      return adaptationLevelFromBoundsAndValue(actual, lb, ub);
    } catch (error) {
      showNotification(
        'Ha habido un error al calcular el nivel de adaptación para: '
        + translateMacroToEs(macro.name),
        'danger'
      );
      return null;
    }
  }

  const adaptationsDerivedFromMacros = useMemo(() => {

    const proteinAdaptation = calculateAdaptation(protein);
    const carbsAdaptation = calculateAdaptation(carbs);
    const fatAdaptation = calculateAdaptation(fat);

    return {
      protein: proteinAdaptation,
      carbs: carbsAdaptation,
      fat: fatAdaptation
    } as AdaptationLevelsFromMacros;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protein, carbs, fat]);

  const [selectedMacro, setSelectedMacro] = useState<fesNutrientReportType | null>(
    // find first adaptation not null
    () => {
      if (protein && adaptationsDerivedFromMacros.protein) return protein;
      if (carbs && adaptationsDerivedFromMacros.carbs) return carbs;
      if (fat && adaptationsDerivedFromMacros.fat) return fat;
      return null;
    }
  );

  const handleClick = (macro: macroNutrients) => {
    setSelectedMacro(
      macro === macroNutrients.PROTEINS ? protein
        : macro === macroNutrients.CARBS ? carbs
          : fat
    )
  }

  const selectedMacroAdaptation = useMemo(() => {
    return adaptationsDerivedFromMacros[selectedMacro?.name as macroNutrients];
  }, [adaptationsDerivedFromMacros, selectedMacro]);

  return (
    protein && carbs && fat ?
      <Card className="mynu-shadow card-bottom">

        <Card.Header> Macronutrientes </Card.Header>

        <Card.Body className="macro-body">
          {/* macro selector */}
          <div className="buttons-container">
            {macros.map((macro: macroNutrients, i) => {
              let name = translateMacroToEs(macro);
              return (name && adaptationsDerivedFromMacros[macro] ?
                <div
                  key={`macro-button-${macro}`}
                  className={`mb-2 ${selectedMacro?.name === macro ? 'selected' : 'macro-button'}`}
                  onClick={() => handleClick(macro)}
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </div>
                : null
              )
            })
            }
          </div>

          {selectedMacro && selectedMacroAdaptation ?
            <div className="h-100 macro-body-content">
              {/* charts */}
              <div className="mb-2">
                {selectedMacro ?
                  <MacronutrientsChart
                    name={selectedMacro.name}
                    value={selectedMacro.value}
                    dailyLb={selectedMacro.dailyLb}
                    dailyUb={selectedMacro.dailyUb}
                    unit={selectedMacro.unit}
                    level={selectedMacroAdaptation}
                  />
                  : null
                }
              </div>
              {/* Text box */}
              <div className={`${selectedMacroAdaptation}`}>
                <div className="font-avenir-bold">
                  {
                    objectivesMap[goal]
                      ?.macros[selectedMacro.name as macroNutrients]
                      ?.[selectedMacroAdaptation]
                      ?.title
                  }
                </div>
                <div className="h-50 d-flex color-text">
                  {
                    objectivesMap[goal]
                      ?.macros[selectedMacro.name as macroNutrients]
                      ?.[selectedMacroAdaptation]
                      ?.description
                  }
                </div>
              </div>
            </div>
            : null
          }
        </Card.Body >
      </Card >
      : null
  )
}
