import { useState } from 'react';

import Badge from 'react-bootstrap/Badge';

import { DashCircleFill, PlusCircleFill } from 'react-bootstrap-icons';

import './ResponsiveCard.scss';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MYNU_PRIMARY_COLOR } from '@/utils/constants';

interface ResponsiveCardProps {
  md?: boolean,
  title?: JSX.Element | string,
  description: string,
  icon?: JSX.Element,
  subtitle?: string,
  examples?: string[]
}

const MAX_DISPLAY_EXAMPLES = 3;

export default function ResponsiveCard(props: ResponsiveCardProps) {

  const {
    md,
    title,
    description,
    subtitle,
    icon,
    examples
  } = props;

  const [moreExamples, setMoreExamples] = useState(false);

  return (
    <div className="responsive-card-container">
      {description.length > 0 ?
        <div className='top'>
          {icon ?
            <div className="icon-img"
              style={{
                margin: md && title ? "3rem 0.5rem 1rem" : ''
              }}
            >
              {icon}
            </div>
            : null}

          <div>
            {md && title ? <div className="card-title"> {title} </div> : null}

            <div className="content">
              <div className="info">
                <div className="responsive-card-description">
                  {md ?
                    <Markdown remarkPlugins={[remarkGfm]} >
                      {description}
                    </Markdown>
                    : <div
                      dangerouslySetInnerHTML={{
                        __html: (!md && title ? `<span style="color: ${MYNU_PRIMARY_COLOR};">${title + ' '}</span>` : '') + description
                      }}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        : null
      }
      {subtitle ?
        <div className="aditional-info">
          <div className="sub-title"> {subtitle} </div>
          {examples && examples.length > 0 ?
            <div
              className={`examples ${moreExamples ? 'expanded' : ''}`}
              style={{
                '--heigth-offset': moreExamples ? (examples.length / MAX_DISPLAY_EXAMPLES) * 35 + 'px' : 0,
              } as React.CSSProperties}
            >
              {examples.slice(0, moreExamples ? examples.length : MAX_DISPLAY_EXAMPLES)!.map((e, i) =>
                <Badge pill key={`examples-${i}`}>{e}</Badge>
              )}
            </div>
            : null}
          {examples && examples?.length > MAX_DISPLAY_EXAMPLES ?
            <div className='more' onClick={() => setMoreExamples(!moreExamples)}>
              {!moreExamples ? <PlusCircleFill /> : <DashCircleFill />}
            </div>
            : null}
        </div>
        : null}

    </div>
  )
}
