import { useState } from "react";

import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import Modal from 'react-bootstrap/Modal';

import { ArrowLeftCircle, XCircleFill } from "react-bootstrap-icons";

import './Tutorial.scss';

type TutorialSlidesProps = {
  title: JSX.Element;
  description: JSX.Element;
  buttonText: string;
  SecondButtonText?: string;
  imageSrc?: string;
  extraImg?: string
}

interface TutorialProps {
  show: boolean,
  handleClose: (openFesTutorial: boolean) => void,
  handleReturn?: () => void,
  tutorialSlidesInfo: TutorialSlidesProps[],
  startIndex: number
}

export default function Tutorial({ show, handleClose, handleReturn, tutorialSlidesInfo, startIndex }: TutorialProps) {
  const [index, setIndex] = useState(startIndex);
  const handleSelect = (id: number) => {
    setIndex(id);
  }

  const handleCloseModal = (openFesTutorial: boolean) => {
    if (openFesTutorial) {
      handleClose(true);
    } else {
      setIndex(startIndex);
      handleClose(false);
    }
  }

  const secondButtonPadding = '2% 10%';

  return (
    <Modal
      className="tutorial-modal"
      show={show}
      handleClose={handleClose}
      onHide={() => handleCloseModal(false)}
      centered
    >

      <Modal.Header>
        <div className="back-button">
          {index !== startIndex ?
            <ArrowLeftCircle size={28} style={{ cursor: 'pointer' }} onClick={() => setIndex(index - 1)} />
            : null}
        </div>
        {!!handleReturn ?
          <div className="return-button">
            <Button variant='outline' onClick={() => { handleReturn() }}>Volver al tutorial</Button>
          </div>
          : null}
        <div className="close-button">
          <XCircleFill size={28} onClick={() => handleCloseModal(false)} />
        </div>

      </Modal.Header>
      <Modal.Body className="p-0">

        <Card className='tutorial-card'>
          <Card.Body>

            <Carousel
              className="animated-carousel"
              activeIndex={index}
              onSelect={handleSelect}
              data-bs-theme="dark"
              interval={null}
              controls={false}
              indicators={false}
              wrap={false}
            >

              {tutorialSlidesInfo.map(({ title, description, buttonText, SecondButtonText, imageSrc, extraImg }: TutorialSlidesProps, i) => (
                <CarouselItem key={`tutorial-slide-${i}`} id={`tutorial-slide-id-${i}`} className="p-0">
                  {!!imageSrc ? <div className="card-img mb-3">
                    <img src={imageSrc} alt={`Imagen tutorial ${i}`} />
                  </div> : null}
                  <Card.Text>
                    <div>
                      {title}
                    </div>
                    <div className="description1 mb-3 mt-3">
                      {description}
                    </div>
                  </Card.Text>
                  {!!extraImg ? (
                    <div className="card-img-extra mb-3"><img src={extraImg} alt={`Imagen auxiliar ${i}`} /></div>) : null}
                  <div className="card-button mb-2">
                    {!!SecondButtonText ?
                      <Button variant="warning" style={{ padding: secondButtonPadding }} onClick={() => {
                        if (i === 4) {
                          handleCloseModal(true);
                        }
                      }}>{SecondButtonText}</Button> : null}
                    <Button variant="primary" style={{ padding: !!SecondButtonText ? secondButtonPadding : '2% 30%' }} onClick={() => {
                      i < tutorialSlidesInfo.length - 1 ?
                        handleSelect(i + 1) :
                        handleCloseModal(false)
                    }}>{buttonText}</Button>
                  </div>
                </CarouselItem>
              ))}
            </Carousel>
            <Card.Text>{index !== 0 ? index + '/' + (tutorialSlidesInfo.length - 1) : ''}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  )
}