import Toast from 'react-bootstrap/Toast';

import './Notification.scss'

import { InfoCircleFill, CheckCircleFill, ExclamationTriangleFill, XCircleFill } from 'react-bootstrap-icons';

export type NotificationToastVariant = 'info' | 'warning' | 'success' | 'danger';

interface ToastProps {
  show: boolean;
  variant: NotificationToastVariant;
  text: string[];
  onClose: () => void;
}


export default function Notification(props: ToastProps) {

  const { show, variant, text, onClose } = props;

  const IconElement = () => {
    switch (variant) {
      case 'info':
        return <InfoCircleFill />;
      case 'danger':
        return <XCircleFill />;
      case 'success':
        return <CheckCircleFill />;
      case 'warning':
        return <ExclamationTriangleFill />;
      default:
        return <InfoCircleFill />;
    }
  }

  return (
    text[0].length > 0 ?
      <Toast
        show={show}
        onClose={onClose}
        className={`fw-bold mynu-notification-toast ${variant}`}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <div>
            <IconElement />
          </div>
          <div className='d-flex flex-column justify-content-center align-items-baseline'>
            {text.map((t, i) =>
              <p key={`notification-text-${i}`} className={`${i !== text.length - 1 ? 'pb-2' : ''}`}> {t} </p>
            )}
          </div>
        </Toast.Header>
      </Toast>
      : null
  )
}

