import { CategoryIds, DietsPanelCategoryIds, DietsPanelSubCategoryIds, DietsPanelTypeIds, PredispositionIds, Recommendation, SubcategoryIds } from "./result-constants";


export const categoryNames = {
  [CategoryIds.ALLERGIES]: 'Alergias e Intolerancias',
  [CategoryIds.WELLNESS]: 'Bienestar',
  [CategoryIds.BODY]: 'Composición Corporal',
  [CategoryIds.VITAMINS]: 'Vitaminas y Minerales',
  [CategoryIds.CARDIOVASCULAR]: 'Salud Cardiovascular',
  [CategoryIds.CARBOHYDRATES]: 'Asimilación de Carbohidratos',
}

type PredispositionTypeParameters = {
  [key in PredispositionIds]: {
    id: number;
    bgColor: string;
    textColor: string;
    textColor2: string;
    name: string;
  }
}

export const predispositionValues: PredispositionTypeParameters = {
  [PredispositionIds.LOW]: {
    id: 0,
    bgColor: '#33B591',
    textColor: 'white',
    textColor2: '#34B692',
    name: 'Baja',
  },
  [PredispositionIds.MEDIUM]: {
    id: 1,
    bgColor: '#FF9D00',
    textColor: 'white',
    textColor2: '#3900B3',
    name: 'Media',
  },
  [PredispositionIds.HIGH]: {
    id: 2,
    bgColor: '#FE5133',
    textColor: 'white',
    textColor2: '#FE5133',
    name: 'Alta',
  }
}


export enum Indicator {
  PRS_VALUE = 'prs',
  OR_VALUE = 'or',
  NONE = 'none'
}

export const recommendationNames = {
  [Recommendation.PREFER]: 'Prefiere',
  [Recommendation.REDUCE]: 'Reduce',
  [Recommendation.AVOID]: 'Evita',
}

export const subcategoryNames: {
  [key in SubcategoryIds]: string
} = {
  [SubcategoryIds.ALLERGIES_CELIAC]: 'Enfermedad Celiaca',
  [SubcategoryIds.ALLERGIES_COWMILK]: 'Alergia a la Leche de Vaca',
  [SubcategoryIds.ALLERGIES_FLG]: 'Barrera de la Piel',
  [SubcategoryIds.ALLERGIES_FOODALLERGY]: 'Alergias Alimentarias',
  [SubcategoryIds.ALLERGIES_HENEGG]: 'Alergia al Huevo de Gallina',
  [SubcategoryIds.ALLERGIES_LACTOSE]: 'Intolerancia a la Lactosa',
  [SubcategoryIds.ALLERGIES_PEANUT]: 'Alergia al Maní',
  [SubcategoryIds.ALLERGIES_SHRIMP]: 'Alergia al Camarón',
  
  [SubcategoryIds.BODY_LEAN]: 'Masa Corporal Magra',
  [SubcategoryIds.BODY_WEIGHT]: 'Ganancia de Peso e IMC',
  
  [SubcategoryIds.CARDIOVASCULAR_BLOODPRESSURE]: 'Presión Arterial',
  [SubcategoryIds.CARDIOVASCULAR_CHOLESTEROL]: 'Colesterol',
  [SubcategoryIds.CARDIOVASCULAR_GENERAL]: 'Salud Cardiovascular General',
  [SubcategoryIds.CARDIOVASCULAR_TRIGLYCERIDES]: 'Triglicéridos',
  
  [SubcategoryIds.CARBOHYDRATES]: 'Metabolismo de la Glucosa',

  [SubcategoryIds.VITAMINS_MINERALS_A]: 'Deficiencia de Vitamina A',
  [SubcategoryIds.VITAMINS_MINERALS_B12]: 'Deficiencia de Vitamina B12',
  [SubcategoryIds.VITAMINS_MINERALS_B6]: 'Deficiencia de Vitamina B6',
  [SubcategoryIds.VITAMINS_MINERALS_C]: 'Deficiencia de Vitamina C',
  [SubcategoryIds.VITAMINS_MINERALS_D]: 'Deficiencia de Vitamina D',
  [SubcategoryIds.VITAMINS_MINERALS_COPPER]: 'Deficiencia de Cobre',
  [SubcategoryIds.VITAMINS_MINERALS_FOLICACID]: 'Deficiencia de Ácido Fólico',
  [SubcategoryIds.VITAMINS_MINERALS_SELENIUM]: 'Deficiencia de Selenio',
  [SubcategoryIds.VITAMINS_MINERALS_ZINC]: 'Deficiencia de Zinc',

  [SubcategoryIds.WELLNESS_ALCOHOL]: 'Alcohol',
  [SubcategoryIds.WELLNESS_HISTAMINE]: 'Intolerancia a la Histamina',
  [SubcategoryIds.WELLNESS_IBD]: 'Salud Intestinal',
  [SubcategoryIds.WELLNESS_MIGRAINES_MEN]: 'Migraña',
  [SubcategoryIds.WELLNESS_MIGRAINES_WOMEN]: 'Migraña',
  [SubcategoryIds.WELLNESS_TOBACCO]: 'Tabaco',
}

export enum chartType {
  STOPLIGHT = 'stoplight',
  BAR = 'bar',
}

export enum accordionItemKind {
  TEXT = 'text',
  RECOMMENDATION = 'recommendation',
  IMG = 'image',
}

export enum cardItemKind {
  CHART = 'chart',
  CARD = 'card'
}

export type SVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;


export interface MainSubCategorybutton {
  id: string,
  name: string,
  description: string,
}

export interface AllergiesMainCardButton
  extends MainSubCategorybutton {
  IconComponent: SVGComponent,
  examples: string[]
}

export type RecommendationType = {
  [key in Recommendation]: string[] | null;
}

export type RecommendationValues = RecommendationType | string | null;

export type PredispositionTypeObj = {
  [key in PredispositionIds]: RecommendationValues;
}

export const isAccordionItemBodyType = (body: string | PredispositionTypeObj): body is PredispositionTypeObj => {
  const b = body as PredispositionTypeObj;
  return (b.low !== undefined) || (b.medium !== undefined) || (b.high !== undefined);
}

export interface AccordionItemType {
  id: string,
  kind: accordionItemKind,
  header: string,
  body: string | PredispositionTypeObj,
  replacePRSBody?: {
    [key in PredispositionIds]: string | null
  },
  metadata?: {
    imgSrc: string,
    description: string,
    examples?: string[]
  },
}

export interface SubcategoryFooterType {
  title: string,
  description: string,
  replacePRS: null | {
    [key in PredispositionIds]: string
  }
}


export interface SubcategoryAllergiesType {
  id: SubcategoryIds,
  name: string,
  kind: cardItemKind,
  heroTitle?: string,
  SubcategoryIcon: SVGComponent | null,
  indicator: Indicator,
  description?: string,
  barOrStoplight: chartType,
  accordion: AccordionItemType[],
  footer: SubcategoryFooterType
  examples?: string[],
  chartActiveIds?: allergiesDiagramIds[],
  chartImg?: string
}

export interface CategoryAllergiesType {
  id: CategoryIds,
  name: string,
  main_card: {
    id: SubcategoryIds,
    name: string,
    description: string,
    buttons: AllergiesMainCardButton[],
    question: string,
    answer: string,
    accordion: AccordionItemType[]
  }
  subcategories: SubcategoryAllergiesType[]
}


export enum allergiesDiagramIds {
  SKIN = 'skin',
  IMMUNOREGULATION = 'immunoregulation',
  ADAPTIVE = 'adaptive',
  OTHERS = 'others',
  TOBACCO = 'tobacco',
  POLLUTION = 'pollution',
  MICROBIOTA = 'microbiota',
  HEALTHYFOOD = 'healthy-food'
}

export interface LandingCategoryType {
  id: CategoryIds,
  name: string,
  imgSrc: string,
}

export type ReplacePRSText = {
  [key in PredispositionIds]: null | string[]
}

export const isReplacePRSText = (text: string | ReplacePRSText): text is ReplacePRSText => {
  const t = text as ReplacePRSText;
  return (t.low !== undefined) || (t.medium !== undefined) || (t.high !== undefined);
}

export interface SubcategoryVitaminsType {
  id: SubcategoryIds,
  name: string,
  tooltip: string,
  percentileOrStoplight: chartType,
  effects: null | string[],
  prsText: null | string | ReplacePRSText,
  examples: string[],
  mtfhrActivity: null | string[],
  body: PredispositionTypeObj,
  remember: string[] | null
}

export interface CategoryVitaminsType {
  id: CategoryIds,
  name: string,
  description?: string,
  vitaminSubcategories: SubcategoryVitaminsType[];
  mineralsSubcategories: SubcategoryVitaminsType[];
}

export type MainSubcategorytype = {
  id: SubcategoryIds,
  name: string,
  tooltip: string,
  replacePRSText: string,
  bodyTitle: string,
  cardContent: MainSubCategorybutton[],
  footer: {
    examples: string[]
  }
}

export type CardiovascularSubcategory = {
  id: SubcategoryIds,
  name: string,
  tooltip: string,
  replacePRSText: ReplacePRSText,
  recommendation: PredispositionTypeObj,
  examples: string[]
}

export interface CardiovascularDescriptionType {
  id: CategoryIds,
  name: string,
  mainSubcategory: MainSubcategorytype,
  subcategories: CardiovascularSubcategory[],
}

export enum cardiovascularButtons {
  CORONARY_DISEASE = 'coronary_disease',
  INFARCTION = 'infarction',
  FAT_METABOLISM = 'fat_metabolism',
  BLOOD_PRESSURE = 'blood_pressure',
  HOMOCYSTEINE = 'homocysteine',
}

export enum CarbohydratesTips {
  TIP1 = 'tip-1',
  TIP2 = 'tip-2',
  TIP3 = 'tip-3',
  TIP4 = 'tip-4',
  TIP5 = 'tip-5',
}

export enum carbButtons {
  INSULIN = "insulin",
  DIABETES = "diabetes",
  AMYLASE = "amylase",
}

export interface ButtonForGroup {
  id: carbButtons,
  label: string,
  IconImage: SVGComponent,
  body: string[],
  examples: string[]
}

export interface CarbohydrateSubcategoryType {
  id: SubcategoryIds,
  name: string,
  tooltip: string[],
  topcard: {
    title: string,
    replacePRSText: string[],
    buttonGroup: ButtonForGroup[]
  },
  collapse: {
    title: string,
    body: string[]
  },
  bottomCard: {
    header: string,
    body: MainSubCategorybutton[]
  },
  recommendation: PredispositionTypeObj
}

export interface CarbohydrateCatgoryType {
  id: CategoryIds,
  name: string,
  subcategories: CarbohydrateSubcategoryType[]
}

export enum BodyButtons {
  DIET = 'diet',
  ACTIVE = 'active',
  ALCOHOL = 'alcohol',
  SEDENTARY = 'sedentary',
  SLEEP = 'sleep',
}
export enum BodyButtonsAccordion {
  ENERGY_USAGE = 'energy_usage',
  FAT_METABOLISM = 'fat_metabolism',
  OXIDATIVE_STRESS = 'oxidative_stress',
  CIRCADIAN_RYTHM = 'circadian_rythm',
  FOOD_REGULATION = 'food_regulation',
  FAT_ACCUMULATION = 'fat_accumulation',
}

export enum ToggleOptions {
  YES = 'yes',
  NO = 'no'
}


export type DietPatternToggleBaseType = {
  id: DietsPanelTypeIds,
  title: string,
  message: {
    [key in ToggleOptions]: string
  },
  IconElement: SVGComponent
}

export interface DietPatternBaseOption {
  id: DietsPanelTypeIds,
  title: string,
  tooltip: string,
  IconElement: SVGComponent
}


export type DietPanelDescriptionType = {
  parentId: DietsPanelCategoryIds;
  id: DietsPanelSubCategoryIds;
  title: string;
  subtitle: string;
  description: string;
  genes: string[];
  toggles: null | DietPatternToggleBaseType[];
  buttons: null | DietsPanelTypeIds[];
}

export type DietPanelModifyType = {
  id: 'diet-panel-customization';
  title: string;
  subtitle: string;
  description: string;
  genes: string[];
  toggles: null | DietPatternToggleBaseType[];
  buttons: null | DietsPanelTypeIds[];
}