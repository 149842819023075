import { axiosInstance } from "@/api/http";
import { User, Subscription } from "@/models";

const data = require('./user.json');

export function getUserData() {
  return data.user; //TODO corregir según cómo se guardará la información del perfil del usuario en BD
}

export async function getCurrentUser(userToken: string): Promise<User | null> {
  try {
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }

    const response = await axiosInstance.get('/users/me', { headers });
    const response2 = await axiosInstance.get('/subscription', { headers });

    const user: User = response.data;
    const subscription: Subscription = response2.data;

    user.subscription = subscription;

    return user;
  } catch (e) {
    return null;
  }
}

export async function registerTestId(
  testId: string,
  user: User,
  userToken: string
): Promise<boolean> {
  const headers = {
    "Authorization": `Bearer ${userToken}`,
  }

  try {
    const data = {
      testId,
      userEmail: user.email
    }
    await axiosInstance.patch(
      `/users/test-id`,
      data,
      { headers }
    );
    return true;
  } catch (error: any) {
    let message: string = error?.response?.data || error.message

    if (message.includes('Información no válida')) {
      message = 'Kit id no válido. Intenta de nuevo.';
    } else if (message.includes('El kit ya está asociado a otro usuario')) {
      message = 'Este kit ya ha sido registrado. Si tienes problemas, contáctanos.';
    } else {
      message = 'Algo salió mal. Por favor, intenta registrar tu kit id más tarde.';
    }

    throw new Error(message);
  }
}

export const updateNumyLastLogin = async () => {
  try {

    const userToken = localStorage.getItem('userToken');
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }
    await axiosInstance.patch('/users/numy-last-login', {}, { headers });
  } catch (err: any) {
    throw new Error(`UserService - updateLastNumyLogin: ${err}`);
  }
}

export const updateUserLastLogin = async () => {
  try {
    const userToken = localStorage.getItem('userToken');
    const headers = {
      "Authorization": `Bearer ${userToken}`,
    }

    await axiosInstance.patch('/users/last-login', {}, { headers });

  } catch (err: any) {
    throw new Error(`UserService - updateLastNumyLogin: ${err}`);
  }
}