import Accordion from 'react-bootstrap/Accordion';

import './AccordionComponent.scss';


interface AccordionComponentProps {
  children: React.ReactElement
}

export default function AccordionComponent({ children }: AccordionComponentProps) {
  return (
    <div className='mynu-accordion-container'>
      <Accordion alwaysOpen>
        {children}
      </Accordion>
    </div>
  )
}