import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from "react-chartjs-2";
import { MYNU_PRIMARY_COLOR } from "../../../../utils/constants";
import { useMemo } from 'react';
import { healthy, not_healthy, risk } from './constants';
import { truncateTo } from '@/utils';
import { useMobileOrientation } from 'react-device-detect';

Chart.register(...registerables);
Chart.register(annotationPlugin);

export const RiskAgeChart = () => {
  const color1 = '#7FD7BE';
  const color2 = '#6628FF';
  const color3 = '#FF9D00';

  const data = {
    labels: [0, 1, 8, 15, 18, 55],
    datasets: [
      {
        label: 'Peso',
        data: [3, 9, 25, 53, 57, 72],
        fill: false,
        borderColor: color1,
        tension: 0.1
      },
      {
        label: 'Peso',
        data: [3, 10, 27, 60, 63, 77],
        fill: false,
        borderColor: color2,
        tension: 0.1
      },
      {
        label: 'Peso',
        data: [3, 10, 29, 66, 69, 85],
        fill: false,
        borderColor: color3,
        tension: 0.1
      }
    ]
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,  // This hides vertical grid lines
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 15,
        },
      }
    },
  };

  const circleSize = '20px';


  return (
    <div className="w-100 mb-3 d-flex flex-column">
      <div className="d-flex">
        <div style={{ borderRadius: '25px', padding: '0 1rem', border: `1px solid ${MYNU_PRIMARY_COLOR}` }}>
          Peso kg
        </div>
      </div>
      <div style={{ height: '250px' }}>
        <Line data={data} options={options} />
      </div>

      <div className="mb-3 d-flex justify-content-end">
        <div style={{ borderRadius: '25px', padding: '0 1rem', border: `1px solid ${MYNU_PRIMARY_COLOR}` }}>
          Edad
        </div>
      </div>

      <div className="d-flex justify-content-center" style={{ gap: '1rem' }}>
        <div className="d-flex flex-row gap-1">
          <div style={{ width: '40%' }}>
            <Circle size={circleSize} background={color1} />
          </div>
          <div>Riesgo Bajo</div>
        </div>
        <div className="d-flex flex-row gap-1">
          <div style={{ width: '40%' }}>
            <Circle size={circleSize} background={color2} />
          </div>
          <div>Riesgo Medio</div>
        </div>
        <div className="d-flex flex-row gap-1">
          <div style={{ width: '40%' }}>
            <Circle size={circleSize} background={color3} />
          </div>
          <div>Riesgo Alto</div>
        </div>
      </div>
    </div>
  )
}


export const RiskObeseChart = ({ percentile, prs, adds }: { percentile: number, prs: number, adds: number }) => {
  const color1 = '#FE5133';
  const color2 = '#33B591';
  const colorUser = '#6628FF';
  const { isLandscape } = useMobileOrientation();

  const xOffsetScale = useMemo(() => {
    const scale = document.documentElement.clientWidth / document.documentElement.clientHeight;

    const xOffset = (isLandscape ? 25 : 20) / scale;
    return xOffset;
  }, [isLandscape])

  const mydata = useMemo(() => {
    const maxBound = not_healthy[percentile - 1];
    const minBound = healthy[percentile - 1];

    const risk = (minBound + maxBound) / 2;

    const restrictedValue = Math.max(minBound, Math.min(maxBound, risk + adds));

    return restrictedValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentile, adds, isLandscape]);

  const data: ChartData<'line'> = {
    labels: risk,
    datasets: [
      {
        label: 'Poco Saludable',
        data: not_healthy,
        fill: false,
        borderColor: color1,
        pointRadius: 0.01
      },
      {
        label: 'Saludable',
        data: healthy,
        fill: false,
        borderColor: color2,
        pointRadius: 0.01
      },
      {
        label: 'Probabilidad (%)',
        data: [{ x: percentile, y: mydata }],
        backgroundColor: colorUser,
        borderColor: colorUser,
        borderWidth: 2,
        pointRadius: 4,
        pointStyle: 'circle',
        showLine: false
      }
    ]
  };


  // Chart options
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
      annotation: {
        annotations: {
          label1: {
            type: 'label',
            xValue: (ctx) => {
              if (percentile + 35 < 100) {
                return percentile + xOffsetScale;
              } else {
                return percentile - xOffsetScale;
              }
            },
            yValue: mydata,
            backgroundColor: MYNU_PRIMARY_COLOR,
            borderRadius: 20,
            font: {
              size: 15,
              weight: 'bold',
              family: 'Avenir Next'
            },
            color: 'white',
            padding: {
              top: 5,
              right: 15,
              bottom: 5,
              left: 15
            },
            content: `${truncateTo(1 + adds / prs, 2)}x Riesgo`,
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          display: false
        }
      },
    },
  };

  const circleSize = '20px';

  const verticalLinePlugin = useMemo(() => {
    return {
      id: 'verticalLine',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xScale = chart.scales.x;
        const yScale = chart.scales.y;

        const xPos = xScale.getPixelForValue(percentile - 1);
        const maxX = xScale.getPixelForValue(100);

        ctx.save();
        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(xPos, yScale.top);
        ctx.lineTo(xPos, yScale.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = MYNU_PRIMARY_COLOR;
        ctx.stroke();

        ctx.font = 'bold 14px Avenir';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        const label = `Percentil ${percentile}`

        let labelYPosition = yScale.bottom - 5;
        let labelXPosition = xPos + 50 > maxX ?
          xPos - 50
          : xPos - 50 > 0 ? xPos - 50 : xPos + 50;

        ctx.fillText(label, labelXPosition, labelYPosition);

        ctx.restore();
      },
    }
  }, [percentile])

  return (
    <div className="">
      <div className="mx-3 my-2 d-flex">
        <div className='chart-axis-label'>
          Probabilidad de ser obeso (%)
        </div>
      </div>
      <div className='mx-auto' style={{ height: '200px', width: '90%', overflow: 'visible' }}>
        <Line data={data} options={options} plugins={[verticalLinePlugin]} />
      </div>

      <div className="mx-3 my-2 d-flex justify-content-end">
        <div className='chart-axis-label'>
          Predisposición genética
        </div>
      </div>
      <div className='chart-obese-labels'>
        <div className="label-container justify-content-evenly justify-content-md-center">
          <div className="d-flex flex-row gap-1 align-items-center">
            <div style={{ width: '40%' }}>
              <Circle size={circleSize} background={color2} />
            </div>
            <div>Saludable</div>
          </div>
          <div className="d-flex flex-row gap-1 align-items-center">
            <div>
              <Circle size={circleSize} background={color1} />
            </div>
            <div>Poco Saludable</div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CircleProps {
  size: string,
  background: string
}


const Circle = ({ size, background }: CircleProps) => {
  return (
    <div className="m-1" style={{ background: background, width: size, height: size, borderRadius: '50%' }} />
  )
}

