import { useMemo, useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";

import ButtonBadgeComponent from "../ButtonBadge/ButtonBadge";
import ResponsiveCard from "../ResponsiveCard/ResponsiveCard";

import { SVGComponent } from "../../pages/results/ResultsComponentsModel";

import './ButtonWrapDescription.scss';

export type ButtonItem = {
  id: string;
  name: string;
  description: string;
}

export interface ButtonWrapProps {
  componentTitle?: JSX.Element;
  buttonSize?: number;
  buttons: ButtonItem[];
  iconsArray: { [x: string]: SVGComponent };
  action?: (x: string) => void;
  wide?: boolean;
  noInfoInButtons?: boolean;
}

export default function ButtonWrapDescription(props: ButtonWrapProps) {

  const {
    buttons, iconsArray, buttonSize,
    wide, componentTitle, noInfoInButtons
  } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<ButtonItem | null>(null);
  const [displayedButton, setDisplayedButton] = useState<ButtonItem | null>(null);

  const handleSelectButton = (b: ButtonItem) => {
    if (selectedButton && selectedButton.id === b.id) {
      setCollapseOpen(false);
      setTimeout(() => {
        setSelectedButton(null);
        setDisplayedButton(null);
      }, 200);
    } else {
      if (!selectedButton) {
        setSelectedButton(b);
        setDisplayedButton(b);
        setCollapseOpen(true);
      } else {
        setSelectedButton(b);
        setDisplayedButton(b);
      }
    }
  };

  const IconToUse = useMemo(() => {
    if (!displayedButton) return <></>;

    const Icon = iconsArray[displayedButton.id];
    return <Icon />;

  }, [displayedButton, iconsArray])

  return (
    <div className='button-wrap-description-container'
      style={{
        flexDirection: wide ? 'row' : 'column',
        justifyContent: wide ? 'space-evenly' : 'center'
      }}
    >
      <div className="d-flex flex-column flex-xxl-row gap2 justify-content-evenly align-items-center">
        {componentTitle}

        <div className="buttons-container" style={{ minWidth: '90%' }}>
          {buttons.map((item, i) => {
            const { id, name } = item;
            const IconComponent = iconsArray[id];
            return (
              <div className="icon-button" key={`${id}-${i}-${name}`}>
                <ButtonBadgeComponent
                  size={buttonSize ?? 3}
                  round
                  content={<IconComponent />}
                  onClick={() => handleSelectButton(item)}
                  active={selectedButton ? selectedButton.id === id : false}
                  title={name}
                  noInfo={noInfoInButtons}
                />
              </div>
            )
          })}
        </div>
      </div>
      <Collapse in={collapseOpen}>
        <div className="collapse-container">
          {displayedButton && (
            <ResponsiveCard
              title={displayedButton.name}
              description={displayedButton.description ?? ''}
              icon={
                <ButtonBadgeComponent round active noInfo size={2.5} content={IconToUse} />
              }
            />
          )}
        </div>
      </Collapse>
    </div>
  )
}