import { useEffect, useMemo, useState } from 'react';
import { loadDiet, loadQuestionnaire, loadUserInfo } from '@/store/loader';
import { useStateStore } from '@/store/store';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { useCurrentUser } from '@/components/AuthContext';

import { SyncLoader } from 'react-spinners';

import MainLayout from '@/components/MainLayout/MainLayout';
import ButtonBadgeComponent from '@/components/ButtonBadge/ButtonBadge';
import ToggleButtonComponent from '@/components/ToggleButton/ToggleButton';
import ResponsiveCard from '@/components/ResponsiveCard/ResponsiveCard';
import CustomSliderComponent from '@/components/CustomSlider/CustomSlider';
import AccordionComponent from '@/components/AccordionComponent/AccordionComponent';
import FoodTagsRecommendator from '@/components/FoodTagsRecommendator/FoodTagsRecommendator';
import StopLight from '@/components/StopLight/StopLight';
import AllergiesDiagram from './AllergiesDiagram/AllergiesDiagram';
import CollapseIcon from '@/components/CollapseIcon/CollapseIcon';
import CategoryHeader from '../CategoryHeaderComponent';
import { LoadingPage } from '@/router/LoadingPage';

import { useNotifications } from '@/contexts/NotificationContext';

import { AllergiesDescription } from './AllergiesDescription';

import { CategoryIds, PredispositionIds, Recommendation, SubcategoryIds } from '../../result-constants';
import {
  accordionItemKind, AllergiesMainCardButton, cardItemKind,
  chartType, isAccordionItemBodyType, predispositionValues,
  SubcategoryAllergiesType,
} from '../../ResultsComponentsModel';
import { QuestionnaireData } from '@/models';
import { saveQuestionnaire, updateAllergies } from '@/services/questionnaire/questionnaire-service';

import { areArraysEqual } from '@/utils';
import { buildFoodTagArgumentsUrl, getCategoryFromResults, parseRecommendation, replacePRSOR } from '../../ResultPage';

import bgSrc from '@/assets/results/allergies/images/allergies.webp';

import '../../GeneticResults.scss';
import './AllergiesComponent.scss';
import { DashCircleFill, InfoCircle, PlusCircleFill } from 'react-bootstrap-icons';


export default function AllergiesComponent() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { isLoadingDiet, hasResults, resultsData, questionnaireData, setQuestionnaireData, getToken } = store;

  const { showNotification } = useNotifications();

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.ALLERGIES), [resultsData]);

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseImageOpen, setCollapseImageOpen] = useState(false);
  const [collapseButtonOpen, setCollapseButtonOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<AllergiesMainCardButton | null>(null);
  const [displayedButton, setDisplayedButton] = useState<AllergiesMainCardButton | null>(null);

  const [originalAllergies, setOriginalAllergies] = useState<string[]>();
  const [allergiesChanged, setAllergiesChanged] = useState(false);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set initial state of allergies from store
  useEffect(() => {
    if (!allergiesChanged && questionnaireData?.allergyOrIntoleranceIds !== undefined) {
      setOriginalAllergies(questionnaireData.allergyOrIntoleranceIds);
      setAllergiesChanged(true);
    }
  }, [questionnaireData, allergiesChanged])

  const allergiesEqualToOriginal = useMemo<boolean>(() => {
    if (originalAllergies !== undefined && questionnaireData?.allergyOrIntoleranceIds !== undefined) {
      return areArraysEqual(originalAllergies, questionnaireData?.allergyOrIntoleranceIds)
    } else {
      return true;
    }
  }, [originalAllergies, questionnaireData])

  const handleSelectButton = (b: AllergiesMainCardButton) => {
    if (selectedButton && selectedButton.id === b.id) {
      setCollapseButtonOpen(false);
      setTimeout(() => {
        setSelectedButton(null);
        setDisplayedButton(null);
      }, 200);
    } else {
      if (!selectedButton) {
        setSelectedButton(b);
        setDisplayedButton(b);
        setCollapseButtonOpen(true);
      } else {
        setSelectedButton(b);
        setDisplayedButton(b);
      }
    }
  };

  const saveToggles = () => {
    const t = getToken();

    if (allergiesEqualToOriginal || !t) return;

    let subcats = {
      ...Object.fromEntries(
        AllergiesDescription.subcategories.map(s => [mapAllergiesResults(s.id), false])
      )
    }

    if (questionnaireData) {
      questionnaireData.allergyOrIntoleranceIds.forEach(a => {
        subcats[a] = true
      })
    }

    updateAllergies(subcats, t)
      .then(async () => {
        const q = questionnaireData as QuestionnaireData;
        await saveQuestionnaire(q, t, true);
        await loadQuestionnaire(store);
        await loadDiet(store);
        setAllergiesChanged(false);

        showNotification('Preferencias actualizadas', 'success');
        setAllergiesChanged(false);
      })
      .catch(() => {
        showNotification(
          'Ha ocurrido un error actualizando tus preferencias. Por favor, intenta más tarde',
          'danger'
        );
      });
  }

  const checkDiet = async () => {
    try {
      await loadDiet(store);
    } catch (error: any) {
      showNotification(
        'Ha ocurrido un error cargando tu dieta. Por favor, intenta más tarde',
        'danger'
      );
    }
  };

  useEffect(() => {
    if (isLoadingDiet) {
      checkDiet();
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);


  const handleUpdateAvoidedMeals = (meal: SubcategoryIds) => {
    if (questionnaireData) {
      const data = questionnaireData.allergyOrIntoleranceIds;
      const found = data.find(m => mapAllergiesResults(meal) === m)
      const filteredData = data.filter(m => mapAllergiesResults(meal) !== m)

      setQuestionnaireData({
        ...questionnaireData,
        allergyOrIntoleranceIds: found
          ? filteredData
          : [...data, mapAllergiesResults(meal)]
      })
    }
  }

  const handleRevertSelection = () => {
    const originalQuestionnaire = {
      ...questionnaireData,
      allergyOrIntoleranceIds: originalAllergies ?? []
    } as QuestionnaireData

    setQuestionnaireData(originalQuestionnaire)
  }

  return (
    <MainLayout>

      <div className="category-container allergies-component-container "
        style={{
          '--bg-image-src': `url("${bgSrc}")`,
          position: 'relative'
        } as React.CSSProperties}
      >
        <CategoryHeader
          title={AllergiesDescription.name}
          warnOnExit={!allergiesEqualToOriginal}
        />

        <Container className="px-0 change-parameters-container">
          <Card className='mynu-shadow'>
            <div className='gap-3 d-flex align-items-center'>
              <div>
                <InfoCircle size={'2rem'} className='mynu-stars m-2' />
              </div>
              <div>
                Puedes actualizar tu cuestionario con cada alergia o intolerancia.
              </div>
            </div>
            <div className='change-params-buttons'>
              {isLoadingDiet ?
                <div className='gap-3 d-flex flex-column m-2 align-items-center'>
                  <div><i>Tu dieta está siendo procesada, por favor intenta más tarde</i></div>
                  <SyncLoader className='mynu-stars' speedMultiplier={0.7} />
                </div>
                :
                <>
                  <Button variant='outline-primary' onClick={handleRevertSelection} disabled={allergiesEqualToOriginal}>
                    Cancelar
                  </Button>
                  <Button variant='primary' onClick={saveToggles} disabled={allergiesEqualToOriginal}>
                    Actualizar
                  </Button>
                </>
              }
            </div>
          </Card>
        </Container>

        {hasResults() && resultsState
          && resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY].prs_to_1 !== null
          && resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY].percentile !== null ?
          <>
            <Container className='px-0 main-card-container'>
              <Card className="sbcat-card food-allergies">
                <Card.Header className="title border-0">
                  <div>
                    {AllergiesDescription.main_card.name}
                  </div>
                </Card.Header>
                <Card.Body>

                  <div className="percentile-bar-container">
                    <div className="text-center">
                      <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                      <CustomSliderComponent
                        size='2.5rem'
                        percent={resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY].percentile}
                        intervals={['Menor', 'Promedio', 'Mayor']}
                        intervalsDescriptions={[
                          'Tienes menos riesgo que la población general',
                          'Tu riesgo es similar al de la población general',
                          'Tienes más riesgo que la población general'
                        ]}
                      />
                    </div>
                  </div>

                  <div className="question">
                    <div className="info-container"
                      style={{
                        "--info-container-header": `"${AllergiesDescription.main_card.question}"`
                      } as React.CSSProperties}
                    >
                      <p
                        className='text-start'
                        dangerouslySetInnerHTML={{
                          __html:
                            replacePRSOR(
                              AllergiesDescription.main_card.answer,
                              resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY].prs_to_1,
                              resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY].prs_to_1)
                        }}
                      />
                    </div>
                  </div>

                  <CollapseIcon
                    open={collapseOpen}
                    setOpen={setCollapseOpen}
                    iconSize={2}
                  >
                    <div>
                      <div className='my-4 d-flex flex-column flex-md-row-reverse justify-content-evenly'>
                        <div className="d-block d-md-none allergies-description">
                          <div>
                            {AllergiesDescription.main_card.description}
                          </div>
                        </div>

                        <div className="d-none d-md-block text-end mynu-stars"
                          style={{ fontSize: 'large', width: '40%', fontWeight: 'bolder' }}
                        >
                          <div>
                            {AllergiesDescription.main_card.description}
                          </div>
                        </div>

                        <div className="buttons-container-allergies">
                          {AllergiesDescription.main_card.buttons.map((allergyButton, i) => {
                            const { id, IconComponent, name } = allergyButton;
                            return (
                              <div className="icon-button" key={`${id}-${name}`}>
                                <ButtonBadgeComponent
                                  size={3}
                                  title={name}
                                  content={<IconComponent />}
                                  onClick={() => handleSelectButton(allergyButton)}
                                  active={selectedButton ? selectedButton.id === id : false}
                                  round
                                />
                              </div>
                            )
                          })}
                        </div>

                      </div>

                      <div className='collapse-container'>
                        <Collapse in={collapseButtonOpen}>
                          <div>
                            {displayedButton && (
                              <ResponsiveCard
                                md
                                title={
                                  <h4 className='text-start'>
                                    <Badge pill>
                                      {displayedButton.name}
                                    </Badge>
                                  </h4>
                                }
                                description={displayedButton.description}
                                subtitle="Genes"
                                examples={displayedButton.examples}
                              />
                            )}
                          </div>
                        </Collapse>
                      </div>

                      <AccordionComponent>
                        <div className="accordion-container">
                          {AllergiesDescription.main_card.accordion.map((s, i) => {
                            const { id, kind, header, body, metadata } = s;
                            const { predisposition, gene_breakdown } = resultsState[SubcategoryIds.ALLERGIES_FOODALLERGY];
                            return (
                              <Accordion.Item key={`allergies-${id}-${kind}-${i}`} eventKey={`${i}`} className='mt-0'>
                                <Accordion.Header>
                                  {header}
                                </Accordion.Header>
                                <Accordion.Body className='py-0 px-1'>
                                  {kind === accordionItemKind.TEXT &&
                                    typeof body === 'string' ?
                                    <div className='my-1 mx-2' style={{ textAlign: 'justify' }}>
                                      {body.split('.').map((d, i) =>
                                        <p key={`sbcat-allergy-body-${i}`}>{d}</p>
                                      )}
                                    </div>
                                    :
                                    metadata !== undefined ?
                                      <div
                                        className='d-flex flex-column justify-content-evenly align-items-center'
                                      >
                                        <img src={metadata.imgSrc} alt='img-a' className='d-none d-md-block' style={{ width: '85%' }} />
                                        <img src={metadata.imgSrc} alt='img-a' className='d-block d-md-none' style={{ width: '100%' }} />
                                        <CollapseIcon
                                          open={collapseImageOpen}
                                          setOpen={setCollapseImageOpen}
                                        >
                                          <ResponsiveCard
                                            description={metadata.description}
                                            subtitle={'Genes'}
                                            examples={gene_breakdown?.map(g => g.gene_name) ?? metadata.examples}
                                          />
                                        </CollapseIcon>
                                      </div>
                                      :
                                      kind === accordionItemKind.RECOMMENDATION &&
                                        body && typeof body !== 'string' &&
                                        body[predisposition] !== null ?
                                        <FoodTagsRecommendator
                                          prefer={parseRecommendation(body[predisposition], Recommendation.PREFER)}
                                          reduce={parseRecommendation(body[predisposition], Recommendation.REDUCE)}
                                          avoid={parseRecommendation(body[predisposition], Recommendation.AVOID)}
                                          navigateTo={
                                            buildFoodTagArgumentsUrl(
                                              CategoryIds.ALLERGIES,
                                              SubcategoryIds.ALLERGIES_FOODALLERGY,
                                              predisposition)
                                          }
                                          singleColumnRecommendation

                                        />
                                        : null
                                  }
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          })}
                        </div>
                      </AccordionComponent>
                    </div>
                  </CollapseIcon>
                </Card.Body>

              </Card>
            </Container>

            <Container className="subcategories-cards-container" >
              {AllergiesDescription.subcategories.map((sbcat, i) => {
                const { predisposition, prs_to_1, percentile } = resultsState[sbcat.id];

                return (
                  prs_to_1 !== null && percentile !== null && predisposition !== null ?
                    <SubcatCard
                      idPrefix={`allergies-subcat-card-${i}-`}
                      key={`allergies-subcat-card-${i}-${sbcat.id}`}
                      cardInfo={sbcat}
                      percentile={percentile}
                      predisposition={predisposition}
                      prs={prs_to_1}
                      avoided={questionnaireData
                        ? questionnaireData.allergyOrIntoleranceIds.includes(mapAllergiesResults(sbcat.id))
                        : false
                      }
                      handleToggle={handleUpdateAvoidedMeals}
                      isLoadingDiet={isLoadingDiet}
                    />
                    : null)
              })}
            </Container>
          </>
          : <LoadingPage />
        }

      </div >
    </MainLayout >
  )
}

interface SubcatCardProps {
  idPrefix: string;
  cardInfo: SubcategoryAllergiesType;
  percentile: number;
  predisposition: PredispositionIds;
  prs: number,
  avoided: boolean;
  handleToggle: (x: SubcategoryIds) => void;
  isLoadingDiet: boolean;
}
const SubcatCard = (props: SubcatCardProps) => {
  const {
    idPrefix, cardInfo,
    percentile, predisposition, prs,
    avoided, handleToggle,
    isLoadingDiet
  } = props;

  const {
    id, name, kind,
    SubcategoryIcon, description, barOrStoplight,
    accordion,
    chartActiveIds, chartImg,
    footer, examples,
  } = cardInfo;

  const [collapseOpen, setCollapseOpen] = useState(false);
  const subcategoryId = useMemo(() => id, [id]);

  return (
    <Card className='sbcat-card' id={idPrefix + id}>
      <Card.Header className='d-flex flex-column justify-content-center'>
        <div>{name}</div>
        {barOrStoplight === chartType.BAR ?
          <div className='percentile-bar-container'>
            <div className="text-center">
              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
              <CustomSliderComponent
                size={'2rem'}
                percent={percentile}
                intervals={['Menor', 'Promedio', 'Mayor']}
                intervalsDescriptions={[
                  'Tienes menos riesgo que la población general',
                  'Tu riesgo es similar al de la población general',
                  'Tienes más riesgo que la población general'
                ]}
              />
            </div>
          </div>
          :
          <>
            <div className='percentile-bar-container'>
              <div className='d-flex gap-2 flex-row justify-content-center' style={{ fontSize: 'medium' }}>
                <div>{name.includes('Intolerancia') ? 'Intolerancia' : 'Alergia'}: </div>
                <div style={{
                  color: predispositionValues[predisposition].textColor2,
                }}>{`${predispositionValues[predisposition].name}`}</div>
              </div>

              <StopLight
                colors={['#34B692', '#3900B3', '#FE5133']}
                descriptions={[]}
                titles={
                  Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].name)
                }
                changable={false}
                activeIndex={predispositionValues[predisposition].id}
              />
            </div>
          </>
        }
      </Card.Header>

      <Collapse in={collapseOpen} >
        <div>
          <Card.Body>
            {kind === cardItemKind.CHART ?
              <AllergiesDiagram activeIds={chartActiveIds} multiplier={prs} imgSrc={chartImg} />
              :
              <div className='collapse-container'>
                <ResponsiveCard
                  description={description ?? ''}
                  icon={SubcategoryIcon ? <ButtonBadgeComponent round noInfo size={3} content={<SubcategoryIcon />} /> : undefined}
                />
              </div>
            }

            <div
              className='d-flex flex-row justify-content-between'
              style={{ margin: '2rem auto', gap: '1rem', padding: '0 1rem' }}
            >
              <div>
                Si has tenido síntomas o malestares,{' '}
                <strong>elimina este alimento de tu plan nutricional.</strong>
              </div>
              <div className='my-auto'>
                {!isLoadingDiet ?
                  <ToggleButtonComponent
                    value={avoided}
                    changeValue={() => handleToggle(id)}
                    on={<></>}
                    off={<></>}
                  />
                  :
                  <div className="sync-loader">
                    <SyncLoader speedMultiplier={0.7} />
                  </div>
                }
              </div>
            </div>

            <AccordionComponent>
              <>
                {accordion.map((a, ii) => {
                  const { id, header, body } = a;
                  return (
                    <Accordion.Item
                      key={`sbcat-accordion-item-${ii}-${id}`}
                      eventKey={id}
                    >
                      <Accordion.Header>{header}</Accordion.Header>
                      <Accordion.Body>
                        {!isAccordionItemBodyType(body) ?
                          <div className='my-1 mx-2' style={{ textAlign: 'justify', color: 'black' }}> {body} </div>
                          :
                          <FoodTagsRecommendator
                            prefer={parseRecommendation(body[predisposition], Recommendation.PREFER)}
                            reduce={parseRecommendation(body[predisposition], Recommendation.REDUCE)}
                            avoid={parseRecommendation(body[predisposition], Recommendation.AVOID)}
                            navigateTo={buildFoodTagArgumentsUrl(
                              CategoryIds.ALLERGIES,
                              subcategoryId,
                              predisposition)
                            }
                            singleColumnRecommendation
                          />
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </>
            </AccordionComponent>
          </Card.Body>

          <Card.Footer className='p-0'>
            <div className="info-container"
              style={{
                "--info-container-header": `"${footer.title}"`,
              } as React.CSSProperties}
            >
              <div className='d-flex justify-content-center'>
                <ResponsiveCard
                  title={''}
                  description={
                    (replacePRSOR(footer.description, prs, prs) + '\n'
                      + (footer.replacePRS ? footer.replacePRS[predisposition] : ''))
                  }
                  subtitle={'Genes'}
                  examples={examples}
                />
              </div>
            </div>
          </Card.Footer>

        </div>
      </Collapse>

      <div className="mt-3 d-flex justify-content-center">
        <div style={{ cursor: 'pointer' }} onClick={() => setCollapseOpen(!collapseOpen)}>
          {!collapseOpen ?
            <PlusCircleFill size={'2rem'} className='mynu-stars' />
            :
            <DashCircleFill size={'2rem'} className='mynu-stars' />
          }
        </div>
      </div>
    </Card >
  )
}

const mapAllergiesResults = (v: string) => {
  switch (v) {
    case 'lactose':
      return SubcategoryIds.ALLERGIES_LACTOSE;
    case 'gluten':
      return SubcategoryIds.ALLERGIES_CELIAC;
    case 'shrimp':
      return SubcategoryIds.ALLERGIES_SHRIMP;
    case 'peanut':
      return SubcategoryIds.ALLERGIES_PEANUT;
    case 'egg':
      return SubcategoryIds.ALLERGIES_HENEGG;
    case 'milk':
      return SubcategoryIds.ALLERGIES_COWMILK;

    case SubcategoryIds.ALLERGIES_LACTOSE:
      return 'lactose';
    case SubcategoryIds.ALLERGIES_CELIAC:
      return 'gluten';
    case SubcategoryIds.ALLERGIES_SHRIMP:
      return 'shrimp';
    case SubcategoryIds.ALLERGIES_PEANUT:
      return 'peanut';
    case SubcategoryIds.ALLERGIES_HENEGG:
      return 'egg';
    case SubcategoryIds.ALLERGIES_COWMILK:
      return 'milk';

    default:
      return ''
  }
}