import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateToTop } from '@/hooks/useNavigateTop';

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from "react-bootstrap/Offcanvas";

// state & context
import { useCurrentUser } from '@/components/AuthContext';
import { useStateStore } from '@/store/store';

// utils
import { validSubscription } from '@/utils';

// my components
import MynuTooltip from '@/components/Tooltip/Tooltip';

// assets
import './AppNavbar.scss';
import LockIcon from '@/assets/images/lock-icon.svg';
import MynuLogo from '@/assets/images/mynu-y-icon.svg';
import NavbarLogo from '@/assets/images/mynu-navbar-logo.svg';
import {
  messages,
  RESULTS_DISABLED,
  NUMY_DISABLED,
  DIETS_DISABLED,
} from './constants';

import { House } from 'react-bootstrap-icons';
import {
  BookUser, Dna, FileUser, ListTodo, Lock,
  LogOut, MessageCircle, NotepadText, User, Utensils, BookOpen,
  ArrowLeft
} from 'lucide-react';

function AppNavbar() {
  const location = useLocation();
  const navigate = useNavigateToTop();
  const currentUser = useCurrentUser();
  const { hasQuestionnaireData, hasResults } = useStateStore();
  const [isSubscriptionBanner, setSubscriptionBanner] = useState(true);

  const resultsAvailable = () => {
    return currentUser && hasResults() && currentUser?.resultsDate
  }

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      if (!validSubscription(currentUser?.subscription)) {
        setSubscriptionBanner(false)
      }
    }
  }, [currentUser]);

  const signOff = async () => {
    localStorage.removeItem('userToken');
    navigate('/login');
  };

  const [showCanvas, setShowCanvas] = useState(false);

  const [selectedOffCanvas, setSelectedOffCanvas] = useState<'diets' | 'me' | null>(null)

  const handleSelectRoute = (r: 'diets' | 'me' | null) => {
    setShowCanvas(true)
    setSelectedOffCanvas(r);
  }

  const offCanvasContent = [
    {
      id: 'diets',
      header: <div className='p-0 bottom-navbar-link' style={{ color: 'black' }}>
        <Utensils />
        <div>Nutrición</div>
      </div>,
      content: <>
        <Nav.Item key={'offcanvas-diet-menu'} className="py-1 navbar-dropdown-item active">
          <div
            id={"diet-menu"}
            onClick={() => navigate('/diets/menu')}
            className={`bottom-navbar-link ${location.pathname.includes('menu') ? 'active-link' : ''}`}
          >
            <NotepadText />
            My Menú
          </div>
        </Nav.Item>
        <Nav.Item key={'offcanvas-diet-guide'} className="py-1 navbar-dropdown-item active">
          <div
            id={"diet-guide"}
            onClick={() => navigate('/diets/guide')}
            className={`bottom-navbar-link ${location.pathname.includes('guide') ? 'active-link' : ''}`}
          >
            <ListTodo />
            My Pauta
          </div>
        </Nav.Item>
        <Nav.Item key={'offcanvas-diet-summary'} className="py-1 navbar-dropdown-item active">
          <div
            id={"diet-summary"}
            onClick={() => navigate('/diets/summary')}
            className={`bottom-navbar-link ${location.pathname.includes('summary') ? 'active-link' : ''}`}
          >
            <BookUser />
            Guía Nutricional
          </div>
        </Nav.Item>
        <Nav.Item key={'offcanvas-diet-portions'} className="py-1 navbar-dropdown-item active">
          <div
            id={"diet-portions"}
            onClick={() => navigate('/diets/portions')}
            className={`bottom-navbar-link ${location.pathname.includes('portions') ? 'active-link' : ''}`}
          >
            <BookOpen />
            Glosario porciones
          </div>
        </Nav.Item>
      </>
    },
    {
      id: 'me',
      header: <div className='p-0 bottom-navbar-link' style={{ color: 'black' }}>
        <User />
        <div>Yo</div>
      </div>,
      content: <>
        <Nav.Item
          key={'offcanvas-user-profile'}
          className="py-1 navbar-dropdown-item active"
        >
          <div
            id={"profile"}
            onClick={() => navigate('/settings')}
            className={`bottom-navbar-link gap-2 navbar-link ${location.pathname.includes('settings') ? 'active-link' : ''}`}
          >
            <User />
            Mi Perfil
          </div>
        </Nav.Item>
        <Nav.Item
          key={'offcanvas-user-questionnaire'}
          className="py-1 navbar-dropdown-item active"
        >
          <div
            id={"profile"}
            onClick={() => navigate('/questionnaire')}
            className={`bottom-navbar-link navbar-link ${location.pathname.includes('questionnaire') ? 'active-link' : ''}`}
          >
            <FileUser />
            Mi Cuestionario
          </div>
        </Nav.Item>
        <Nav.Item
          key={'offcanvas-user-logout'}
          onClick={signOff} className={"bottom-navbar-link py-1 font-avenir-bold"} style={{ color: 'red' }}
        >
          <LogOut />
          Cerrar sesión
        </Nav.Item>
      </>
    }
  ]

  return (
    <>
      <Navbar fixed="bottom" expand="md" className='bottom-bar' >
        <Nav variant="underline" defaultActiveKey="/">
          <Nav.Item>
            <div
              id={"home"}
              onClick={() => navigate('/')}
              className={`navbar-link ${location.pathname === '/' ? 'active-navbar-link' : ''}`}
            >
              <div className='d-flex flex-column align-items-center'>
                <div>
                  <House />
                </div>
                Inicio
              </div>
            </div>
          </Nav.Item>
          {hasQuestionnaireData() && resultsAvailable() && !DIETS_DISABLED ?
            <Nav.Item onClick={() => handleSelectRoute('diets')} className={`navbar-link ${location.pathname.includes('/diets/') ? 'active-navbar-link' : ''}`}>
              <div className='more-options-button'>
                <div>
                  <Utensils />
                </div>
                Nutrición
              </div>
            </Nav.Item>
            :
            <MynuTooltip position='bottom'
              description={
                hasQuestionnaireData() && !resultsAvailable()
                  ? messages.DIETS_DISABLED_NEED_RESULTS_MSG
                  : !hasQuestionnaireData() ? messages.DIETS_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                    : messages.OUT_OF_SERVICE_MSG
              }
            >
              <Nav.Item className={`navbar-link disabled`}>
                <div className='d-flex flex-column align-items-center disabled'>
                  <div>
                    <Lock />
                  </div>
                  <span className='disabled-link'>Nutrición</span>
                </div>
              </Nav.Item>
            </MynuTooltip>
          }
          {hasQuestionnaireData() && resultsAvailable() && !NUMY_DISABLED ?
            <Nav.Item>
              <div
                id={"numy"}
                onClick={() => navigate('/chat')}
                className={`navbar-link ${location.pathname === '/chat' ? 'active-navbar-link' : ''}`}
              >
                <div className='d-flex flex-column align-items-center'>
                  <div>
                    <MessageCircle />
                  </div>
                  <span id="numy">{'Numy'}</span>
                </div>
              </div>
            </Nav.Item>
            :
            <MynuTooltip
              description={
                hasQuestionnaireData() && !resultsAvailable()
                  ? messages.NUMY_DISABLED_NEED_RESULTS_MSG
                  : !hasQuestionnaireData() ? messages.NUMY_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                    : messages.OUT_OF_SERVICE_MSG
              }
              position='bottom'
            >
              <Nav.Item className={`navbar-link disabled`}>
                <div className='d-flex flex-column align-items-center'>
                  <div>
                    <Lock />
                  </div>
                  <span className='disabled-link'>{'Numy'}</span>
                </div>
              </Nav.Item>
            </MynuTooltip>
          }

          {resultsAvailable() && !RESULTS_DISABLED ?
            <Nav.Item>
              <div
                id={"results"}
                onClick={() => navigate('/results')}
                className={`navbar-link ${location.pathname === '/results' ? 'active-navbar-link' : ''}`}
              >
                <div className='d-flex flex-column align-items-center'>
                  <div>
                    <Dna />
                  </div>
                  <span>{'Genética'}</span>
                </div>
              </div>
            </Nav.Item>
            :
            <MynuTooltip
              description={!resultsAvailable()
                ? messages.NEED_RESULTS_MSG
                : messages.OUT_OF_SERVICE_MSG
              }
              position='bottom'
            >
              <Nav.Item className={`navbar-link disabled`}>
                <div className='d-flex flex-column align-items-center'>
                  <div>
                    <Lock />
                  </div>
                  <span className='disabled-link'>Genética</span>
                </div>
              </Nav.Item>
            </MynuTooltip>
          }
          <Nav.Item onClick={() => handleSelectRoute('me')} className={`navbar-link ${location.pathname.includes('settings') ? 'active-navbar-link' : ''}`}>
            <div className='more-options-button'>
              <div>
                <User />
              </div>
              <span>Yo</span>
            </div>
          </Nav.Item>
        </Nav>
      </Navbar>

      <Offcanvas show={showCanvas} onHide={() => setShowCanvas(!showCanvas)} placement={'bottom'} className='offcanvas-navbar'>
        {
          offCanvasContent.map(c =>
            c.id === selectedOffCanvas ?
              <>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    {c.header}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='d-flex gap-1 flex-column align-items-baseline justify-content-evenly'>
                  {c.content}
                </Offcanvas.Body>
              </>
              : null
          )
        }
      </Offcanvas>

      <Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: 'white' }} className='navbar-top'>
        <Container className='justify-content-center position-relative'>
          {!(location.pathname === '/') && !(location.pathname.includes('results')) &&
            <div
              onClick={() => navigate(-1)}
              className='back-button-navbar d-flex justify-content-center align-items-center d-lg-none'
            >
              <ArrowLeft />
            </div>
          }

          <Navbar.Brand>
            <div
              id={"home"}
              onClick={() => navigate('/')}
            >
              <img className="ms-auto" src={NavbarLogo} alt="" />
            </div>
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto mx-auto">
              <div
                id={"home"}
                onClick={() => navigate('/')}
                className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/' && 'active-navbar-link'}`}
              >
                Inicio
              </div>
              <span className="d-flex align-items-center justify-content-start">
                <span
                  className="d-inline-block"
                >
                  {hasQuestionnaireData() && resultsAvailable() && !DIETS_DISABLED ?
                    <Nav className="d-flex align-items-center d-none d-lg-flex">
                      <NavDropdown
                        className={`navbar-dropdown ${location.pathname.includes('/diets/') ? 'active-navbar-link' : ''}`}
                        id="collapsible-nav-dropdown-diets"
                        title={<span className='font-avenir-next'>Nutrición</span>}
                      >
                        <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                          <Nav.Item className="py-1">
                            <div
                              id={"diet-menu"}
                              onClick={() => navigate('/diets/menu')}
                              className={`navbar-links ${location.pathname.includes('menu') ? 'active-navbar-link' : ''}`}
                            >
                              My Menú
                            </div>
                          </Nav.Item>
                        </NavDropdown.Item>
                        <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                          <Nav.Item className="py-1">
                            <div
                              id={"diet-guide"}
                              onClick={() => navigate('/diets/guide')}
                              className={`navbar-links ${location.pathname.includes('guide') ? 'active-navbar-link' : ''}`}
                            >
                              My Pauta <span style={{ fontSize: '0.7rem', marginLeft: '0.5rem' }}>(New)</span>
                            </div>
                          </Nav.Item>
                        </NavDropdown.Item>
                        <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                          <Nav.Item className='py-1'>
                            <div
                              id={"diet-summary"}
                              onClick={() => navigate('/diets/summary')}
                              className={`navbar-links ${location.pathname.includes('summary') ? 'active-navbar-link' : ''}`}
                            >
                              Guía Nutricional
                            </div>
                          </Nav.Item>
                        </NavDropdown.Item>

                        <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                          <Nav.Item className='py-1'>
                            <div
                              id={"diet-portions"}
                              onClick={() => navigate('/diets/portions')}
                              className={`navbar-links ${location.pathname.includes('portions') ? 'active-navbar-link' : ''}`}
                            >
                              Glosario Porciones
                            </div>
                          </Nav.Item>
                        </NavDropdown.Item>

                      </NavDropdown>
                    </Nav>
                    :
                    <MynuTooltip
                      description={
                        hasQuestionnaireData() && !resultsAvailable()
                          ? messages.DIETS_DISABLED_NEED_RESULTS_MSG
                          : !hasQuestionnaireData() ? messages.DIETS_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                            : messages.OUT_OF_SERVICE_MSG
                      }
                      position='bottom'
                    >
                      <div className='nav-link mx-4 px-0 navbar-links'>
                        <span className='disabled-link'>Nutrición</span>
                        <img src={LockIcon} alt="" className="ms-3" />
                      </div>
                    </MynuTooltip>}
                </span>
              </span>

              <span className="d-flex align-items-center justify-content-start">
                <span className="d-inline-block" >
                  {hasQuestionnaireData() && resultsAvailable() && !NUMY_DISABLED ?
                    <div
                      id={"numy"}
                      onClick={() => navigate('/chat')}
                      className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/chat' && 'active-navbar-link'}`}
                    >
                      <span id="numy">{'Numy (Beta)'}</span>
                    </div>
                    :
                    <MynuTooltip
                      description={
                        hasQuestionnaireData() && !resultsAvailable()
                          ? messages.NUMY_DISABLED_NEED_RESULTS_MSG
                          : !hasQuestionnaireData() ? messages.NUMY_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                            : messages.OUT_OF_SERVICE_MSG
                      }
                      position='bottom'
                    >
                      <div className='nav-link mx-4 px-0 navbar-links'>
                        <span className='disabled-link'>{'Numy (Beta)'}</span>
                        <img src={LockIcon} alt="" className="ms-3" />
                      </div>
                    </MynuTooltip>
                  }
                </span>
              </span>

              <span className="d-flex align-items-center justify-content-start">
                <span className="d-inline-block" >
                  {resultsAvailable() && !RESULTS_DISABLED ?
                    <div
                      id={"results"}
                      onClick={() => navigate('/results')}
                      className={`nav-link mx-4 px-0 navbar-links ${location.pathname.includes('/results') && 'active-navbar-link'}`}
                    >
                      <span>{'Genética'}</span>
                    </div>
                    :
                    <MynuTooltip
                      description={!resultsAvailable()
                        ? messages.NEED_RESULTS_MSG
                        : messages.OUT_OF_SERVICE_MSG
                      }
                      position='bottom'
                    >
                      <div className='nav-link mx-4 px-0 navbar-links'>
                        <span className='disabled-link'>Genética</span>
                        <img src={LockIcon} alt="" className="ms-3" />
                      </div>
                    </MynuTooltip>
                  }
                </span>
              </span>

              <span className="d-flex align-items-center justify-content-start">
                <span className="d-inline-block" >
                  <div
                    id={"questionnaire"}
                    onClick={() => navigate('/questionnaire')}
                    className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/questionnaire' && 'active-navbar-link'} ${!isSubscriptionBanner && 'disabled-link'}`}
                  >
                    <span>Cuestionario</span>
                  </div>
                </span>
              </span>
            </Nav>
            <Nav className="d-flex align-items-center d-none d-lg-flex">
              <img src={MynuLogo} className="mx-2" alt="" width={32} height={32} />
              <NavDropdown
                className="navbar-dropdown"
                id="collapsible-nav-dropdown-user"
                title={`${currentUser?.givenName} ${currentUser?.familyName}`}
              >
                <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                  <div
                    id={"profile"}
                    onClick={() => navigate('/settings')}
                    className={`navbar-links`}
                  >
                    Mi Perfil
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={signOff} className="py-1 navbar-dropdown-item active">
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>

  );
}

export default AppNavbar;