import { StateCreator } from 'zustand';
import { ResultsStore } from '../interfaces/results';
import { AppState } from '../interfaces';
import { GeneticResults } from '../../models';

export const createResultsSlice: StateCreator<
  AppState,
  [],
  [],
  ResultsStore
> = (set, get) => ({
  resultsData: null,
  setResultsData: (r: GeneticResults | null) => set(() => ({ resultsData: r })),
  isLoadingResults: false,
  setIsLoadingResults: (l: boolean) => set(() => ({ isLoadingResults: l })),
  resultMealsRestrictions: { allowed: [], reduced: [], avoided: [] },
  setResultMealsRestrictions: (r: string[][]) => set(() => ({ resultMealsRestrictions: { allowed: r[0], reduced: r[1], avoided: r[2] } })),
  hasResults: () => {
    const r = get().resultsData;
    return resultsAvailable(r);
  },
  getResultsStatus: () => {
    const r = get().resultsData;
    return resultsAvailable(r) ? r.status : 'unavailable';
  }
});

const resultsAvailable = (r: GeneticResults | null): r is GeneticResults => {
  return !!r
}