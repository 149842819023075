import './UGRSBarComponent.scss';

interface UGRSBarProps {
  value: number;
  bounds: number[];
  label1: JSX.Element;
  label2: JSX.Element;
}

export default function UGRSBarComponent(props: UGRSBarProps) {
  const { value, label1, label2, bounds } = props;

  const sanitizedValue = value > bounds[1] ? bounds[1] : value < bounds[0] ? bounds[0] : value;

  const p = 100 * (sanitizedValue - bounds[0]) / (bounds[1] - bounds[0]);

  const ugrsPositive = p - 50 > 0;
  const barColor = p - 50 > 0 ? '#33B591' : '#FE5133';

  return (
    <div className="ugrs-bar-container">
      <div className="ugrs-background">
        <div className="v-line" />
        <div className="ugrs-fill"
          style={{
            "--ugrs-fill-color": barColor,
            "--ugrs-fill-width": `${Math.abs(50 - p)}%`,
            
            borderRadius: ugrsPositive ? '0 25px 25px 0' : '25px 0 0 25px',

            transform: ugrsPositive ? 'translateX(0)' : 'translateX(-100%)',
            transformOrigin: ugrsPositive ? 'left' : 'right',

          } as React.CSSProperties}
        />
      </div>

      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
        {label1}
        {label2}
      </div>
    </div>
  )
}