
import React, { useMemo, useState } from 'react';

import { recommendationNames } from '../../pages/results/ResultsComponentsModel';

import './FoodTagsRecommendator.scss'
import { Recommendation } from '../../pages/results/result-constants';
import { useStateStore } from '@/store/store';
import { useNavigate } from 'react-router';
import { DashCircleFill, PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';

interface FoodTagsRecommendatorProps {
  prefer: string | string[] | null;
  avoid: string | string[] | null;
  reduce: string | string[] | null;

  navigateTo?: string,
  singleColumnRecommendation?: boolean
}

export default function FoodTagsRecommendator(props: FoodTagsRecommendatorProps) {
  const { prefer, avoid, reduce, navigateTo, singleColumnRecommendation } = props;

  const navigate = useNavigate();

  const store = useStateStore();
  const { foodTags } = store;

  const [maxArraysize, setMaxArraySize] = useState(-1);
  const [isString, setIsString] = useState(false);

  const recommendations = useMemo(() => {
    const r = [
      {
        id: Recommendation.PREFER,
        list: prefer,
        active: (prefer && prefer.length > 0) ?? false,
        name: recommendationNames[Recommendation.PREFER],
        color: '#33B591',
        Icon: PlusCircleFill
      },
      {
        id: Recommendation.REDUCE,
        list: reduce,
        active: false,
        name: recommendationNames[Recommendation.REDUCE],
        color: '#FF9D00',
        Icon: DashCircleFill
      },
      {
        id: Recommendation.AVOID,
        list: avoid,
        active: false,
        name: recommendationNames[Recommendation.AVOID],
        color: '#FE5133',
        Icon: XCircleFill
      }
    ]

    // check if recommendation is a string, only for predisposition == low
    r.forEach(r => setIsString(typeof r.list === 'string'))

    setMaxArraySize(Math.max(prefer?.length ?? 0, reduce?.length ?? 0, avoid?.length ?? 0));

    return r;

  }, [prefer, avoid, reduce])

  const handleNavigate = (advice: string) => {
    if (!navigateTo) return;

    navigate(navigateTo + advice)
  }

  return (
    <div className="food-tags-recommendator-container">
      {isString ?
        <div className='d-flex jusify-content-center'>
          <div className='mx-auto'>
            {prefer}
          </div>
        </div>
        : recommendations.some(r => r.list !== null) ?
          <>
            <div className="food-tags-box"
              style={{
                maxHeight: maxArraysize > 7 ? '300px' : undefined
              }}
            >
              {recommendations.map(r =>
                r.list && r.list.length > 0 ?
                  <div className={`ftr`}
                    key={`ftr-item-${r.id}`}
                    style={{
                      '--ftr-element-color': r.color,
                    } as React.CSSProperties}
                  >
                    <div className='d-flex flex-column flex-md-row justify-content-center align-items-center gap-1'>
                      <div className='custom-pill header'>
                        {r.name}
                      </div>
                      <r.Icon color={r.color} size={'1.5rem'} />
                    </div>

                    <div className="pills"
                      style={{
                        '--grid-repeat': singleColumnRecommendation ? 1 : 2,
                      } as React.CSSProperties}
                    >
                      {typeof r.list === 'object' ?
                        r.list.map((pill, i) =>
                          <div className='custom-pill point'
                            key={`ftr-pill-${r.id}-${i}-${pill.replaceAll(' ', '_')}`}
                            onClick={() => handleNavigate(r.id
                              + (foodTags && foodTags[pill] ? `/${foodTags[pill]}` : '')
                            )}
                          >
                            <div>
                              {foodTags && foodTags[pill] ? foodTags[pill] : pill}
                            </div>
                          </div>
                        )
                        : null
                      }
                    </div>
                  </div>
                  : null
              )}
            </div>
          </>
          : null
      }
    </div>
  )
}

