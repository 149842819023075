import React, { useEffect, useMemo, useState } from "react";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadUserInfo } from "@/store/loader";
import { useMobileOrientation } from "react-device-detect";

import Badge from "react-bootstrap/esm/Badge";
import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

import ButtonBadgeComponent from "@/components/ButtonBadge/ButtonBadge";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";
import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import MainLayout from "@/components/MainLayout/MainLayout";
import StopLight from "@/components/StopLight/StopLight";
import { LoadingPage } from "@/router/LoadingPage";
import CategoryHeader from "../CategoryHeaderComponent";
import ModalToQuestionnaire from "./components/ModalToQuestionnaire";
import { ButtonsArrayComponent } from "./components/ButtonsArrayComponent";
import TipsComponent from "./components/TipsComponent";
import MainSubcategoryCard from "./components/MainSubcategoryCard";
import SubcatCard from "./components/SubcatCard";

import bgSrc from '@/assets/results/wellness/images/wellness.webp';

import { ReactComponent as Polymer } from '@/assets/results/wellness/icons/polymer.svg';
import { ReactComponent as Migraine } from '@/assets/results/wellness/icons/migraine.svg';

import { WellnessDescription } from "./WellnessDescription";
import { predispositionValues } from "../../ResultsComponentsModel";

import { getCategoryFromResults, replacePRSOR } from "../../ResultPage";
import {
  CategoryIds,
  PredispositionIds,
  SubcategoryIds,
} from "../../result-constants";

import '../../GeneticResults.scss';
import './WellnessComponent.scss';
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";
import { isEmptyOrUndefined } from "@/utils";

export default function WellnessComponent() {

  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults, resultsData, hasQuestionnaireData, questionnaireData } = store;

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.WELLNESS), [resultsData]);

  const migrainesSubcagegoryId = useMemo(() => {
    if (hasQuestionnaireData()) {
      return questionnaireData?.genderId === 'masculine' ?
        SubcategoryIds.WELLNESS_MIGRAINES_MEN
        : SubcategoryIds.WELLNESS_MIGRAINES_WOMEN
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireData])

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const top = WellnessDescription.polygons.top;
  const mid = WellnessDescription.polygons.mid;
  const bottom = WellnessDescription.polygons.bottom;

  const [isMidCardOpen, setIsMidCardOpen] = useState(false);
  const [isTopCardOpen, setIsTopCardOpen] = useState(false);


  return (
    <>
      <ModalToQuestionnaire show={!hasQuestionnaireData()} />

      <MainLayout>
        <div className="category-container wellness-component-container"
          style={{
            '--bg-image-src': `url("${bgSrc}")`,
            position: 'relative'
          } as React.CSSProperties}
        >
          <CategoryHeader title={WellnessDescription.name} />

          {hasResults() && resultsState && migrainesSubcagegoryId !== null ?
            <>
              {/* Mobile view */}
              <Container className="d-block d-lg-none px-0 subcategories-cards-container">
                {resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1 !== null
                  && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition !== null
                  && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].percentile !== null ?
                  <Card className="pb-0 sbcat-card pentagon" style={{ boxShadow: 'none' }}>
                    <Card.Header className="d-flex flex-column justify-content-center">
                      <div className="d-flex align-items-center">
                        <div>{top.name}</div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={top.id} >
                              {top.tooltip.length > 0 ?
                                top.tooltip.map((t, i) => <p key={`tooltip-wellness-${i}`}>{t}</p>)
                                : null}
                            </Tooltip>
                          }
                        >
                          <div className="sbcat-tooltip">
                            <div> ? </div>
                          </div>
                        </OverlayTrigger>
                      </div>

                      <ButtonBadgeComponent size={4} round noInfo content={<Polymer />} />

                      <div className="percentile-bar-container">
                        {top.prsFill ?
                          <div className="text-center">
                            <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                            <CustomSliderComponent
                              percent={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].percentile}
                              intervals={['Menor', 'Promedio', 'Mayor']}
                              intervalsDescriptions={[
                                'Tienes menos riesgo que la población general',
                                'Tu riesgo es similar al de la población general',
                                'Tienes más riesgo que la población general'
                              ]}
                            />
                          </div>
                          :
                          <div>
                            <div className="text-center" style={{ fontSize: 'medium' }}>
                              <strong>
                                {'Actividad de enzima DAO'}: {' '}
                                <span
                                  style={{
                                    color: predispositionValues[resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition].textColor2
                                  }}
                                >
                                  {''.replace('', predispositionValues[resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition].name)}
                                </span>
                              </strong>
                            </div>
                            <StopLight
                              colors={['#34B692', '#3900B3', '#FE5133']}
                              descriptions={[]}
                              titles={
                                Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].name)
                              }
                              changable={false}
                              activeIndex={predispositionValues[resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition].id}
                            />
                          </div>
                        }
                      </div>

                    </Card.Header>

                    <Card.Body className="pb-2 d-flex flex-column justify-content-center align-items-center gap-2">
                      <div>
                        <CollapseIcon
                          open={isTopCardOpen}
                          setOpen={() => setIsTopCardOpen(!isTopCardOpen)}
                          title={
                            <h4 className="text-center mt-3"><Badge pill>{top.pill}</Badge></h4>
                          }
                          iconSize={1.5}
                          elements={
                            <div className="d-flex flex-column justify-content-center">
                              <div className="prs-text align-self-center"
                                dangerouslySetInnerHTML={{
                                  __html: replacePRSOR(top.replacePRSText,
                                    resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1,
                                    resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1)
                                }}
                              />

                              <ButtonsArrayComponent
                                subcategoryId={top.id}
                                buttons={top.buttonArray}
                                predisposition={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition}
                                prs={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1}
                              />
                              {
                                !isEmptyOrUndefined(resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown, 'arr')
                                  && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown!.length > 0 ?
                                  <div className="info-container"
                                    style={{
                                      margin: '1rem',
                                    } as React.CSSProperties}
                                  >
                                    <ResponsiveCard
                                      description=""
                                      subtitle="Gen"
                                      examples={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown!.map(g => g.gene_name)}
                                    />
                                  </div>
                                  : null
                              }
                            </div>
                          }
                        >
                          <div style={{ margin: '0 2rem 1rem' }} > {top.description} </div>
                        </CollapseIcon>
                      </div>
                    </Card.Body>
                  </Card>
                  : null
                }
                {resultsState[migrainesSubcagegoryId].prs_to_1 !== null
                  && resultsState[migrainesSubcagegoryId].predisposition !== null
                  && resultsState[migrainesSubcagegoryId].percentile !== null ?
                  <Card className={`sbcat-card poly`}
                    style={{
                      boxShadow: 'none',
                      padding: isTopCardOpen ? '1rem 0 0' : undefined,
                      '--top-arch-percentage': `${(isTopCardOpen ? 5 : 1.5) + (isMidCardOpen ? 0.5 : 3.5)}%`,
                    } as React.CSSProperties}
                  >
                    <Card.Body className="pb-2 d-flex flex-column justify-content-center  gap-2">
                      <div className="card-header mt-4">
                        <div>{mid.name}</div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={mid.id[0]} >
                              {mid.tooltip.length > 0 ?
                                mid.tooltip.map((t, i) => <p key={`tooltip-wellness-${i}`}>{t}</p>)
                                : null}
                            </Tooltip>
                          }
                        >
                          <div className="sbcat-tooltip">
                            <div> ? </div>
                          </div>
                        </OverlayTrigger>
                      </div>

                      <div className="align-self-center">
                        <ButtonBadgeComponent size={4} round noInfo content={<Migraine />} />
                      </div>

                      {resultsState[migrainesSubcagegoryId].percentile !== null ?
                        <div className="percentile-bar-container">
                          {mid.prsFill ?
                            <div className="text-center">
                              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                              <CustomSliderComponent
                                percent={resultsState[migrainesSubcagegoryId].percentile}
                                intervals={['Menor', 'Promedio', 'Mayor']}
                                intervalsDescriptions={[
                                  'Tienes menos riesgo que la población general',
                                  'Tu riesgo es similar al de la población general',
                                  'Tienes más riesgo que la población general'
                                ]}
                              />
                            </div>
                            :
                            <div className="text-center" style={{ fontSize: 'medium' }}>
                              <strong>
                                {'Predisposición'}: {' '}
                                <span
                                  style={{
                                    color: predispositionValues[resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition].textColor2
                                  }}
                                >
                                  {''.replace('', predispositionValues[resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition].name)}
                                </span>
                              </strong>

                              <StopLight
                                colors={['#34B692', '#3900B3', '#FE5133']}
                                descriptions={[]}
                                titles={
                                  Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].name)
                                }
                                changable={false}
                                activeIndex={predispositionValues[resultsState[migrainesSubcagegoryId].predisposition].id}
                              />
                            </div>
                          }
                        </div>
                        : null
                      }

                      <CollapseIcon
                        open={isMidCardOpen}
                        setOpen={() => setIsMidCardOpen(!isMidCardOpen)}
                        title={
                          <h4 className="text-center mt-3"><Badge pill>{mid.pill}</Badge></h4>
                        }
                        iconSize={1.5}
                        elements={
                          <div>
                            <ButtonsArrayComponent
                              subcategoryId={migrainesSubcagegoryId}
                              buttons={mid.buttonArray}
                              predisposition={resultsState[migrainesSubcagegoryId].predisposition}
                              prs={resultsState[migrainesSubcagegoryId].prs_to_1}
                            />
                            {
                              !isEmptyOrUndefined(resultsState[migrainesSubcagegoryId].gene_breakdown, 'arr')
                                && resultsState[migrainesSubcagegoryId].gene_breakdown!.length > 0 ?
                                <div className="info-container"
                                  style={{
                                    margin: '1rem auto',
                                    padding: '0.5rem'
                                  } as React.CSSProperties}
                                >
                                  <ResponsiveCard
                                    description=""
                                    subtitle="Gen"
                                    examples={resultsState[migrainesSubcagegoryId].gene_breakdown!.map(g => g.gene_name)}
                                  />
                                </div>
                                : null
                            }
                          </div>
                        }
                      >
                        <div
                          style={{ margin: '0 2rem' }}
                          dangerouslySetInnerHTML={{
                            __html: replacePRSOR(
                              mid.description,
                              resultsState[migrainesSubcagegoryId].prs_to_1,
                              resultsState[migrainesSubcagegoryId].prs_to_1
                            )
                          }}
                        />
                      </CollapseIcon>
                    </Card.Body>
                  </Card>
                  : null
                }

                {
                  resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1 !== null &&
                    resultsState[migrainesSubcagegoryId].prs_to_1 !== null ?
                    <Tips isMidCardOpen={isMidCardOpen} tips={bottom.tips} poly />
                    : null
                }

              </Container>

              {/* Desktop View */}
              <Container
                className="d-none d-md-block px-0 subcategories-cards-container grid-cards-container"
              >
                <Card className="sbcat-card grid-cards-container">
                  {resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1 !== null
                    && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition !== null
                    && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].percentile !== null ?

                    <MainSubcategoryCard
                      title='Actividad de enzima DAO'
                      sbcatInfo={resultsState[SubcategoryIds.WELLNESS_HISTAMINE]}
                      Icon={Polymer}
                      prsFill={top.prsFill}
                      tooltip={top.tooltip}
                      pill={top.pill}
                      description={top.description}
                      replacePRSText={top.replacePRSText}
                      buttonArray={top.buttonArray}
                      collapseOpen={isTopCardOpen}
                      predisposition={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].predisposition}
                    />
                    : null
                  }
                  {
                    resultsState[migrainesSubcagegoryId].prs_to_1 !== null
                      && resultsState[migrainesSubcagegoryId].predisposition !== null
                      && resultsState[migrainesSubcagegoryId].percentile !== null ?
                      <MainSubcategoryCard
                        title='Predisposición'
                        sbcatInfo={resultsState[migrainesSubcagegoryId]}
                        Icon={Migraine}
                        prsFill={mid.prsFill}
                        tooltip={mid.tooltip}
                        pill={mid.pill}
                        description={mid.description}
                        replacePRSText={mid.replacePRSText ?? ''}
                        buttonArray={mid.buttonArray}
                        collapseOpen={isTopCardOpen}
                        predisposition={resultsState[migrainesSubcagegoryId].predisposition}
                      />
                      : null
                  }
                  {
                    resultsState[SubcategoryIds.WELLNESS_HISTAMINE].prs_to_1 !== null &&
                      resultsState[migrainesSubcagegoryId].prs_to_1 !== null ?
                      <div style={{ gridColumn: '1 / 3' }}>
                        <CollapseIcon
                          open={isTopCardOpen}
                          setOpen={setIsTopCardOpen}
                          iconSize={2}
                        >
                          {
                            !isEmptyOrUndefined(resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown, 'arr')
                              && resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown!.length > 0 ?
                              <div className="info-container"
                                style={{
                                  margin: '1rem auto',
                                  width: '50%',
                                } as React.CSSProperties}
                              >
                                <ResponsiveCard
                                  description=""
                                  subtitle="Gen"
                                  examples={resultsState[SubcategoryIds.WELLNESS_HISTAMINE].gene_breakdown!.map(g => g.gene_name)}
                                />
                              </div>
                              : null
                          }
                          <Tips tips={bottom.tips} />
                        </CollapseIcon>
                      </div>
                      : null
                  }
                </Card>
              </Container>

              <Container className="px-0 subcategories-cards-container grid-cards-container">
                {WellnessDescription.subcategoryCards.map((c, i) => {
                  const { id, name, tooltip, buttonsArray, examples } = c;
                  const sbcatInfo = resultsState[id];
                  return (
                    sbcatInfo.prs_to_1 !== null
                      && sbcatInfo.predisposition !== null
                      && sbcatInfo.percentile !== null ?
                      <SubcatCard
                        key={`wellness-${id}-${i}`}
                        id={id}
                        name={name}
                        tooltip={tooltip}
                        buttonsArray={buttonsArray}
                        examples={examples}
                        sbcatInfo={sbcatInfo}
                      />
                      : null)
                })}
              </Container>
            </>
            :
            <LoadingPage />
          }
        </div>
      </MainLayout >
    </>
  )
}

interface TipsProps {
  isMidCardOpen?: boolean;
  poly?: boolean;
  tips: {
    id: string,
    title: string;
    description: string;
  }[]
}

const Tips = (props: TipsProps) => {
  const { isMidCardOpen, tips, poly } = props;
  const { isLandscape } = useMobileOrientation();

  return (
    <Card
      className={`sbcat-card ${poly ? 'pentagon-complement pt-5' : 'border-0'} ${isMidCardOpen ? 'open' : ''}`}
      style={{
        boxShadow: 'none',
        padding: !isLandscape ? '6rem 0 0' : undefined
      }}
    >
      <Card.Body>
        <div className="d-flex flex-row justify-content-center">
          <div>
            <ButtonBadgeComponent size={4} round noInfo content={<Polymer />} />
          </div>
          <div style={{ margin: '0 0 0 -5px' }}>
            <ButtonBadgeComponent size={4} round noInfo content={<Migraine />} />
          </div>
        </div>
        <TipsComponent buttons={tips} />
      </Card.Body>
    </Card>
  )
}

