import React, { useEffect, useMemo, useState } from "react";

import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import FoodTagsRecommendator from "@/components/FoodTagsRecommendator/FoodTagsRecommendator";
import MainLayout from "@/components/MainLayout/MainLayout";
import ResponsiveCard from "@/components/ResponsiveCard/ResponsiveCard";

import { CarbsDescription } from "./CarbsDescription";
import { SVGComponent } from "../../ResultsComponentsModel";

import '../../GeneticResults.scss';
import './CarbsComponent.scss';

import bgSrc from "@/assets/results/carbs/images/carbs.webp";
import { ReactComponent as GlucoseCollapseIcon } from "@/assets/results/carbs/icons/glucose-collapse-icon.svg";
import { ReactComponent as Glucometer } from "@/assets/results/carbs/icons/glucometer.svg";
import { ReactComponent as Carrot } from "@/assets/results/carbs/icons/carrot.svg";
import { ReactComponent as Bottle } from "@/assets/results/carbs/icons/bottle.svg";
import { ReactComponent as Pudding } from "@/assets/results/carbs/icons/pudding.svg";
import { ReactComponent as Walk } from "@/assets/results/carbs/icons/walk.svg";

import ButtonBadgeComponent from "@/components/ButtonBadge/ButtonBadge";
import ButtonWrapDescription from "@/components/ButtonWrapDescription/ButtonWrapDescription";
import { CategoryIds, Recommendation } from "../../result-constants";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { buildFoodTagArgumentsUrl, getCategoryFromResults, parseRecommendation, replacePRSOR } from "../../ResultPage";
import { loadUserInfo } from "@/store/loader";
import { LoadingPage } from "@/router/LoadingPage";
import CategoryHeader from "../CategoryHeaderComponent";
import CollapseIcon from "@/components/CollapseIcon/CollapseIcon";
import AccordionComponent from "@/components/AccordionComponent/AccordionComponent";
import { Egg } from "lucide-react";

const iconsArray = {
  'tip-1': Egg,
  'tip-2': Carrot,
  'tip-3': Bottle,
  'tip-4': Pudding,
  'tip-5': Walk,
}

export default function CarbsComponent() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults, resultsData } = store;

  const resultsState = useMemo(() => getCategoryFromResults(resultsData, CategoryIds.CARBOHYDRATES), [resultsData]);

  useEffect(() => {
    if (currentUser?.id) {
      if (!hasResults()) {
        loadUserInfo(store);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <MainLayout>
      <div className="category-container carbs-component-container"
        style={{
          '--bg-image-src': `url("${bgSrc}")`,
          position: 'relative'
        } as React.CSSProperties}
      >

        <CategoryHeader title={CarbsDescription.name} />

        {hasResults() && resultsState ?
          <>
            <Container className="px-0 subcategories-cards-container">
              {CarbsDescription.subcategories.map((sbcat, i) => {
                const {
                  id, name, tooltip, topcard,
                  collapse, bottomCard, recommendation
                } = sbcat;

                const subcatInfo = resultsState[id];

                return (
                  subcatInfo.prs_to_1 !== null
                    && subcatInfo.predisposition !== null
                    && subcatInfo.percentile !== null ?
                    <Card className="sbcat-card">
                      <Card.Header>
                        <div className="gap-3 d-flex flex-row align-items-center">
                          <div>
                            {name}
                          </div>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={id} style={{ width: '250px' }}>
                                {tooltip.length > 0 ?
                                  tooltip.map((t, i) => <p key={`tooltip-carbs-${i}`}>{t}</p>)
                                  : null}
                              </Tooltip>
                            }
                          >
                            <div className="sbcat-tooltip">
                              <div> ? </div>
                            </div>
                          </OverlayTrigger>
                        </div>

                        <div className="info-section">
                          <div className="percentile-bar-container">
                            <div className="text-center">
                              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
                              <CustomSliderComponent
                                size={'2rem'}
                                percent={subcatInfo.percentile}
                                intervals={['Menor', 'Promedio', 'Mayor']}
                                intervalsDescriptions={[
                                  'Tienes menos riesgo que la población general',
                                  'Tu riesgo es similar al de la población general',
                                  'Tienes más riesgo que la población general'
                                ]}
                              />
                            </div>
                          </div>
                          <div className="gap-4 d-flex flex-column">
                            <div className="gap-2 d-flex flex-row align-items-center align-self-center align-self-md-baseline">
                              <ButtonBadgeComponent
                                round
                                noInfo
                                size={2}
                                content={<Glucometer />}
                              />
                              <h4 className="mb-0"><Badge pill>{topcard.title}</Badge></h4>
                            </div>
                            <div
                              className="text-area"
                              dangerouslySetInnerHTML={{
                                __html: topcard.replacePRSText
                                  .map(e => replacePRSOR(e, subcatInfo.prs_to_1, subcatInfo.prs_to_1))
                                  .join(' ')
                              }}
                            />
                          </div>
                        </div>
                      </Card.Header>
                      <CollapseIcon
                        open={collapseOpen}
                        setOpen={setCollapseOpen}
                        iconSize={2}
                      >
                        <div>
                          <Card.Body>

                            <div className="buttons-accordion">
                              <div className="buttons">
                                <ButtonsArrayComponent
                                  buttons={topcard.buttonGroup}
                                />
                              </div>
                              <AccordionComponent>
                                <Accordion.Item eventKey={`${id}-${name}`} style={{ border: 'none !important' }}>
                                  <Accordion.Header>
                                    {collapse.title}
                                  </Accordion.Header>
                                  <Accordion.Body className="gap-3 d-flex flex-column justify-content-center align-items-center">
                                    <GlucoseCollapseIcon fontSize={12} />
                                    <div>
                                      {collapse.body}
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </AccordionComponent>
                            </div>

                            <div className="mt-4 d-flex flex-column">
                              <div className="mx-auto my-4" style={{ fontWeight: 'bold', fontSize: 'large' }}>
                                <span className="mynu-stars">Tips{' '}</span>
                                {bottomCard.header}
                              </div>
                              <ButtonWrapDescription
                                buttonSize={3}
                                buttons={bottomCard.body}
                                iconsArray={iconsArray}
                              />
                            </div>

                          </Card.Body>
                          <Card.Footer className="d-flex flex-column align-items-center">
                            <div>
                              <h4 className="m-3 text-center">
                                <Badge pill>
                                  <span className="px-3 py-2"> Recomendación Nutricional </span>
                                </Badge>
                              </h4>

                              <FoodTagsRecommendator
                                prefer={parseRecommendation(recommendation[subcatInfo.predisposition], Recommendation.PREFER)}
                                reduce={parseRecommendation(recommendation[subcatInfo.predisposition], Recommendation.AVOID)}
                                avoid={parseRecommendation(recommendation[subcatInfo.predisposition], Recommendation.REDUCE)}
                                navigateTo={
                                  buildFoodTagArgumentsUrl(
                                    CategoryIds.CARBOHYDRATES,
                                    id,
                                    subcatInfo.predisposition
                                  )
                                }
                                singleColumnRecommendation
                              />
                            </div>

                          </Card.Footer>
                        </div>
                      </CollapseIcon>
                    </Card>
                    : null)
              })}
            </Container>
          </>
          : <LoadingPage />
        }
      </div>
    </MainLayout>
  )
}

export interface ButtonForGroup {
  id: string,
  label: string,
  IconImage: SVGComponent,
  body: string[],
  examples: string[]
}

export const ButtonsArrayComponent = (
  props: {
    buttons: ButtonForGroup[]
  }
) => {

  const {
    buttons
  } = props;

  const [selectedBody, setSelectedBody] = useState<ButtonForGroup | null>(buttons[0]);

  return (
    <div className="mx-auto collapse-container d-flex flex-column justify-content-center">
      <div className="sbcat-buttons-array mx-auto">
        <ButtonGroup aria-label="sbcat-buttons-array">
          {buttons.map((b, i) =>
            <Button className={`${i === 0 ? 'first' : i === buttons.length - 1 ? 'third' : 'second'}`}
              active={selectedBody?.id === b.id}
              onClick={() => setSelectedBody(b)}
            >
              {b.label}
            </Button>
          )}
        </ButtonGroup>
      </div>
      <div className="mx-auto">
        {selectedBody ?
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="mx-auto">
              <selectedBody.IconImage />
            </div>

            <div className="mx-auto">
              <p className="mx-2 my-4 text-center">
                {selectedBody.body.join(' ')}
              </p>
            </div>
          </div>
          : null
        }
        <ResponsiveCard
          description=""
          subtitle="Genes"
          examples={selectedBody?.examples}
        />
      </div>
    </div>
  )

}