import React, { useEffect, useState } from "react";
import { fesNutrientReportType } from "@/models/fes-model";
import { truncateTo } from "@/utils";

import { adaptationLevels } from "../../constants";

import './MacroNutrientsChart.scss'
import { translateMacroToEs } from "@/utils/fes";

type MacronutrientsChartType = fesNutrientReportType & {
  level: adaptationLevels
}

const percentageGivenZeroHundred = (value: number, zeroPercent: number, hundredPercent: number): number => {
  const percentage = 100 * (value - zeroPercent) / (hundredPercent - zeroPercent);
  return percentage > 5 ? percentage : 5;
}

const percent = (x: number, l: number, u: number): { xPercent: number, lPercent: number, uPercent: number } => {
  const EPSILON = 0.01;

  let zeroPercent = 0;
  let hundredPercent = 0;

  if (x < l) {
    zeroPercent = x - (l - x) * EPSILON;
    hundredPercent = u * (1 + EPSILON);
  } else if (x < u) {
    zeroPercent = l * (1 - EPSILON);
    hundredPercent = u * (1 + EPSILON);
  } else {
    zeroPercent = l * (1 - EPSILON);
    hundredPercent = x + (x - u) * EPSILON;
  }

  zeroPercent = zeroPercent - 10;
  hundredPercent = hundredPercent + 10;

  const toReturn = {
    lPercent: percentageGivenZeroHundred(l, zeroPercent, hundredPercent),
    xPercent: percentageGivenZeroHundred(x, zeroPercent, hundredPercent),
    uPercent: percentageGivenZeroHundred(u, zeroPercent, hundredPercent)
  };
  return toReturn;
}

export const MacronutrientsChart = (props: MacronutrientsChartType) => {

  const { dailyLb, dailyUb, value, level, unit } = props;

  const [barColor, setBarColor] = useState<'#33B591' | '#FF474A' | '#FF8D1C'>('#33B591');

  useEffect(() => {
    switch (level) {
      case adaptationLevels.VL:
        setBarColor('#FF474A');
        break;
      case adaptationLevels.L:
        setBarColor('#FF8D1C');
        break;
      case adaptationLevels.M:
        setBarColor('#33B591');
        break;
      case adaptationLevels.H:
        setBarColor('#FF8D1C');
        break;
      case adaptationLevels.VH:
        setBarColor('#FF474A');
        break;
      default: break;
    }
  }, [level])



  const { xPercent, lPercent, uPercent } = percent(value, dailyLb, dailyUb);

  return (
    <div className="macro-chart">
      <div className="macro-chart-grades">
        <div
          className="item1"
          style={{
            width: `${lPercent}%`,
            "--after-content-item1": `'${Math.trunc(dailyLb)} ${translateMacroToEs(unit)}'`,
          } as React.CSSProperties}
        />
        <div className="item2"
          style={{
            width: `${uPercent - lPercent}%`
          } as React.CSSProperties}
        >
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="142" viewBox="0 0 12 142" fill="none">
              <path d="M6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667ZM6 130.667C3.05448 130.667 0.666667 133.054 0.666667 136C0.666667 138.946 3.05448 141.333 6 141.333C8.94552 141.333 11.3333 138.946 11.3333 136C11.3333 133.054 8.94552 130.667 6 130.667ZM5 6L5 136H7L7 6H5Z" fill="black" />
            </svg>
          </div>
        </div>
        <div className="item3"
          style={{
            "--before-content-item3": `'${Math.trunc(dailyUb)} ${translateMacroToEs(unit)}'`,
            width: `${lPercent}%`
          } as React.CSSProperties}
        />
        <div
          className="macro-chart-fill"
          style={{
            width: `${xPercent}%`,
            background: barColor,
            borderRadius: '18px',
            color: barColor,
            "--after-content": `'${truncateTo(value, 10).toLocaleString('es-CL')} ${translateMacroToEs(unit)}'`
          } as React.CSSProperties
          }
        />
      </div>
    </div>
  );
};