import { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

interface TipButton {
  id: string,
  title: string,
  description: string
}

interface TipsComponentProps {
  buttons: TipButton[]
}

export default function TipsComponent(props: TipsComponentProps) {
  const { buttons } = props;
  const [paneActive, setPaneActive] = useState<TipButton>(buttons[0]);

  return (
    <div className="tips-component" style={{ margin: '2rem 0' }}>
      <div className="text-center" style={{ margin: '2rem auto' }}>
        Estos <span className="mynu-stars"><strong>tips</strong></span> te ayudarán a disminuir el consumo de histamina, lo que podría mejorar tus síntomas de migraña.
      </div>
      <ButtonGroup className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
        {buttons.map((b, i) =>
          <Button
            key={`tip-button-${b.id}-${i}`}
            onClick={() => setPaneActive(b)}
            style={{ borderRadius: '18px', margin: '0 1rem' }}
            active={b.id === paneActive.id}
          >
            {b.title}
          </Button>
        )}
      </ButtonGroup>

      <div className="text-center my-3">
        {paneActive.description}
      </div>
    </div>
  )

}