import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import Button from "react-bootstrap/esm/Button";

import { ReactComponent as Skin } from "@/assets/results/allergies/icons/skin-barrier.svg";
import { ReactComponent as Immune } from "@/assets/results/allergies/icons/immunoregulation.svg";
import { ReactComponent as Adaptive } from "@/assets/results/allergies/icons/adaptive-system.svg";
import { ReactComponent as Others } from "@/assets/results/allergies/icons/others.svg";

import { ReactComponent as HealthyFood } from "@/assets/results/allergies/icons/healthy-food.svg";
import { ReactComponent as Microbiota } from "@/assets/results/allergies/icons/microbiota.svg";
import { ReactComponent as Pollution } from "@/assets/results/allergies/icons/pollution.svg";
import { ReactComponent as Tobacco } from "@/assets/results/allergies/icons/tobacco.svg";

import allergiesImg from "@/assets/results/allergies/images/allergies.webp";

import "./AllergiesDiagram.scss"
import { ArrowUpRight, QuestionCircleFill } from "react-bootstrap-icons";
import { allergiesDiagramIds, SVGComponent } from "../../../ResultsComponentsModel";
import React, { useMemo, useState } from "react";

type geneticIconType = {
  id: allergiesDiagramIds;
  name: string;
  IconObj: SVGComponent;
}

const geneticIcons: geneticIconType[] = [
  {
    id: allergiesDiagramIds.SKIN,
    name: 'Barreras de la piel',
    IconObj: Skin
  },
  {
    id: allergiesDiagramIds.IMMUNOREGULATION,
    name: 'Regulación del sistema Inmune',
    IconObj: Immune
  },
  {
    id: allergiesDiagramIds.ADAPTIVE,
    name: 'Sistema Adaptativo',
    IconObj: Adaptive
  },
  {
    id: allergiesDiagramIds.OTHERS,
    name: 'Otros mecanismos',
    IconObj: Others
  },
]

const environmentalIcons: geneticIconType[] = [
  {
    id: allergiesDiagramIds.HEALTHYFOOD,
    name: 'Comida Saludable',
    IconObj: HealthyFood
  },
  {
    id: allergiesDiagramIds.MICROBIOTA,
    name: 'Microbiota Intestinal',
    IconObj: Microbiota
  },
  {
    id: allergiesDiagramIds.POLLUTION,
    name: 'Polución del medioambiente',
    IconObj: Pollution
  },
  {
    id: allergiesDiagramIds.TOBACCO,
    name: 'Consumo del tabaco',
    IconObj: Tobacco
  },

]

interface AllergiesDiagramProps {
  multiplier: number,
  activeIds?: allergiesDiagramIds[],
  imgSrc?: string,
  tutorial?: boolean
}

export default function AllergiesDiagram(props: AllergiesDiagramProps) {
  const { activeIds, imgSrc, tutorial, multiplier } = props;

  const [tutorialActive, setTutorialActive] = useState(false);

  const sizeMultiplier = useMemo(() => {
    const x = (activeIds?.length ?? 1) * multiplier;
    return 1 / (1 + Math.exp(-x))
  }, [multiplier, activeIds])

  return (
    <div className="allergies-diagram-container">
      <div>
        <div className="left-buttons">
          {geneticIcons.map((o, i) => {
            const { id, name, IconObj } = o;
            return (
              <div
                className={`icon-container ${activeIds?.includes(id) ? 'selected' : ''}`}
                key={'allergies-diagram-icon-' + id + i}
              >
                {tutorial && i === 0 ?
                  <div className="tutorial-mode">
                    <Button className="tutorial-button" variant="primary" active={tutorialActive} onClick={() => setTutorialActive(!tutorialActive)}>
                      <span>Click acá! <ArrowUpRight size={'1rem'} className="mb-1 mx-2" /> </span>
                    </Button>

                    {tutorialActive ?
                      <div id={'allergies-diagram-tutorial-tp-1'} className="tutorial-tooltip-1">
                        Descubre qué significa cada ícono y ve qué factores son los
                        que se ven alterados por tu genética en cada alergia específica.
                      </div>
                      : null}

                    {tutorialActive ?
                      <div id={'allergies-diagram-tutorial-tp-2'} className="tutorial-tooltip-2">
                        Esto sugiere que la genética influye en el mecanismo que
                        podría favorecer el desarrollo de esa alergia.
                      </div>
                      : null}
                  </div>
                  : null}

                <IconObj />

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={'allergies-diagram-icon-tp' + id} className="tooltip-allergies">{name}</Tooltip>}
                >
                  <div className="details-button">
                    <QuestionCircleFill />
                  </div>
                </OverlayTrigger>
              </div>
            )
          })}
        </div>
        <div className="center-circles">
          <div className="left-circle"
            style={{
              '--size-multiplier': sizeMultiplier,
            } as React.CSSProperties}
          >
            <p>Factor</p>
            <p>genético</p>
          </div>
          <img
            src={imgSrc ?? allergiesImg}
            alt="lol"
          />
          <div className="right-circle">
            <p>Factor</p>
            <p>Ambiental</p>
          </div>
        </div>
        <div className="right-buttons">
          {environmentalIcons.map((o, i) => {
            const { id, name, IconObj } = o;
            return (
              <div
                className={`icon-container right`}
                key={'allergies-diagram-icon-' + id + i}
              >
                <IconObj />

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={'allergies-diagram-icon-tp' + id} className="tooltip-allergies">{name}</Tooltip>}
                >
                  <div className="details-button">
                    <QuestionCircleFill />
                  </div>
                </OverlayTrigger>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}