import Badge from "react-bootstrap/esm/Badge";
import Card from "react-bootstrap/esm/Card";
import Collapse from "react-bootstrap/esm/Collapse";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

import ButtonBadgeComponent from "@/components/ButtonBadge/ButtonBadge";
import CustomSliderComponent from "@/components/CustomSlider/CustomSlider";
import StopLight from "@/components/StopLight/StopLight";
import { ButtonForGroup, ButtonsArrayComponent } from "./ButtonsArrayComponent";

import { Subcategory } from "@/models";
import { PredispositionIds } from "@/pages/results/result-constants";
import { replacePRSOR } from "@/pages/results/ResultPage";
import { predispositionValues, subcategoryNames, SVGComponent } from "@/pages/results/ResultsComponentsModel";

type MainSubcategoryCardProps = {
  title: string;
  sbcatInfo: Subcategory;
  Icon: SVGComponent;
  prsFill: boolean;
  tooltip: string[];
  pill: string;
  description: string | null;
  replacePRSText: string;
  buttonArray: ButtonForGroup[];
  collapseOpen: boolean;
  predisposition: PredispositionIds;
}

export default function MainSubcategoryCard(props: MainSubcategoryCardProps) {
  const {
    title,
    sbcatInfo, description,
    prsFill, replacePRSText,
    predisposition,
    tooltip, pill,
    buttonArray,
    collapseOpen, Icon
  } = props;

  const predispositionId = predispositionValues[predisposition].id;

  return (
    <div className="pb-0 sbcat-card border-0" style={{ boxShadow: 'none' }}>
      <Card.Header className="d-flex flex-column justofy-content-center">
        <div className="d-flex align-items-center gap-2">
          <div>{subcategoryNames[sbcatInfo.subcategory_name]}</div>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={sbcatInfo.subcategory_name} >
                {tooltip.length > 0 ?
                  tooltip.map((t, i) => <p key={`tooltip-wellness-${i}`}>{t}</p>)
                  : null}
              </Tooltip>
            }
          >
            <div className="sbcat-tooltip">
              <div> ? </div>
            </div>
          </OverlayTrigger>
        </div>

        <ButtonBadgeComponent size={4} round noInfo content={<Icon />} />



        <div className="percentile-bar-container">
          {prsFill ?
            <div className="text-center">
              <div className="mb-2" style={{ fontSize: 'medium' }}><strong>Percentil</strong></div>
              <CustomSliderComponent
                percent={sbcatInfo.percentile}
                intervals={['Menor', 'Promedio', 'Mayor']}
                intervalsDescriptions={[
                  'Tienes menos riesgo que la población general',
                  'Tu riesgo es similar al de la población general',
                  'Tienes más riesgo que la población general'
                ]}
              />
            </div>
            :
            <div>
              <div className="text-center" style={{ fontSize: 'medium' }}>
                <strong>
                  {title}: {' '}
                  <span
                    style={{
                      color: predispositionValues[predisposition].textColor2
                    }}
                  >
                    {''.replace('', predispositionValues[predisposition].name)}
                  </span>
                </strong>
              </div>
              <StopLight
                colors={
                  Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].textColor2)
                }
                descriptions={[]}
                titles={
                  Object.keys(predispositionValues).map((k) => predispositionValues[k as PredispositionIds].name)
                }
                changable={false}
                activeIndex={predispositionId}
              />
            </div>
          }
        </div>
      </Card.Header>

      <Collapse in={collapseOpen}>
        <div>
          <Card.Body className="pb-2 d-flex flex-column justify-content-center gap-2">
            <div className="d-flex flex-column justify-content-center align-items-center">

              <h4><Badge pill>{pill}</Badge></h4>

              {!description ? null
                :
                <div
                  style={{ textAlign: 'justify', margin: '2rem' }}
                  dangerouslySetInnerHTML={{
                    __html: replacePRSOR(description, sbcatInfo.prs_to_1, sbcatInfo.prs_to_1)
                  }}
                />
              }

              {!replacePRSText ? null
                :
                <div className="prs-text"
                  dangerouslySetInnerHTML={{
                    __html: replacePRSOR(replacePRSText, sbcatInfo.prs_to_1, sbcatInfo.prs_to_1)
                  }}
                  style={{ textAlign: 'justify', margin: '0 2rem 2rem', fontSize: 'medium' }}
                />
              }

              <ButtonsArrayComponent
                subcategoryId={sbcatInfo.subcategory_name}
                buttons={buttonArray}
                predisposition={predisposition}
                prs={sbcatInfo.prs_to_1}
              />
            </div>

          </Card.Body>
        </div>
      </Collapse>

    </div>
  )
}