import { categoryNames, subcategoryNames } from "../../ResultsComponentsModel";

import {
  CategoryIds,
  PredispositionIds,
  Recommendation,
  SubcategoryIds,
} from "../../result-constants";

export const WellnessDescription = {
  id: CategoryIds.WELLNESS,
  name: categoryNames[CategoryIds.WELLNESS],
  polygons: {
    top: {
      id: SubcategoryIds.WELLNESS_HISTAMINE,
      name: subcategoryNames[SubcategoryIds.WELLNESS_HISTAMINE],
      prsFill: false,
      tooltip: [
        'La histamina naturalmente facilita la comunicación entre células, especialmente en el sistema de defensa.',
        'Su principal función es activar respuestas para que los órganos funcionen correctamente y combatir infecciones.',
        'Cuando esta se libera sin control, puede desencadenar reacciones alérgicas.',
      ],
      pill: '¿Cómo influye la genética?',
      description: 'Los niveles altos de histamina pueden deberse a la falta de la enzima DAO, encargada de descomponerla en el cuerpo, influenciada por factores genéticos, dieta y microbiota intestinal. El consumo excesivo de alimentos ricos en histamina y un metabolismo deficiente pueden causar intolerancia a esta molécula.',
      replacePRSText: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar alteraciones en las concentraciones de histamina.',
      buttonArray: [
        {
          id: 'button_predisposition',
          title: 'Síntomas de intolerancia',
          interpretation: {
            [PredispositionIds.LOW]: 'Tu genética no se relaciona con una disminución de la actividad de la enzima DAO, por lo tanto, no existe mayor riesgo genético de presentar intolerancia a la histamina.',
            [PredispositionIds.MEDIUM]: 'Presentas una reducción en la actividad de la enzima DAO, haciendo ineficiente el metabolismo de histamina y aumentando el riesgo a presentar síntomas de intolerancia. Consulta con un profesional en caso de tener sospecha de síntomas relacionados.',
            [PredispositionIds.HIGH]: 'Presentas una reducción en la actividad y expresión de la enzima DAO, haciendo ineficiente el metabolismo de histamina y aumentando el riesgo a presentar síntomas de intolerancia. Consulta con un profesional en caso de tener sospecha de síntomas relacionados.'
          },
          description: null,
        },
        {
          id: 'button_recommendation',
          title: 'Recomendación Nutricional',
          description: null,
          interpretation: null,
          recommendation: {
            [PredispositionIds.LOW]: 'Sigue disfrutando de una amplia variedad de alimentos, e intenta apuntar siempre hacia una alimentación natural 🥕🥩.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: [
                'alimentos_frescos',
                'carbohidratos_complejos',
                'carnes_blanca_magras_pescados_y_mariscos',
                'frutas_enteras',
                'verduras',
              ],
              [Recommendation.REDUCE]: [
                'alcohol',
                'alimentos_en_conserva',
                'alimentos_fermentados',
                'carnes_ahumadas',
                'frutas_citricas',
                'palta',
                'platano',
                'quesos_fermentados',
                'ultraprocesados',
              ],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: [
                'alimentos_frescos',
                'carbohidratos_complejos',
                'carnes_blanca_magras_pescados_y_mariscos',
                'frutas_enteras',
                'verduras'
              ],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]: [
                'alcohol',
                'alimentos_en_conserva',
                'alimentos_fermentados',
                'carnes_ahumadas',
                'frutas_citricas',
                'palta',
                'platano',
                'quesos_fermentados',
                'ultraprocesados',
              ],
            }
          }
        }
      ]
    },
    mid: {
      id: [SubcategoryIds.WELLNESS_MIGRAINES_MEN, SubcategoryIds.WELLNESS_MIGRAINES_WOMEN],
      name: subcategoryNames[SubcategoryIds.WELLNESS_MIGRAINES_MEN],
      prsFill: false,
      pill: '¿Cómo influye la genética?',
      tooltip: [
        'La acumulación de histamina puede causar migrañas al dilatar los vasos sanguíneos del cerebro, activar nervios que liberan sustancias inflamatorias y afectar la barrera protectora del cerebro, lo que intensifica el dolor de cabeza.'
      ],
      replacePRSText: null,
      description: '%[PRS_VALUE]% una persona sin predisposición genética para presentar migrañas asociadas a intolerancia a la histamina por una insuficiente degradación de histamina en el cuerpo. Consulta a un médico en caso de sospecha por síntomas asociados.',
      buttonArray: [
        {
          id: 'button_recommendation',
          title: 'Recomendación Nutricional',
          description: null,
          interpretation: null,
          recommendation: {
            [PredispositionIds.LOW]: 'Mantén una dieta equilibrada🥙, haz ejercicio🏃, gestiona el estrés, duerme bien💤 e hidrátate💧 para reducir el riesgo de migrañas.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: [
                'alimentos_frescos',
                'carbohidratos_complejos',
                'carnes_blanca_magras_pescados_y_mariscos',
                'frutas_enteras',
                'verduras',
              ],
              [Recommendation.REDUCE]: [
                'alcohol',
                'alimentos_en_conserva',
                'alimentos_fermentados',
                'carnes_ahumadas',
                'frutas_citricas',
                'palta',
                'platano',
                'quesos_fermentados',
                'ultraprocesados',
              ],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: [
                'alimentos_frescos',
                'carbohidratos_complejos',
                'carnes_blanca_magras_pescados_y_mariscos',
                'frutas_enteras',
                'verduras',
              ],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]: [
                'alcohol',
                'alimentos_en_conserva',
                'alimentos_fermentados',
                'carnes_ahumadas',
                'frutas_citricas',
                'palta',
                'platano',
                'quesos_fermentados',
                'ultraprocesados',
              ],
            }
          }
        }
      ]
    },
    bottom: {
      tips: [
        {
          id: 'tip-1',
          title: 'Tip 1',
          description: 'Los alimentos frescos tienen menor contenido de histamina. Prefiérelos por sobre alimentos recalentados y/o muy maduros.',
        },
        {
          id: 'tip-2',
          title: 'Tip 2',
          description: 'Si bien el alcohol cumple otras funciones dañinas para el cuerpo, los licores claros como el vodka tienen menos histamina y suelen ser mejor tolerados. La cerveza y el vino son altos en histamina y pueden empeorar los síntomas.'
        },
        {
          id: 'tip-3',
          title: 'Tip 3',
          description: 'El estrés contribuye al empeoramiento de la intolerancia a la histamina debido al estímulo de las células liberadoras de histamina.'
        }
      ]
    }
  },
  subcategoryCards: [
    {
      id: SubcategoryIds.WELLNESS_ALCOHOL,
      name: subcategoryNames[SubcategoryIds.WELLNESS_ALCOHOL],
      percentileORprs: 'prs',
      tooltip: [
        'Analiza tu tolerancia al alcohol, debido a tu predisposición de sentir los efectos secundarios de su acumulación en el cuerpo.'
      ],
      buttonsArray: [
        {
          id: 'effects',
          title: 'Efectos',
          interpretation: null,
          description: [
            '%[PRS_VALUE]% una persona sin predisposición genética para sentir rápidamente los efectos del exceso de alcohol.',
            'Algunos de estos son enrojecimiento, palpitaciones, náuseas, etc.'
          ],
        }
      ],
      examples: ['ADH1B',]
    },
    {
      id: SubcategoryIds.WELLNESS_TOBACCO,
      name: subcategoryNames[SubcategoryIds.WELLNESS_TOBACCO],
      percentileORprs: 'percentile',
      tooltip: [
        'Alteraciones genéticas pueden aumentar el metabolismo de la nicotina en el cuerpo, lo que se asocia a un mayor consumo de tabaco.',
        'Esto se debe a que necesitas fumar más para mantener los niveles de nicotina en el cuerpo.',
        'Además, considera genes que se asocian con el consumo de consumo diario de cigarros.'
      ],
      buttonsArray: [
        {
          id: 'effects',
          title: 'Efectos',
          description: null,
          interpretation: {
            [PredispositionIds.LOW]: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar un metabolismo acelerado de la nicotina. Tienes menos riesgo genético a ser fumador. ',
            [PredispositionIds.MEDIUM]: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar metabolismo acelerado de la nicotina. Tienes el mismo riesgo genético a ser fumador.',
            [PredispositionIds.HIGH]: '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar un metabolismo acelerado de la nicotina. Tienes más riesgo genético a ser fumador.',
          },
        },
        {
          id: 'recommendation',
          title: 'Recomendación Nutricional',
          interpretation: null,
          description: null,
          recommendation: {
            [PredispositionIds.LOW]: 'Aunque genéticamente no tienes una preferencia al tabaco, busca evitarlo, ya que podrías desarrollar una dependencia. Si fumas tabaco, procura aumentar tu consumo de vitamina C🍊🫑🥝 y antioxidantes🍊🫐🥬.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: [
                'alimentos_altos_en_antioxidantes',
                'frutas_citricas',
              ],
              [Recommendation.REDUCE]: [
                'tabaco'
              ],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: [
                'alimentos_altos_en_antioxidantes',
                'frutas_citricas',
              ],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]: [
                'tabaco'
              ]
            },
          }
        }
      ],
      examples: ['TAS2R38', 'CHRNA3', 'TMPRSS11E', 'CHRNA5', 'CYP2A6', 'UGT2B10', 'UGT2B10']
    },
    {
      id: SubcategoryIds.WELLNESS_IBD,
      name: subcategoryNames[SubcategoryIds.WELLNESS_IBD],
      percentileORprs: 'percentile',
      tooltip: [
        'Regulación del sistema inmune y de la respuesta inflamatoria asociada a la digestión, que, en conjunto con factores ambientales, pueden afectar tu barrera intestinal.',
        'Incluso puede desencadenar enfermedades como la de Crohn o colitis ulcerosa.'
      ],
      buttonsArray: [
        {
          id: 'effects',
          title: 'Efectos',
          description: [
            '%[PRS_VALUE]% una persona sin predisposición genética para desarrollar problemas de digestión.'
          ],
          interpretation: {
            [PredispositionIds.LOW]: 'No tiendes a presentar problemas digestivos, como hinchazón, estreñimiento y/o diarrea.',
            [PredispositionIds.MEDIUM]: 'Tiendes a un mayor riesgo de presentar problemas digestivos, como hinchazón, estreñimiento y/o diarrea.',
            [PredispositionIds.HIGH]: 'Tiendes a un mayor riesgo de presentar problemas digestivos, como hinchazón, estreñimiento y/o diarrea.',
          },
        },
        {
          id: 'recommendation',
          title: 'Recomendación Nutricional',
          interpretation: null,
          description: null,
          recommendation: {
            [PredispositionIds.LOW]: 'Recuerda potenciar alimentos de origen vegetal como frutas🍏🍌🥝, verduras🥬🥕, legumbres🫘🫛🍲 y frutos secos🥜🌰🫘 para mantener tu salud intestinal.',
            [PredispositionIds.MEDIUM]: {
              [Recommendation.PREFER]: [
                'aceite_de_oliva_o_canola',
                'frutas_y_verduras',
                'harinas_y_cereales_sin_gluten',
                'lacteos_con_o_sin_lactosa',
                'leche_de_almendras',
                'legumbres',
                'pan_de_masa_madre',
                'pescados_y_mariscos',
                'proteinas_vegetales',
                'proteinas_vegetales',
              ],
              [Recommendation.REDUCE]: [
                'azucar_y_productos_dulces',
                'carnes_rojas',
                'productos_de_trigo_procesados',
                'ultraprocesados',
              ],
              [Recommendation.AVOID]: null,
            },
            [PredispositionIds.HIGH]: {
              [Recommendation.PREFER]: [
                'aceite_de_oliva_o_canola',
                'frutas_y_verduras',
                'harinas_y_cereales_sin_gluten',
                'lacteos_con_o_sin_lactosa',
                'leche_de_almendras',
                'legumbres',
                'pan_de_masa_madre',
                'pescados_y_mariscos',
                'proteinas_vegetales',
                'proteinas_vegetales',
              ],
              [Recommendation.REDUCE]: null,
              [Recommendation.AVOID]: [
                'azucar_y_productos_dulces',
                'carnes_rojas',
                'productos_de_trigo_procesados',
                'ultraprocesados',
              ]
            },
          }
        }
      ],
      examples: ['IRF6', 'FAM171B, FOXP1', 'IMPG2', 'CCND3', 'CUL1', 'PLCG2', 'FERMT1',]
    }
  ]
}