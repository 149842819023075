import { useState } from "react";
import { useNavigateToTop } from "@/hooks/useNavigateTop";

import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Modal from "react-bootstrap/esm/Modal";
import Row from "react-bootstrap/esm/Row";

import { ArrowLeftIcon } from "lucide-react";

interface CategoryHeaderProps {
  title: string;
  warnOnExit?: boolean;
}

export default function CategoryHeader(props: CategoryHeaderProps) {
  const { title, warnOnExit } = props;
  const navigate = useNavigateToTop();

  const [show, setShow] = useState(false);

  const handleGoBack = () => {
    if (warnOnExit) {
      setShow(true);
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      <Container className='p-0 header-container'>
        <div className="arrow-back-button">
          <ArrowLeftIcon size={25} color="white" onClick={handleGoBack} cursor='pointer' />
        </div>
        <div className="title">
          {title}
        </div>
      </Container>

      <Modal size='lg' show={show} onHide={() => setShow(false)} className='container-exit-modal'>
        <Modal.Header className="header-exit-modal close" closeButton />
        <Modal.Body>
          <div className="exit-container d-flex center">
            <h1 className="text-title-exit-modal">
              ¿Estás seguro de salir sin guardar tus modificaciones?
            </h1>
            <Col>
              <Row>
                <Col md={6}>
                  <Button onClick={() => navigate(-1)} className="continue-button m-3" variant="outline-primary">Salir</Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}