import { DietPanelDescriptionType, DietPatternBaseOption, ToggleOptions } from "../../ResultsComponentsModel"

import { ReactComponent as BloodIcon } from '../../../../assets/results/diets-panel/icons/blood.svg';
import { ReactComponent as BreakfastIcon } from '../../../../assets/results/diets-panel/icons/breakfast.svg';
import { ReactComponent as FatIcon } from '../../../../assets/results/diets-panel/icons/fat.svg';
import { ReactComponent as SeedIcon } from '../../../../assets/results/diets-panel/icons/seed.svg';
import { ReactComponent as VegetableIcon } from '../../../../assets/results/diets-panel/icons/vegetable.svg';
import { ReactComponent as WheatIcon } from '../../../../assets/results/diets-panel/icons/wheat.svg';
import { ReactComponent as RBCellsIcon } from '../../../../assets/results/diets-panel/icons/red-blood-cells.svg';
import { ReactComponent as JellyIcon } from '../../../../assets/results/diets-panel/icons/jelly.svg';
import { DietsPanelCategoryIds, DietsPanelSubCategoryIds, DietsPanelTypeIds } from "../../result-constants";
import { Omega } from "lucide-react";


export const DietPanelDescriptions: DietPanelDescriptionType[] = [
  {
    parentId: DietsPanelCategoryIds.WEIGHT_LOSS,
    id: DietsPanelSubCategoryIds.WEIGHT_LOSS,
    title: 'Para bajar de peso',
    subtitle: ' ',
    description: 'Mientras mayor sea la eficiencia, mejor será la respuesta de tu organismo a ese patrón alimentario, para bajar de peso.',
    genes: ['MTNR1B', 'IRS1', 'IZUMO1', 'FTO', 'TCF7L2', 'TFAP2B', 'PPM1K', 'IL6-AS1'],
    buttons: [
      DietsPanelTypeIds.LOW_FAT,
      DietsPanelTypeIds.LOW_CARB,
      DietsPanelTypeIds.HIGH_PROTEIN,
      DietsPanelTypeIds.MEDITERRANEAN,
    ],
    toggles: null
  },
  {
    parentId: DietsPanelCategoryIds.HEALTH,
    id: DietsPanelSubCategoryIds.HEALTH_CARBOHYDRATE_METABOLISM,
    title: 'Para mejorar mi salud',
    subtitle: 'Control de glucosa',
    description: 'Mientras mayor sea la eficiencia, mejor será la respuesta de tu organismo a ese patrón alimentario, para controlar la glucosa en sangre.',
    genes: ['IRS1', 'FTO', 'GIPR', 'PPM1K', 'TCF7L2', 'CLOCK', 'DHCR7', 'TNF-α'],
    buttons: [
      DietsPanelTypeIds.LOW_FAT,
      DietsPanelTypeIds.LOW_CARB,
      DietsPanelTypeIds.HIGH_PROTEIN,
      DietsPanelTypeIds.HIGH_PUFA,
    ],
    toggles: null
  },
  {
    parentId: DietsPanelCategoryIds.HEALTH,
    id: DietsPanelSubCategoryIds.HEALTH_CARDIOVASCULAR,
    title: 'Para mejorar mi salud',
    subtitle: 'Cardiovascular',
    description: 'Mientras mayor sea la eficiencia, mejor será la respuesta de tu organismo a ese patrón alimentario, para mejorar tu salud cardiovascular.',
    genes: ['ZPR1', 'FTO', 'LIPC', 'APOE', 'CETP', 'APOC1', 'TCF7L2', 'TNF-α', 'MLXIPL',],
    buttons: [
      DietsPanelTypeIds.LOW_FAT,
      DietsPanelTypeIds.LOW_CARB,
      DietsPanelTypeIds.MEDITERRANEAN,
      DietsPanelTypeIds.HIGH_PUFA,
      DietsPanelTypeIds.HIGH_MUFA,
      DietsPanelTypeIds.LOW_GLUCEMIA,
    ],
    toggles: null
  },
  {
    parentId: DietsPanelCategoryIds.SUPPLEMENTS,
    id: DietsPanelSubCategoryIds.SUPPLEMENTS_CARDIOVASCULAR,
    title: 'Respuesta al omega 3',
    subtitle: 'Triglicéridos y colesterol',
    description: 'El suplemento de omega 3 podría ayudar a disminuir tus niveles de colesterol y triglicéridos en sangre. Descubre si tienes predisposición genética a responder de forma favorable a la suplementación de omega 3.',
    genes: ['APOE'],
    buttons: null,
    toggles: [
      {
        id: DietsPanelTypeIds.OMEGA3,
        title: 'Control de triglicéridos en sangre',
        IconElement: RBCellsIcon,
        message: {
          [ToggleOptions.YES]: 'Podrías responder mejor al suplemento. Te puede ayudar a bajar los triglicéridos en sangre.',
          [ToggleOptions.NO]: 'El suplemento de omega 3 podría no impactar de forma significativa en tus triglicéridos en sangre. Complementa con ejercicio y menos azúcares, grasas trans y alcohol.'
        }
      },
      {
        id: DietsPanelTypeIds.OMEGA3,
        title: 'Control de colesterol en sangre',
        IconElement: JellyIcon,
        message: {
          [ToggleOptions.YES]: 'Podrías responder mejor al suplemento. Te puede ayudar a bajar el colesterol en sangre.',
          [ToggleOptions.NO]: 'El suplemento de omega 3 podría no impactar de forma significativa en tu colesterol en sangre. Complementa con ejercicio, más fibra y menos grasas saturadas y alimentos procesados.'
        }
      }
    ]
  },
]

export const DietPatternDescriptions: DietPatternBaseOption[] = [
  {
    id: DietsPanelTypeIds.LOW_CARB,
    IconElement: WheatIcon,
    title: 'Baja en carbohidratos / Alta en grasas',
    tooltip: 'Reduce los carbohidratos y aumenta las grasas saludables.'
  },
  {
    id: DietsPanelTypeIds.LOW_FAT,
    IconElement: FatIcon,
    title: 'Baja en grasas / alta en carbohidratos',
    tooltip: 'Aumenta los carbohidratos y limita la ingesta de grasas.'
  },
  {
    id: DietsPanelTypeIds.HIGH_PROTEIN,
    IconElement: BreakfastIcon,
    title: 'Alta en proteínas',
    tooltip: 'Incrementa el consumo de proteínas en la dieta.'
  },
  {
    id: DietsPanelTypeIds.MEDITERRANEAN,
    IconElement: VegetableIcon,
    title: 'Dieta mediterránea',
    tooltip: 'Dieta basada en frutas, verduras, pescado y aceite de oliva.'
  },
  {
    id: DietsPanelTypeIds.HIGH_PUFA,
    IconElement: SeedIcon,
    title: 'Alta en grasas poliinsaturadas',
    tooltip: 'Dieta rica en grasas saludables como omega-3, presentes en pescados, chía y linaza, y omega-6, que se encuentran en aceites y frutos secos.'
  },
  {
    id: DietsPanelTypeIds.HIGH_MUFA,
    IconElement: SeedIcon,
    title: 'Alta en grasas monoinsaturadas',
    tooltip: 'Enfatiza el consumo de grasas monoinsaturadas como el aceite de oliva, palta y frutos secos.'
  },
  {
    id: DietsPanelTypeIds.LOW_GLUCEMIA,
    IconElement: BloodIcon,
    title: 'Bajo índice glicémico',
    tooltip: 'Favorece alimentos que elevan lentamente el azúcar en sangre.'
  },
  {
    id: DietsPanelTypeIds.OMEGA3,
    IconElement: Omega,
    title: 'Alta en Omega 3',
    tooltip: 'Enfocada en pescados y grasas antiinflamatorias omega-3, como nueces y semillas de chía o linaza.'
  }
]