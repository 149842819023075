import React, { useEffect } from "react";

import "./CustomSlider.scss";
import { colorFromPercentage } from "../../utils";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface CustomSliderProps {
  percent: number | null,
  intervals?: string[],
  intervalsDescriptions?: string[],
  size?: string
}

export default function CustomSliderComponent(props: CustomSliderProps) {
  const { percent, intervals, intervalsDescriptions, size } = props;

  useEffect(() => {
    const slider = document.getElementById("custom-slider-bar");

    if (slider && percent) {
      slider.style.setProperty("--after-content", `"${percent}"`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  const ballSize = size ? `${size}` : "25px";

  const color = colorFromPercentage(percent ?? 0, "#33b591", "#3900b3", "#fe5133");

  return (
    percent !== null ?
      <div className="mb-2 custom-slider">
        {intervals && intervals.length === 3 ?
          <div className="slider-header">
            {intervals.map((name, i) =>
              <div className={`intervals intervals-${i}`} key={`custom-slider-intervals-${i}-${name}`} >
                <div className="d-flex flex-row align-items-center gap-2" style={{fontSize:'small'}}>
                  {name}
                  {
                    intervalsDescriptions ?
                      <OverlayTrigger overlay={<Tooltip id={name}>{intervalsDescriptions[i]}</Tooltip>}>
                        <QuestionCircleFill />
                      </OverlayTrigger>
                      : null
                  }
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26">
                  <path d="M3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333ZM2.5 3L2.5 26L3.5 26L3.5 3L2.5 3Z" />
                </svg>
              </div>
            )}
          </div>
          : null}
        <div className="custom-slider-bg"
          style={{
            "--before-left": percent === 100 ? '-25px' : undefined,
            "--before-background": `${color}`,
            "--before-content": `'${percent}'`,
            "--size": ballSize,
          } as React.CSSProperties}
        >
          <div
            className="custom-slider-fill"
            id="slider"
            style={{
              color: color,
              width: `calc(${100 - percent + '%'} + ${ballSize} * ${percent < 100 ? 1 : 0})`,
              borderRadius: ballSize,
              '--before-right': percent === 100 ? '0.05px' : undefined,
            } as React.CSSProperties}
          />
        </div>
      </div>
      : null
  )
}
