import { Link, useLocation, useNavigate } from 'react-router-dom';

import InitialLayout from '@/components/InitialLayout/InitialLayout';
import BackButton from '@/components/BackButton/BackButtton';
import SignupForm from './SignupForm';

import { NewUser } from '@/models';
import { registerNewUser } from '@/services/auth/auth-service';
import { useNotifications } from '@/contexts/NotificationContext';

import './Signup.scss';
import MynuBlueLogo from '@/assets/images/mynu-blue-logo.png';


function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const { showNotification } = useNotifications();

  const submitUserData = async (userData: NewUser) => {
    try {
      await registerNewUser(userData);
      if (urlParams.get('testId')) {
        navigate(`/code-confirmation?testId=${urlParams.get('testId')}`);
      } else {
        navigate('/code-confirmation');
      }
    } catch (error: any) {
      showNotification('Ha ocurrido un error con el registro', 'danger');
    }
  }

  return (
    <InitialLayout>
      <div className="py-3">
        <BackButton text="Volver al Home" />
      </div>

      <div className="form-container pt-2">
        <div className="text-center d-lg-none pb-3">
          <img src={MynuBlueLogo} className="mobile-logo" alt="" width={123} />
        </div>
        <h1 className="form-title text-center mb-3">¡Bienvenido/Bienvenida!</h1>
        <SignupForm submitUserData={submitUserData} showAlert={(t: string) => showNotification(t, 'warning')} />
      </div>
      <div className="text-center redirect-note">
        <span className='font-avenir-bold'>¿Ya tienes cuenta? </span>
        <Link to="../login">Ingresa aquí</Link>
      </div>
    </InitialLayout>
  );
}

export default Signup;
