import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { FieldErrors, SubmitErrorHandler, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// react-bootstrap components
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

//custom components
import BuyKitLayout from "./components/BuyKitLayout";
import ShoppingCart from "./components/ShoppingCart";
import StepperComponent from "./components/StepperComponent";

// stepper steps
import KitStep from "./components/steps/KitStep";
import InfoStep from "./components/steps/InfoStep";
import DeliveryInfoForm from "./components/steps/DeliveryStep";
import ConfirmationStep from "./components/steps/ConfirmationStep";

import { submitTransbank } from "@/services/payment/submitTransbank";
import { AnnymousOneTimePaymentInformation, anonymousOneTimeReveniuCheckout, getPlans } from "@/services/subscription";

//styles
import './BuyKitPage.scss';
import kit from '@/assets/images/kit-image.png';

// constant, models & utils
import { useNotifications } from "@/contexts/NotificationContext";
import { KIT_PRICE_CLP, PHONE_NUMBER_REGEX } from "@/utils/constants";
import { trackEvent } from "@/utils/pixel";
import { ga4TrackEvent } from "@/utils/googleTagManager";
import { ReveniuPlan } from "@/models";


export enum FormIds {
  KIT_QUANTITY = 'kitQuantity',
  ACCEPTED_CONDITIONS = 'acceptedConditions',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  EMAIL_CONFIRMATION = 'emailConfirmation',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address',
  ADDRESS2 = 'address2',
  PROVINCE = 'province',
}

const formSteps = [
  [
    FormIds.KIT_QUANTITY,
    FormIds.ACCEPTED_CONDITIONS
  ],
  [
    FormIds.FIRST_NAME,
    FormIds.LAST_NAME,
    FormIds.EMAIL,
    FormIds.EMAIL_CONFIRMATION,
    FormIds.PHONE_NUMBER
  ],
  [
    FormIds.ADDRESS,
    FormIds.ADDRESS2,
    FormIds.PROVINCE
  ],
]

const FormBaseSchema = z.object({
  [FormIds.KIT_QUANTITY]: z
    .number({ required_error: 'Debes seleccionar al menos 1 kit genético' })
    .gte(1, { message: 'Debes seleccionar al menos 1 kit genético' }),

  [FormIds.ACCEPTED_CONDITIONS]: z
    .boolean({
      required_error: 'Debes aceptar nuestros términos y condiciones para continuar.'
    })
    .refine((val) => val === true, {
      message: 'Debes aceptar los términos y condiciones',
    }),

  [FormIds.FIRST_NAME]: z
    .string({ required_error: 'Debes ingresar tu nombre' })
    .min(1, { message: 'Debes ingresar tu nombre' })
    .max(255, { message: 'Por favor sólo usa hasta 255 caracteres' }),

  [FormIds.LAST_NAME]: z
    .string({ required_error: 'Debes ingresar tu apellido' })
    .min(1, { message: 'Debes ingresar tu apellido' })
    .max(255, { message: 'Por favor sólo usa hasta 255 caracteres' }),

  [FormIds.EMAIL]: z
    .string({ required_error: 'Debes ingresar tu correo' })
    .email({ message: 'Formato Incorrecto' }),

  [FormIds.EMAIL_CONFIRMATION]: z
    .string({ required_error: 'Debes ingresar tu correo' })
    .email({ message: 'Formato Incorrecto' }),

  [FormIds.PHONE_NUMBER]: z
    .string({ required_error: 'Debes ingresar tu teléfono' })
    .min(9, 'Debes introducir al menos 9 dígitos')
    .refine((value) => PHONE_NUMBER_REGEX.test(value ?? ""), 'Debes usar el formato correcto'),

  [FormIds.ADDRESS]: z
    .string({ required_error: 'Debes ingresar tu dirección' })
    .min(1, { message: 'Debes ingresar tu dirección' }),

  [FormIds.ADDRESS2]: z.string(),

  [FormIds.PROVINCE]: z
    .string({ required_error: 'Debes seleccionar tu comuna' })
    .min(1, { message: 'Debes seleccionar tu comuna' }),
});

export type FormFields = z.infer<typeof FormBaseSchema>;

const FormSchema = FormBaseSchema
  .superRefine((data, ctx) => {
    if (data[FormIds.EMAIL] !== data[FormIds.EMAIL_CONFIRMATION]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Los emails no coinciden',
        path: [FormIds.EMAIL_CONFIRMATION]
      });
    }
  })


export type BuyKitFormProps = {
  kitQuantity?: number;
  selectedPlan: ReveniuPlan;

  promoApplied?: boolean;
  setPromoApplied?: (applied: boolean) => void;

  kitData: {
    kitName: string;
    kitDescription: string;
    kitImage: string;
  };

  register: UseFormRegister<FormFields>;
  errors: FieldErrors<FormFields>
}


export const DISCOUNT_CODE = 'ETM15OFFMYNU';


const BuyKitPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm<FormFields>({ resolver: zodResolver(FormSchema), mode: 'onChange' });

  const kitQuantity = watch('kitQuantity');
  const email = watch(FormIds.EMAIL);
  const emailConfirmation = watch(FormIds.EMAIL_CONFIRMATION);

  const { showNotification } = useNotifications();

  const [reveniuPlans, setReveniuPlans] = useState<null | ReveniuPlan[]>(null);
  const [selectedPlan, setSelectedPlan] = useState<null | ReveniuPlan>(null);
  const [promoApplied, setPromoApplied] = useState(false);

  const navigate = useNavigate();
  const [background, setBackground] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleSetToggleBackground = () => {
    setBackground(!background);
  }

  const [step, setStep] = useState(0);

  const changeStep = (variation: 'forwards' | 'backwards') => {
    const newStep = variation === 'forwards' ? step + 1 : step - 1;

    if (variation === 'backwards') {
      if (step === 0) {
        navigate('/');
      }
    } else {
      if (step < formSteps.length) {
        const formData = getValues();
        const currentStep = formSteps[step];

        const stepSchema = z.object({
          ...Object.fromEntries(
            currentStep.map(s => [s, FormBaseSchema.shape[s]])
          )
        })
        const stepValues = {
          ...Object.fromEntries(
            currentStep.map(s => [s, formData[s]])
          )
        }

        const stepValid = stepSchema.safeParse(stepValues).success;
        if (!stepValid) {
          showNotification('Debes rellenar todos tus datos correctamente', 'warning');
          return;
        }
      }
    }

    setStep(newStep);
  }

  useEffect(() => {
    trackEvent('track', 'ViewContent', {
      content_name: 'BuyKitPage',
      content_category: 'Page',
    });

    getPlans()
      .then(p => setReveniuPlans(p))
      .catch(e => showNotification('Ha ocurrido un error inesperado', 'danger'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (kitQuantity > 0 && step === 0) {
      trackEvent('track', 'AddToCart', {
        content_ids: ['kit'],
        content_category: 'add_kit_to_cart',
        content_name: 'Kit Genetico',
        currency: 'CLP',
        value: KIT_PRICE_CLP * kitQuantity,
      })

      ga4TrackEvent('add_to_cart', {
        ecommerce: {
          currency: 'CLP',
          value: kitQuantity * KIT_PRICE_CLP,
          items: [{
            item_id: "genetic_kit",
            item_name: "Kit Genético",
            item_brand: "Mynu",
            price: KIT_PRICE_CLP,
            quantity: kitQuantity
          }]
        }
      });
    }

    else if (kitQuantity > 0 && step === 2) {
      ga4TrackEvent('add_shipping_info', {
        ecommerce: {
          currency: 'CLP',
          value: kitQuantity * KIT_PRICE_CLP,
          items: [{
            item_id: "genetic_kit",
            item_name: "Kit Genético",
            item_brand: "Mynu",
            price: KIT_PRICE_CLP,
            quantity: kitQuantity
          }]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (reveniuPlans) {
      const filteredPlan = reveniuPlans.filter(p => promoApplied ? p.title.includes('Descontado') : p);
      if (filteredPlan[0]) {
        setSelectedPlan(filteredPlan[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoApplied, reveniuPlans])


  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    if (step === 4) {
      const formData = getValues();
      const buyerInfoDTO: AnnymousOneTimePaymentInformation = {
        kitQuantity: kitQuantity,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        address: `${formData.address}, ${formData.address2}`,
        province: formData.province,
        internalPlanId: selectedPlan?.internal_id ?? '',
        city: "Santiago",
        region: "Metropolitana",
        acceptedConditions: false
      }

      console.log('data', data);

      trackEvent('track', 'InitiateCheckout', {
        content_category: 'kit',
        content_type: 'kit_purchase',
        content_id: 'start_purchase',
        currency: 'CLP',
        value: kitQuantity * KIT_PRICE_CLP,
        num_items: kitQuantity,
      });

      ga4TrackEvent('begin_checkout', {
        ecommerce: {
          currency: 'CLP',
          value: kitQuantity * KIT_PRICE_CLP,
          items: [{
            item_id: "genetic_kit",
            item_name: "Kit Genético",
            item_brand: "Mynu",
            price: KIT_PRICE_CLP,
            quantity: kitQuantity,
            coupon: promoApplied ? DISCOUNT_CODE : ''
          }]
        }
      });

      setLoadingSubmit(true);
      try {
        const checkoutRes = await anonymousOneTimeReveniuCheckout(buyerInfoDTO);
        submitTransbank(checkoutRes.completion_url, checkoutRes.security_token)
      } catch (error: any) {
        showNotification('Oops, ha ocurrido un error en la conexion', 'danger');
      }
      setLoadingSubmit(false);
    }
  }

  const onError: SubmitErrorHandler<FormFields> = (_) => {
    showNotification('Debes rellenar todos los campos correctamente.', 'warning');
  };

  return (
    selectedPlan &&
    <BuyKitLayout>
      <Card className="desktop-hidden">
        <Card.Header>
          <div className="header-text">Compra tu kit genético aquí!</div>
        </Card.Header>
      </Card>

      <Form id="buy-kit-form" noValidate onSubmit={handleSubmit(onSubmit, onError)} className="d-flex flex-column">
        <StepperComponent
          activeStep={step}
          setActiveStep={setStep}
          stepHeaders={[
            '¿Cuántos kits quieres comprar?',
            'Información de contacto',
            'Información de envío',
            'Por favor, confirma tus datos'
          ]}
          steps={[
            <KitStep
              kitQuantity={kitQuantity}
              register={register}
              errors={errors}
              selectedPlan={selectedPlan}
              kitData={kitData}
              promoApplied={promoApplied}
              setPromoApplied={setPromoApplied}
            />,
            <InfoStep
              register={register}
              errors={errors}
              selectedPlan={selectedPlan}
              kitData={kitData}
              email={email}
              emailConfirmation={emailConfirmation}
            />,
            <DeliveryInfoForm
              register={register}
              errors={errors}
              selectedPlan={selectedPlan}
              kitData={kitData}
            />,
            <ConfirmationStep
              selectedPlan={selectedPlan}
              formData={getValues()}
            />
          ]}
        >
          <div className="bottom-container">
            <ShoppingCart
              selectedPlan={selectedPlan}
              kitQuantity={kitQuantity}
              kitData={kitData}
              handleSetToggleBackground={handleSetToggleBackground}
              step={step}
            />
            <Card className="buttons-container">
              <div className="terms py-2">
                Al comprar el kit estás aceptando nuestros <a href="https://mynu.ai/terminos" target="_blank" rel="noreferrer">
                  Términos y Condiciones</a>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <Button variant="danger" onClick={() => { changeStep('backwards') }}> Atras </Button>
                {selectedPlan === null || loadingSubmit ?
                  <div className="d-flex justify-content-center align-items-center w-50">
                    <Spinner animation="border" variant="primary" />
                  </div>
                  :
                  (step === 4 ?
                    <Button type="submit" className="primary"> Continuar al pago! </Button>
                    :
                    (email && emailConfirmation && email !== emailConfirmation) ?
                      <Button type="button" className="primary" disabled> Siguiente! </Button>
                      :
                      <Button type="button" className="primary" onClick={() => { changeStep('forwards') }}> Siguiente! </Button>
                  )
                }
              </div>
            </Card>
          </div>
        </StepperComponent>

      </Form>

      {background ? <div className="background-blur"></div> : null}

      {/* space fill when display vertical size is too small */}
      <div className='space-fill' />

    </BuyKitLayout>)
}

export const kitData = {
  kitName: 'Kit Genético',
  kitDescription: `
  - Test Genético
  - 3 meses Premium
  - Teleconsulta con especialista`,
  kitImage: kit,
}

export default BuyKitPage;
