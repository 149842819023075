import { useNavigate } from "react-router-dom";

export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateAndReset = (to: string | number, state?: object) => {
    switch (typeof to) {
      case "string":
        navigate(to, { state });
        break;
      case "number":
        navigate(to);
        break;
    }
    window.scrollTo(0, 0);
  };

  return navigateAndReset;
};
