import { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';

// state & context
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from '@/store/store';
import { loadDiet, loadUserInfo } from '@/store/loader';

// my componetns
import MainLayout from '@/components/MainLayout/MainLayout';
import SubscriptionBanner from '@/components/SubscriptionBanner/SubscriptionBanner'
import Spinner from '@/components/Spinner/Spinner';

import ProfileSummary from './components/ProfileSummary';
import DietAccordion from './components/DietAccordion/DietAccordion';

// services & utils
import { validSubscription } from '@/utils';
import { useNotifications } from '@/contexts/NotificationContext';

// assets
import './DietPage.scss';
import { LoadingPage } from '@/router/LoadingPage';
import { days } from '@/utils/constants';

function DietPage() {

  const currentUser = useCurrentUser();
  const store = useStateStore();

  const {
    hasDiet,
    dietInformation,
    isLoadingDiet,
    dietCreationFailed,
  } = store;

  const { showNotification } = useNotifications();

  const [isSubscriptionBanner, setSubscriptionBanner] = useState('');
  const [openAccordionDay, setOpenDietAccordionDay] = useState('dailyMeal-Lunes');

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      const subscription = currentUser?.subscription;
      if (validSubscription(subscription)) {
        setSubscriptionBanner('none');
      }
      if (subscription.end_date && subscription.end_date < new Date().toISOString()) {
        setSubscriptionBanner('expiredAlert');
      }
      if (subscription.type === 'freemium') {
        setSubscriptionBanner('freemium');
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasDiet()) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const checkDiet = async () => {
    try {
      await loadDiet(store);
    } catch (error: any) {
      showNotification('Oops, ha ocurrido un error cargando tu dieta', 'danger');
    }
  };

  useEffect(() => {
    if (isLoadingDiet) {
      checkDiet();
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);

  return (
    <MainLayout>
      {hasDiet() ?
        <>
          <div className="page pt-2 pt-lg-4 px-0">
            <Container className="page-container">
              <Card className="p-4 mb-4 diet-header">
                <h1 className="diet-title">My Menú</h1>
                <p className="text mt-3">¡Bienvenido a tu primera dieta personalizada!
                  Hemos creado un plan semanal adaptado a tus necesidades nutricionales, objetivos y preferencias.
                  Además, puedes revisar las definiciones de las unidades de medida en la sección de <a href='/diets/portions'>porciones</a>.
                </p>
                <hr />
                <ProfileSummary />
              </Card>
              <SubscriptionBanner type={isSubscriptionBanner} onClick={() => { }} />

              {!isLoadingDiet && !dietCreationFailed && dietInformation &&
                <Card className="p-2 mb-4 diet-header">
                  <DietAccordion
                    days={days}
                    loadDiet={checkDiet}
                    currentUser={currentUser}
                    openAccordionDay={openAccordionDay}
                    setOpenDietAccordionDay={setOpenDietAccordionDay}
                  />
                </Card>
              }
              {isLoadingDiet && <div className="loader-container">
                <Spinner size='medium' rotate={true} />
                <span className="loader-text mt-3 text-center">¡Estamos cargando tu dieta!</span>
                <br />
                <p >Te notificaremos por email cuando esté lista</p>
              </div>}
              {dietCreationFailed &&
                <div className="loader-container">
                  <div className='mb-4'>
                    <Spinner size='medium' rotate />
                  </div>
                  <span className="loader-text mt-3 text-center">Cargando tu dieta</span>
                  <br />
                  <p className='explanation-paragraph'>
                    Estamos tardando más de lo habitual generando tu dieta. Porfavor, espera a que tu dieta esté lista.
                  </p>
                </div>
              }
              <Card className="px-3 py-5 px-lg-4 mb-4 diet-header">
                <h2 className="summary-title mb-4 font-avenir-bold">🥗 Resumen de la semana:</h2>
                <span className="font-avenir-bold">✅ Adaptado a ti:</span>
                <p className="text my-1 my-lg-3">Tomando en cuenta tu genética, tus hábitos, gustos y objetivos, hemos diseñado un plan que te llevará a la versión más saludable de ti mismo.</p>
                <span className="font-avenir-bold">✅ Variedad y Sabor:</span>
                <p>Olvídate de las dietas monótonas. Cada día es una nueva oportunidad para explorar sabores y recetas que nutren tu cuerpo.</p>
              </Card>
            </Container>
          </div>
        </>
        : <LoadingPage />
      }
    </MainLayout>
  )
}

export default DietPage;
